import { eventSearchService } from '@mediabank/client';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, from, of } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';

import {
    clearSearchEpicAction,
    loadSmartSearchAction,
    resetSearchAction,
    setLoadingAction,
} from '../../components/AdvancedSearch/actions';
import { HEADER_CLEAR_QUICKFILTER } from '../../components/Header/constants';
import {
    SEARCH_PROVIDER_CLEAR_COLLECTION,
    SEARCH_PROVIDER_LOAD_COLLECTION,
    SEARCH_PROVIDER_REQUEST_ASSET_DEFAULT,
    SEARCH_PROVIDER_REQUEST_COMBINED_ASSET_DEFAULT,
    SEARCH_PROVIDER_REQUEST_COMBINED_EVENT_DEFAULT,
    SEARCH_PROVIDER_REQUEST_EVENT_DEFAULT,
    SEARCH_PROVIDER_RESET_RESULT,
    SEARCH_PROVIDER_SUBMIT_ASSET_QUERY,
    SEARCH_PROVIDER_SUBMIT_COMBINED_ASSET_QUERY,
    SEARCH_PROVIDER_SUBMIT_COMBINED_EVENT_QUERY,
    SEARCH_PROVIDER_SUBMIT_EVENT_QUERY,
} from '../../framework/constants';
import { assetsService, smartSearchService } from '../../services';
import { translateResponse } from '../../store/advancedSearch/utils';
import searchResults from '../../store/assets/assetSearchResult';
import calendar from '../../store/calendar';
import collections from '../../store/collections';
import { eventSearch } from '../../store/eventSearch';
import { UPDATE_EVENTS_FILTERS } from '../../store/eventSearch/constants';
import folders from '../../store/folders/folders';
import searchProvider from '../../store/searchProvider';
import {
    assetSearchSuccess,
    combinedAssetSearchSuccess,
    combinedEventSearchSuccess,
    eventSearchSuccess,
    initAssetSearch,
    initCombinedAssetSearch,
    initCombinedEventSearch,
    initEventSearch,
    populateAssetsWithEmptyData,
    populateEventsWithEmptyData,
    submitAssetQuery,
    submitCombinedAssetQuery,
    submitCombinedEventQuery,
    submitEventQuery,
} from './actions';
import {
    SEARCH_PROVIDER_INIT_ASSET_SEARCH,
    SEARCH_PROVIDER_INIT_COMBINED_ASSET_SEARCH,
    SEARCH_PROVIDER_INIT_COMBINED_EVENT_SEARCH,
    SEARCH_PROVIDER_INIT_EVENT_SEARCH,
    SEARCH_PROVIDER_TRIGGER_INIT,
} from './constants';
import { selectCombinedEventQuery, selectCombinedQuery, selectEventQuery, selectQuery } from './selectors';

const { setQuery, resetQuery } = searchProvider.actions;
const { setLoading, resetSelection: resetAssetSelection } = searchResults.actions;
const {
    requestStart: eventRequestStart,
    requestFinished: eventRequestFinished,
    resetSelection: resetEventSearchSelection,
    resetFilters: resetEventFilters,
    updateFilters,
} = eventSearch.actions;
const { setActiveCollectionId, setActiveSubCollectionId, resetCollection } = collections.actions;
const { resetCalendar } = calendar.actions;
const { resetFolders } = folders.actions;

const getDefaultSearchAssets = ({ action, addToastError, title, state }) => {
    const limit = action.payload?.limit || state.ui.limit;

    return from(assetsService.search({ ...action.payload, limit })).pipe(
        map(assetSearchSuccess),
        catchError(() =>
            concat(
                of(setLoadingAction(false)),
                of(populateAssetsWithEmptyData()),
                of(
                    addToastError({
                        title: title,
                    })
                )
            )
        )
    );
};

const getDefaultSearchCombinedAssets = ({ action, addToastError, title }) => {
    const limit = 40;

    return from(assetsService.search({ ...action.payload, limit })).pipe(
        map(combinedAssetSearchSuccess),
        catchError(() =>
            concat(
                of(setLoadingAction(false)),
                of(populateAssetsWithEmptyData()),
                of(
                    addToastError({
                        title: title,
                    })
                )
            )
        )
    );
};

const getDefaultSearchCombinedEvents = ({ action, addToastError, title }) => {
    const eventResultLimit = 40;

    return from(eventSearchService.getEvents({ ...action.payload, eventResultLimit })).pipe(
        map(combinedEventSearchSuccess),
        catchError(() =>
            concat(
                of(setLoadingAction(false)),
                of(populateEventsWithEmptyData({ isCombinedMode: true })),
                of(
                    addToastError({
                        title: title,
                    })
                )
            )
        )
    );
};

export const resetSearchEpic = action$ =>
    action$.pipe(
        ofType(HEADER_CLEAR_QUICKFILTER),
        switchMap(() =>
            concat(
                of(resetQuery()),
                of(submitAssetQuery()),
                of(submitCombinedAssetQuery()),
                of(submitEventQuery()),
                of(submitCombinedEventQuery()),
                of(clearSearchEpicAction())
            )
        )
    );

export const resetSearchResultEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_RESET_RESULT),
        switchMap(() => {
            const query = {
                limit: state$.value.config.data.DefaultItemsPerPage,
                page: 1,
                sortOrder: 'desc',
            };

            return concat(
                of(resetSearchAction()),
                of(resetQuery()),
                of(resetCollection()),
                of(resetCalendar()),
                of(resetFolders()),
                of(resetAssetSelection()),
                of(resetEventSearchSelection()),
                of(resetEventFilters()),
                of(submitAssetQuery(query)),
                of(submitEventQuery(query)),
                of(submitCombinedAssetQuery(query)),
                of(submitCombinedEventQuery(query))
            );
        })
    );

// Default request epics
export const requestAssetDefaultEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_REQUEST_ASSET_DEFAULT),
        withLatestFrom(state$),
        distinctUntilChanged((_, [, state]) => state.ui.page),
        switchMap(([action]) =>
            from(smartSearchService.getActive()).pipe(
                switchMap(({ data }) => {
                    if (data && data.data) {
                        const query = data.data && data.data.attributes ? translateResponse(data.data) : {};

                        return concat(
                            of(submitAssetQuery({ ...query, ...action.payload })),
                            of(loadSmartSearchAction(query))
                        );
                    } else {
                        return of(submitAssetQuery(action.payload));
                    }
                })
            )
        )
    );

export const requestCombinedAssetDefaultEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_REQUEST_COMBINED_ASSET_DEFAULT),
        withLatestFrom(state$),
        distinctUntilChanged(([prevPayload], [currPayload]) => prevPayload.searchType === currPayload.searchType),
        switchMap(([action]) =>
            from(smartSearchService.getActive()).pipe(
                switchMap(({ data }) => {
                    if (data && data.data) {
                        const query = data.data && data.data.attributes ? translateResponse(data.data) : {};

                        return concat(
                            of(submitCombinedAssetQuery({ ...query, ...action.payload })),
                            of(loadSmartSearchAction(query))
                        );
                    } else {
                        return of(submitCombinedAssetQuery(action.payload));
                    }
                })
            )
        )
    );

export const requestDefaultEventsEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_REQUEST_EVENT_DEFAULT),
        withLatestFrom(state$),
        distinctUntilChanged((_, [, state]) => state.ui.eventResultPage),
        switchMap(([action]) =>
            from(smartSearchService.getActive()).pipe(
                switchMap(({ data }) => {
                    if (data && data.data) {
                        const query = data.data && data.data.attributes ? translateResponse(data.data) : {};

                        return concat(
                            of(submitEventQuery({ ...query, ...action.payload })),
                            of(loadSmartSearchAction(query))
                        );
                    } else {
                        return of(submitEventQuery(action.payload));
                    }
                })
            )
        )
    );

export const requestDefaultCombinedEventsEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_REQUEST_COMBINED_EVENT_DEFAULT),
        withLatestFrom(state$),
        distinctUntilChanged(([prevPayload], [currPayload]) => prevPayload.searchType === currPayload.searchType),
        switchMap(([action]) =>
            from(smartSearchService.getActive()).pipe(
                switchMap(({ data }) => {
                    if (data && data.data) {
                        const query = data.data && data.data.attributes ? translateResponse(data.data) : {};

                        return concat(
                            of(submitCombinedEventQuery({ ...query, ...action.payload })),
                            of(loadSmartSearchAction(query))
                        );
                    } else {
                        return of(submitCombinedEventQuery(action.payload));
                    }
                })
            )
        )
    );

export const initAssetSearchEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_INIT_ASSET_SEARCH, SEARCH_PROVIDER_TRIGGER_INIT),
        debounceTime(200),
        withLatestFrom(state$),
        filter(([{ type }]) => type === SEARCH_PROVIDER_INIT_ASSET_SEARCH),
        switchMap(([, state]) =>
            from(assetsService.search(selectQuery(state))).pipe(
                map(assetSearchSuccess),
                catchError(() =>
                    concat(
                        of(setLoadingAction(false)),
                        of(populateAssetsWithEmptyData()),
                        of(
                            addToastError({
                                title:
                                    'Something went wrong while fetching the assets for this query. Please refresh your browser and try again.',
                            })
                        )
                    )
                )
            )
        )
    );

export const initCombinedAssetSearchEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_INIT_COMBINED_ASSET_SEARCH),
        debounceTime(200),
        withLatestFrom(state$),
        filter(([{ type }]) => type === SEARCH_PROVIDER_INIT_COMBINED_ASSET_SEARCH),
        switchMap(([, state]) =>
            from(assetsService.search(selectCombinedQuery(state))).pipe(
                map(combinedAssetSearchSuccess),
                catchError(() =>
                    concat(
                        of(setLoadingAction(false)),
                        of(populateAssetsWithEmptyData()),
                        of(
                            addToastError({
                                title:
                                    'Something went wrong while fetching the assets for this query. Please refresh your browser and try again.',
                            })
                        )
                    )
                )
            )
        )
    );

export const initEventSearchEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_INIT_EVENT_SEARCH),
        debounceTime(200),
        withLatestFrom(state$),
        switchMap(([, state]) =>
            from(eventSearchService.getEvents(selectEventQuery(state))).pipe(
                map(eventSearchSuccess),
                catchError(error => {
                    const { status } = error.response || {};

                    return concat(
                        of(setLoadingAction(false)),
                        of(eventRequestFinished()),
                        of(populateEventsWithEmptyData()),
                        status !== 404
                            ? of(
                                  addToastError({
                                      title:
                                          'Something went wrong while fetching the events for this query. Please refresh your browser and try again.',
                                  })
                              )
                            : EMPTY
                    );
                })
            )
        )
    );

export const initCombinedEventSearchEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_INIT_COMBINED_EVENT_SEARCH),
        debounceTime(200),
        withLatestFrom(state$),
        switchMap(([, state]) =>
            from(eventSearchService.getEvents(selectCombinedEventQuery(state))).pipe(
                map(combinedEventSearchSuccess),
                catchError(() =>
                    concat(
                        of(setLoadingAction(false)),
                        of(eventRequestFinished()),
                        of(populateEventsWithEmptyData({ isCombinedMode: true })),
                        of(
                            addToastError({
                                title:
                                    'Something went wrong while fetching the events for this query. Please refresh your browser and try again.',
                            })
                        )
                    )
                )
            )
        )
    );

export const loadCollectionEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_LOAD_COLLECTION),
        withLatestFrom(state$),
        debounceTime(200),
        switchMap(([action, state]) =>
            concat(
                of(setLoading(action.payload)),
                of(resetQuery()),
                of(resetSearchAction()),
                of(setQuery(action.payload)),
                getDefaultSearchAssets({
                    action,
                    addToastError,
                    title:
                        'Something went wrong while fetching the assets for this collection. Please refresh your browser and try again.',
                    state,
                })
            )
        )
    );

export const clearCollectionEpic = action$ =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_CLEAR_COLLECTION),
        switchMap(() => concat(of(setActiveCollectionId(null)), of(setActiveSubCollectionId(null))))
    );

export const watchForAssetQueryChangesEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_SUBMIT_ASSET_QUERY),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { payload = {} } = action;
            const loadingAction = () => setLoading(payload);
            const searchAction = initAssetSearch;

            if (!payload?.isCollectionView && !state?.collections?.activeId) {
                return concat(
                    of(setActiveCollectionId()),
                    of(setActiveSubCollectionId()),
                    of(loadingAction()),
                    of(setQuery(payload)),
                    of(searchAction())
                );
            } else {
                return concat(of(loadingAction()), of(setQuery(payload)), of(searchAction()));
            }
        })
    );

export const watchForCombinedAssetQueryChangesEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_SUBMIT_COMBINED_ASSET_QUERY),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { payload = {} } = action;
            const { keyword } = payload;
            const searchAction = initCombinedAssetSearch;
            const loadingAction = () => setLoading(payload);

            if (!payload?.isCollectionView && !state?.collections?.activeId) {
                return concat(
                    of(setActiveCollectionId()),
                    of(setActiveSubCollectionId()),
                    of(loadingAction()),
                    of(setQuery(payload)),
                    keyword === ''
                        ? getDefaultSearchCombinedAssets({
                              action,
                              addToastError,
                              title:
                                  'Something went wrong while fetching the assets for this query. Please refresh your browser and try again.',
                              state,
                          })
                        : of(searchAction())
                );
            } else {
                return concat(of(loadingAction()), of(setQuery(payload)), of(searchAction()));
            }
        })
    );

export const watchForEventQueryChangesEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_SUBMIT_EVENT_QUERY),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { payload = {} } = action;

            const searchAction = initEventSearch;
            const loadingAction = () => eventRequestStart();

            if (!payload?.isCollectionView && !state?.collections?.activeId) {
                return concat(
                    of(setActiveCollectionId()),
                    of(setActiveSubCollectionId()),
                    of(loadingAction()),
                    of(setQuery(payload)),
                    of(searchAction())
                );
            } else {
                return concat(of(loadingAction()), of(setQuery(payload)), of(searchAction()));
            }
        })
    );

export const watchForCombinedEventQueryChangesEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_SUBMIT_COMBINED_EVENT_QUERY),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { payload = {} } = action;
            const { keyword } = payload;

            const searchAction = initCombinedEventSearch;
            const loadingAction = () => eventRequestStart();

            if (!payload?.isCollectionView && !state?.collections?.activeId) {
                return concat(
                    of(setActiveCollectionId()),
                    of(setActiveSubCollectionId()),
                    of(loadingAction()),
                    of(setQuery(payload)),
                    keyword === ''
                        ? getDefaultSearchCombinedEvents({
                              action,
                              addToastError,
                              title:
                                  'Something went wrong while fetching the assets for this query. Please refresh your browser and try again.',
                              state,
                          })
                        : of(searchAction())
                );
            } else {
                return concat(of(loadingAction()), of(setQuery(payload)), of(searchAction()));
            }
        })
    );

export const watchForEventFilterChangesEpic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_EVENTS_FILTERS),
        withLatestFrom(state$),
        switchMap(([action]) => {
            const { filters } = action.payload;

            return concat(of(eventRequestStart()), of(updateFilters({ filters })), of(initEventSearch()));
        })
    );

export default combineEpics(
    initAssetSearchEpic,
    initEventSearchEpic,
    clearCollectionEpic,
    loadCollectionEpic,
    resetSearchEpic,
    resetSearchResultEpic,
    requestAssetDefaultEpic,
    watchForAssetQueryChangesEpic,
    requestCombinedAssetDefaultEpic,
    watchForCombinedAssetQueryChangesEpic,
    initCombinedAssetSearchEpic,
    requestDefaultEventsEpic,
    requestDefaultCombinedEventsEpic,
    watchForCombinedEventQueryChangesEpic,
    watchForEventQueryChangesEpic,
    initCombinedEventSearchEpic,
    watchForEventFilterChangesEpic
);
