import { createSelector, createSlice } from '@reduxjs/toolkit';

import { configService } from '../../services';
import userSlice from '../user';

const formatWarnings = (assetWarnings = []) =>
    assetWarnings.map(warning => {
        const arrWarning = warning.split(':');

        if (arrWarning.length !== 4) {
            return false;
        }

        return {
            metakey: arrWarning[0],
            metavalue: arrWarning[1],
            type: arrWarning[2],
            info: arrWarning[3],
        };
    });

/* We need this function becase backend sends us wrong typed disabled property at this moment */
const resolveDisabledProp = disabled => {
    if (typeof disabled === 'string') {
        return disabled === 'true' ? true : false;
    }
    if (typeof disabled === 'boolean') {
        return disabled;
    }

    return false;
};

const formatMiniEnvironmentSettings = miniEnvSettings =>
    miniEnvSettings.map(setting => ({ ...setting, set: false, disabled: resolveDisabledProp(setting.disabled) }));

export const initialState = {
    loading: false,
    error: null,
    data: {
        AssetInformationTabConfig: {
            Fields: {
                Default: [],
            },
            MetaField: '',
        },
        AssetWarnings: [],
        ThumbviewMetaOverlayKeys: [],
        HeaderCustomerLogo: undefined,
        LibraryEditableMetaFields: '',
        ListviewColumns: [],
        PackageAttachmentGroups: undefined,
        ReactWorkspaces: '',
        MiniEnvironmentSettings: null,
    },
};

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        fetchStart: state => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            try {
                const data = action.payload.data;
                state.data = data?.AssetWarnings
                    ? { ...data, AssetWarnings: formatWarnings(data.AssetWarnings) }
                    : data;
                state.data = data?.MiniEnvironmentSettings
                    ? {
                          ...data,
                          MiniEnvironmentSettings: formatMiniEnvironmentSettings(data.MiniEnvironmentSettings),
                      }
                    : data;
                state.loading = false;
                state.error = null;
            } catch {
                fetchError();
            }
        },
        fetchError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        clear: () => initialState,
        modifyMiniEnv: (state, { payload: { itemKey, newValue } }) => {
            state.data.MiniEnvironmentSettings = state.data.MiniEnvironmentSettings.map(miniEnvItem => {
                if (miniEnvItem.key === itemKey) {
                    return newValue;
                }

                return miniEnvItem;
            });
        },
        overwriteMiniEnv: (state, payload) => {
            state.data.MiniEnvironmentSettings = payload;
        },
    },
});

const { fetchStart, fetchSuccess, fetchError } = configSlice.actions;

const getConfig = state => state.config?.data;

const selectPackageAttachmentGroups = ({ config }) => parseConfig(config.data.PackageAttachmentGroups);

const getLibraryAutoPlayVideo = state => state.config.data.LibraryAutoPlayVideo;

const selectProxySettings = state => state.config.data.ProxySettings;
const getProxySettings = createSelector(selectProxySettings, config => parseConfig(config));

export default {
    ...configSlice,
    selectors: { getConfig, getLibraryAutoPlayVideo, selectPackageAttachmentGroups, getProxySettings },
};

export const fetchAllConfigs = () => async dispatch => {
    try {
        dispatch(fetchStart());
        const { data } = await configService.getConfig();
        if (data) {
            dispatch(fetchSuccess({ data: data.attributes }));
        } else {
            throw Error('400 Error');
        }
    } catch (err) {
        if (err.response?.status === 401) {
            dispatch(userSlice.actions.clear());
        }

        dispatch(fetchError(err.message));
    }
};

const parseConfig = obj => {
    try {
        return JSON.parse(obj);
    } catch {
        return null;
    }
};
