import { SEARCHTYPE } from '../../hooks/useCombinedSearchMode/useCombinedSearchMode';

export const selectQuery = state => ({ ...state.searchProvider, searchType: SEARCHTYPE.assets });
export const selectCombinedQuery = state => ({ ...state.searchProvider, searchType: SEARCHTYPE.all });

export const selectEventQuery = state => ({
    ...state.searchProvider,
    ...state.eventSearch,
    searchType: SEARCHTYPE.events,
});
export const selectCombinedEventQuery = state => ({
    ...state.searchProvider,
    ...state.eventSearch,
    searchType: SEARCHTYPE.all,
});
