import { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

export const EventTagsContext = createContext(null);

export const EventTagsProvider = ({ children }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [tagsHappeningId, setTagsHappeningId] = useState(null);
    const [selectedAssetInfo, setSelectedAssetInfo] = useState(null);
    const [isEventTagsPanelOpen, setIsEventTagsPanelOpen] = useState(false);

    const setTagsHappeningIdOnSelectAsset = useCallback(
        assetToSelect => {
            if (tagsHappeningId) {
                const assetTagsHappeningId = assetToSelect.attributes?.assetmeta?.TagsHappeningId;

                if (assetTagsHappeningId !== tagsHappeningId) {
                    setTagsHappeningId(assetTagsHappeningId);
                    setSelectedAssetInfo({
                        imageSrc: assetToSelect.imgSrc,
                        title: assetToSelect.metaData.title,
                        category: assetToSelect.metaData?.eventType,
                    });
                }
                if (assetTagsHappeningId) {
                    setIsEventTagsPanelOpen(true);
                } else {
                    setIsEventTagsPanelOpen(false);
                }
            } else {
                setIsEventTagsPanelOpen(false);
            }
        },
        [tagsHappeningId]
    );

    const onHandleOnChevronButtonClick = useCallback(
        (e, { isPanelOpenForCurrentAsset, tagsHappeningId, imgSrc, metaData }) => {
            e.preventDefault();
            e.stopPropagation();
            if (isPanelOpenForCurrentAsset) {
                setIsEventTagsPanelOpen(false);
                setTagsHappeningId(null);
                setSelectedAssetInfo(null);
            } else {
                setIsEventTagsPanelOpen(true);
                setTagsHappeningId(tagsHappeningId);
                setSelectedAssetInfo({
                    imageSrc: imgSrc,
                    title: metaData.title,
                    category: metaData.eventType,
                });
            }
        },
        []
    );

    return (
        <EventTagsContext.Provider
            value={{
                isFullScreen,
                setIsFullScreen,
                tagsHappeningId,
                setTagsHappeningId,
                selectedAssetInfo,
                setSelectedAssetInfo,
                isEventTagsPanelOpen,
                setIsEventTagsPanelOpen,
                setTagsHappeningIdOnSelectAsset,
                onHandleOnChevronButtonClick,
            }}
        >
            {children}
        </EventTagsContext.Provider>
    );
};

EventTagsProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
