import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    errors: [{ key: '', error: null, isValid: false }],
    isSubClipMetadataValid: false,
    subClipMetadata: null,
    visibleSubClipMetadataDialog: false,
};

const miniEnvSlice = createSlice({
    name: 'miniEnv',
    initialState,
    reducers: {
        setError: (state, action) => {
            const error = action.payload;
            const errorKeys = selectMiniEvErrorKeys({ miniEnv: state });
            if (!errorKeys.includes(error.key)) {
                state.errors.push(error);
            } else {
                state.errors[errorKeys.indexOf(error.key)] = error;
            }
        },
        setSubClipMetadata: (state, action) => {
            state.subClipMetadata = action.payload;
            state.isSubClipMetadataValid = !!action.payload;
        },
        toggleSubClipMetadataDialog: state => {
            state.visibleSubClipMetadataDialog = !state.visibleSubClipMetadataDialog;
        },
        clear: () => initialState,
    },
});

const selectMiniEnv = state => state.miniEnv;

export const selectMiniEvErrorKeys = createSelector(selectMiniEnv, ({ errors }) => errors.map(error => error.key));

export const getErrorByKey = key =>
    createSelector(selectMiniEnv, ({ errors }) => errors.find(error => error.key === key));

export const getIsSubClipMetadataValid = ({ miniEnv }) => miniEnv.isSubClipMetadataValid;

export const getSubClipMetadata = ({ miniEnv }) => miniEnv.subClipMetadata;

export default {
    ...miniEnvSlice,
    selectors: { getErrorByKey, getIsSubClipMetadataValid, getSubClipMetadata, selectMiniEnv },
};

export const actions = miniEnvSlice.actions;
