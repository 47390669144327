export const createDynamicFilterParams = (filters, paramName = 'filters', searchType = 'events') =>
    filters.reduce((params, filter, index) => {
        if (searchType === 'assets' && filter.type === 'daterange') {
            params['smartsearchassetcreatedfilterenabled'] = true;
            params['smartsearchassetcreatedfiltertype'] = 'within';
            params['smartsearchassetcreatedfiltervalue'] = [filter.value[0], filter.value[1]];

            return params;
        }

        if (searchType === 'assets' && filter.type === 'freetext') {
            params['smartsearchtext'] = filter.value;

            return params;
        }

        if (searchType === 'events' && filter.type === 'freetext') {
            params[`${paramName}[${index}][key]`] = filter.id;
            params[`${paramName}[${index}][verb]`] = 'freetext';
            params[`${paramName}[${index}][value]`] = filter.value;

            return params;
        }

        params[`${paramName}[${index}][key]`] = filter.id;

        if (Array.isArray(filter.value)) {
            params[`${paramName}[${index}][verb]`] = filter.type === 'daterange' ? 'within' : 'some';
            filter.value.forEach((filterValue, valueIndex) => {
                params[`${paramName}[${index}][value][${valueIndex}]`] = filterValue;
            });
        } else {
            params[`${paramName}[${index}][verb]`] = 'equal';
            params[`${paramName}[${index}][value]`] = filter.value;
        }
        return params;
    }, {});

export const createDynamicAggregateParams = metaKeys =>
    metaKeys.reduce((params, metaKey, index) => {
        params[`aggregate[${index}]`] = metaKey;

        return params;
    }, {});
