import { actionToUrlParamMapping } from '../../store/ui';
import { updateUrlQueryString } from '../../store/ui/epic';

const InitalStateProvider = () => {
    const params = actionToUrlParamMapping.map(entry => ({
        key: entry.key,
        value: entry.initalValue,
    }));
    updateUrlQueryString({ params });

    return null;
};

export default InitalStateProvider;
