import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import advancedSearchSlice from '../../store/advancedSearch';
import activeAssetsSlice from '../../store/assets/activeAssets';
import assetSlice from '../../store/assets/asset';
import assetSearchResultSlice from '../../store/assets/assetSearchResult';
import assetMetaKeysSlice from '../../store/assets/metaKeys';
import collectionsSlice from '../../store/collections';
import configSlice from '../../store/config';
import demoSlice from '../../store/demo';
import mBoxSlice from '../../store/mBox';
import quickfilterSlice from '../../store/quickfilter';
import searchProviderSlice from '../../store/searchProvider';
import sequenceSlice from '../../store/sequence';
import userSlice from '../../store/user';

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(activeAssetsSlice.actions.clear());
        dispatch(assetSlice.actions.clear());
        dispatch(advancedSearchSlice.actions.resetSearch());
        dispatch(assetMetaKeysSlice.actions.clear());
        dispatch(assetSearchResultSlice.actions.clear());
        dispatch(collectionsSlice.actions.clear());
        dispatch(configSlice.actions.clear());
        dispatch(demoSlice.actions.clear());
        dispatch(mBoxSlice.actions.clear());
        dispatch(quickfilterSlice.actions.clear());
        dispatch(searchProviderSlice.actions.resetQuery());
        dispatch(sequenceSlice.actions.clear());
        dispatch(userSlice.actions.clear());
    });

    window.location.href = '/login.php?logout=1';

    return <></>;
};

Logout.propTypes = {};

Logout.defaultProps = {};

export default Logout;
