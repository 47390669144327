import { useContext } from 'react';
import { ReactComponent as ArrowDownIcon } from '@mediabank/assets/icons/chevron-down.svg';
import { ReactComponent as PencilIcon } from '@mediabank/assets/icons/edit.svg';
import { Box, Typography } from '@mui/material';

import SearchableDropDownContext from './SearchableDropDownContext';

const DropDownButton = () => {
    const {
        hideIcon,
        iconElement,
        buttonLabel,
        buttonType,
        isOpened,
        setIsOpened,
        listData,
        selectedItemsNumber,
        onOpen,
        classes: { useSearchableDropDownButtonStyle },
    } = useContext(SearchableDropDownContext);

    const hasSelectedItem = buttonType === 'select' ? listData.some(listDataItem => listDataItem.checked) : false;

    const { classes: searchableDropDownButton } = useSearchableDropDownButtonStyle({
        isActive: isOpened,
        buttonType,
        hasSelectedItem,
    });

    const IconElement = iconElement;

    const handleOnClick = () => {
        if (!isOpened) {
            onOpen && onOpen();
        }
        setIsOpened(!isOpened);
    };

    if (buttonType === 'dropdown') {
        return (
            <Box className={searchableDropDownButton.wrapper} onClick={handleOnClick}>
                {!hideIcon && iconElement ? IconElement : <PencilIcon className={searchableDropDownButton.icon} />}
                <Typography className={searchableDropDownButton.label}>{buttonLabel}</Typography>
            </Box>
        );
    }

    if (buttonType === 'select') {
        if (!hasSelectedItem) {
            return (
                <Box className={searchableDropDownButton.wrapper} onClick={handleOnClick}>
                    <Typography className={searchableDropDownButton.label}>{buttonLabel}</Typography>
                    {!hideIcon && iconElement ? (
                        IconElement
                    ) : (
                        <ArrowDownIcon className={searchableDropDownButton.icon} />
                    )}
                </Box>
            );
        }

        return (
            <Box className={searchableDropDownButton.wrapper} onClick={handleOnClick}>
                <Typography
                    className={searchableDropDownButton.label}
                    sx={{
                        width: '84%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {listData
                        ?.filter(listDataItem => listDataItem.checked)
                        ?.map((listDataItem, index) => (
                            <span key={listDataItem.id}>
                                {`${listDataItem.label || listDataItem.value}${
                                    index + 1 < selectedItemsNumber ? ', ' : ''
                                }`}
                            </span>
                        ))}
                </Typography>

                {!hideIcon && iconElement ? IconElement : <ArrowDownIcon className={searchableDropDownButton.icon} />}
            </Box>
        );
    }

    return null;
};

export default DropDownButton;
