export default (_theme, colors) => ({
    root: {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            padding: '2px',
        },
    },
    listbox: {
        '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            background: colors.accentLight[0.2],
            '&:hover': {
                background: colors.accentLight[0.1],
            },
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            background: colors.accentLight[0.2],
            '&:hover': {
                background: colors.accentLight[0.1],
            },
        },
    },
});
