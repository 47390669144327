import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import EventData from '../EventTile/EventData';
import NumIndicator from '../MBoxes/NumIndicator';
import MetaData from '../VideoTile/MetaData';
import Thumbnail from '../VideoTile/Thumbnail';
import useDragLayerStyles from './styles';
import useDragLayerState from './useDragLayerState';

const tileSize = 120;
const maxPrivewThumbNum = 3;
const opacityList = [1, 0.5, 0.2];

const DragLayerItem = ({ type, selectedItem }) => {
    if (type === 'asset') {
        const { imgSrc, metaData } = selectedItem;

        return (
            <>
                <Thumbnail.Container>
                    <Thumbnail.Img src={imgSrc} title={metaData?.title} />
                </Thumbnail.Container>
                <MetaData.Simple metaData={metaData} />
            </>
        );
    }

    return <EventData.Simple eventData={selectedItem} />;
};

const SimpleTileDragLayer = ({ selectedItems, type }) => {
    const { classes } = useDragLayerStyles({ tileSize });
    const displayItems = selectedItems.slice(Math.max(selectedItems.length - maxPrivewThumbNum, 0));

    return (
        <>
            <div>
                {displayItems.map((selectedItem, index) => {
                    const reverseIndex = displayItems.length - index - 1;
                    const offset = -reverseIndex * 5;

                    return (
                        <div
                            key={index}
                            className={classes.tile}
                            style={{
                                opacity: opacityList[reverseIndex],
                                zIndex: index,
                                top: offset,
                                left: offset,
                            }}
                        >
                            <DragLayerItem selectedItem={selectedItem} type={type} />
                        </div>
                    );
                })}
            </div>

            <NumIndicator className={classes.numIndicator} number={selectedItems.length} />
        </>
    );
};

// we have to keep the selected items and position in order to show the drop animation
// so instead of using the state from useDragLayer directly, we have to keep one more layer

const DragLayer = ({ onDragChange, type }) => {
    const { classes, cx } = useDragLayerStyles({ tileSize });
    const [isDragging, dragItems, { x, y }, canDrop, didDrop] = useDragLayerState({ type });

    useEffect(() => {
        onDragChange(isDragging);
    }, [isDragging, onDragChange]);

    return (
        <CSSTransition classNames="shrinkFadeOut" in={didDrop} timeout={0}>
            <div
                className={cx(classes.root, { [classes.scaleDown]: canDrop, [classes.dragging]: isDragging })}
                style={{ top: y - tileSize, left: x - tileSize / 2 }}
            >
                <SimpleTileDragLayer selectedItems={dragItems} type={type} />
            </div>
        </CSSTransition>
    );
};

DragLayerItem.propTypes = {
    type: PropTypes.oneOf(['asset', 'event']).isRequired,
    selectedItem: PropTypes.object.isRequired,
};

DragLayerItem.defaultProps = {
    onDragChange: undefined,
};

SimpleTileDragLayer.propTypes = {
    selectedItems: PropTypes.array,
    type: PropTypes.oneOf(['asset', 'event']).isRequired,
};

SimpleTileDragLayer.defaultProps = {
    selectedItems: [],
};

DragLayer.propTypes = {
    onDragChange: PropTypes.func,
    type: PropTypes.oneOf(['asset', 'event']).isRequired,
};

DragLayer.defaultProps = {
    onDragChange: undefined,
};

export default DragLayer;
