import { collectionsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    fetched: false,
    error: null,
    data: [],
    activeId: null,
    activeSubId: null,
};

const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        fetchStart: state => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload || [];
            state.fetched = true;
        },
        fetchError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.fetched = true;
        },
        setActiveCollectionId: (state, action) => {
            state.activeId = action.payload;
        },
        setActiveSubCollectionId: (state, action) => {
            state.activeSubId = action.payload;
        },
        resetCollection: state => {
            state.activeId = null;
            state.activeSubId = null;
        },
        clear: () => initialState,
    },
});

const { fetchStart, fetchError, fetchSuccess } = collectionsSlice.actions;

export default collectionsSlice;

export const fetchAll = () => async dispatch => {
    dispatch(fetchStart());
    try {
        const { data } = await collectionsService.getCollections();
        if (data) {
            dispatch(fetchSuccess(data.data));
        } else {
            throw Error('Fetch error.');
        }
    } catch (e) {
        dispatch(fetchError(e.message));
    }
};
