import { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import DynamicFiltersContext from './DynamicFiltersContext';
import { sendSignalAboutChanges } from './utils';

const DynamicClearFilters = () => {
    const {
        metaKeys,
        setMetaKeys,
        dateRange,
        onChange,
        onClearFilters,
        classes: { useDynamicFiltersStyles },
    } = useContext(DynamicFiltersContext);

    const hasSelectedDateRange = !!(dateRange[0]?.startDate && dateRange[0]?.endDate);

    const allOptionsFromFilters = metaKeys
        ? metaKeys.reduce((previousValue, currentValue) => {
              if (currentValue.options) {
                  return [...currentValue.options, ...previousValue];
              }

              return [...previousValue];
          }, [])
        : [];

    const selectedValues = allOptionsFromFilters.some(option => option.checked);
    const hasSelectedValues = selectedValues || hasSelectedDateRange;

    const { classes: dynamicFiltersStyles } = useDynamicFiltersStyles({ hasSelectedValues });

    const handleOnClick = () => {
        if (!hasSelectedValues) return;

        setMetaKeys(prevMetaKeys => {
            const newData = prevMetaKeys.map(prevMetaKey => ({
                ...prevMetaKey,
                options: prevMetaKey.options
                    ? prevMetaKey.options.map(optionItem => ({ ...optionItem, checked: false }))
                    : null,
            }));

            const newDateRange = [
                {
                    ...dateRange[0],
                    startDate: null,
                    endDate: null,
                },
            ];

            sendSignalAboutChanges(newData, newDateRange, onChange);
            onClearFilters();

            return newData;
        });
    };

    return (
        <Box className={dynamicFiltersStyles.clearWrapper}>
            <Typography className={dynamicFiltersStyles.clearText} onClick={handleOnClick}>
                Clear filters
            </Typography>
        </Box>
    );
};

export default DynamicClearFilters;
