import React from 'react';
import { Typography } from '@development-nl/components-library';
import { ReactComponent as ChevronDownIcon } from '@mediabank/assets/icons/chevron-down.svg';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { height, visible }) => ({
    root: {
        position: 'absolute',
        height: height,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: `linear-gradient(to top, ${theme.palette.common.black}, ${theme.custom.accentDark[0.9]} , transparent)`,
        display: visible ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        transition: 'all 0.3s ease',
        paddingBottom: theme.spacing(2),
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    buttonText: {
        letterSpacing: '1px',
    },
    icon: {
        stroke: theme.palette.primary.light,
        height: 24,
        '&.reverse': {
            transform: 'rotate(180deg)',
        },
    },
}));

const ShowMoreArea = React.forwardRef(({ visible, height, onShowing }, ref) => {
    const { classes, cx } = useStyles({ visible, height });

    return (
        <div className={classes.root}>
            <span className={classes.button} onClick={() => onShowing && onShowing(true)}>
                <Typography ref={ref} className={classes.buttonText} data-cy="mboxMoreButton" variant="h5">
                    MORE
                </Typography>
                <ChevronDownIcon className={cx(classes.icon)} data-cy="mboxChevronDownIcon" />
            </span>
        </div>
    );
});

ShowMoreArea.propTypes = {
    visible: PropTypes.bool.isRequired,
    height: PropTypes.number,
    onShowing: PropTypes.func,
};

ShowMoreArea.defaultProps = {
    height: 100,
    onShowing: undefined,
};

export default ShowMoreArea;
