export const HEADER_LOAD_QUICKFILTER = '@@header/loadQuickfilter';
export const HEADER_CLEAR_QUICKFILTER = '@@header/clearQuickfilter';
export const HEADER_UPDATE_KEYWORD = '@@header/updateKeyword';
export const HEADER_SEARCH_GET_SMART_SEARCHES = '@@header/getSmartSearches';

export const MODE_ADVANCED_SEARCH = 'search,results';
export const MODE_ADVANCED_SEARCH_WITH_PREVIEW = 'search,results,preview';
export const MODE_CALENDAR = 'calendar,results';
export const MODE_CALENDAR_PREVIEW = 'calendar,results,preview';
export const MODE_COLLECTIONS = 'collections,results';
export const MODE_COLLECTIONS_PREVIEW = 'collections,results,preview';
export const MODE_PREVIEW = 'results,preview';
export const MODE_PROPERTIES = 'results,viewer,properties';
export const MODE_RESULTS = 'results';
export const MODE_VIEWER = 'results,viewer';
export const MODE_FOLDER = 'folder';
