import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from '@mediabank/assets/icons/more-horizontal.svg';
import { Box, IconButton, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useMenuStyles = makeStyles()(theme => ({
    paper: {
        overflowY: 'initial',
        overflowX: 'initial',
        transform: 'translateY(1rem)!important',
        '& > ul': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& .showIcon :before': {
            top: -20,
            left: 5,
            position: 'absolute',
            content: "''",
            width: 0,
            height: 0,
            border: '10px solid transparent',
            borderBottom: `10px solid ${theme.custom.accentLight[0.08]}`,
        },
    },
}));

const useButtonStyles = makeStyles()(theme => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: theme.custom.accentDark['0.2'],
        '&:hover': {
            backgroundColor: theme.custom.accentDark[0.4],
        },
    },
    moreIcon: {
        fontSize: 'inherit',
        fill: theme.custom.accentLight[0.9],
    },
}));

const ContextMenu = ({ children, size, iconClassName, position, onOpen, ...props }) => {
    const { classes: menuClasses } = useMenuStyles();
    const { classes: buttonClasses, cx } = useButtonStyles();

    const anchorRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState(
        position !== null ? { top: position.pageY, left: position.pageX } : null
    );
    const [isIndividualMenu, setIsIndividualMenu] = useState(false);

    useEffect(() => {
        let newPosition = position;

        if (position) {
            newPosition = {
                top: position.pageY,
                left: position.pageX,
            };
        }
        setMenuPosition(newPosition);
    }, [position]);

    const handleClick = event => {
        setIsIndividualMenu(true);
        event.preventDefault();

        if (menuPosition) {
            return;
        }

        const { pageX, pageY } = event;

        onOpen &&
            onOpen({
                pageX,
                pageY,
            });

        setMenuPosition({
            top: pageY,
            left: pageX,
        });
    };

    // This function is to inherit the MAIN menu scoped props to all child elements (and to their child):
    const recursiveRender = cloneableElements =>
        React.Children.map(React.Children.toArray(cloneableElements), eachChild => {
            const clonedEle = {};
            for (const key in eachChild) {
                if (key === 'props') {
                    const { children: childElement = null, onClick, ...rest } = eachChild.props || {};
                    clonedEle.props = {};
                    if (childElement) {
                        clonedEle.props.children = recursiveRender(eachChild.props.children);
                    }

                    clonedEle.props = {
                        ...clonedEle.props,
                        ...rest,
                        menuPosition,
                        onClick: event => {
                            event.preventDefault();
                            if (onClick) {
                                onClick(event);
                            }
                            setMenuPosition(null);
                            setIsIndividualMenu(false);
                        },
                        isIndividualMenu,
                    };

                    //Handle exceptions
                    if (eachChild.props['data-cy'] === 'HeaderArchiveContextMenuSubList') {
                        delete clonedEle?.props?.menuPosition;
                        delete clonedEle?.props?.isIndividualMenu;
                    }
                } else {
                    clonedEle[key] = eachChild[key];
                }
            }

            return React.isValidElement(clonedEle) && React.cloneElement(clonedEle);
        });

    return (
        <Box>
            {iconClassName !== '' && (
                <IconButton
                    ref={anchorRef}
                    aria-haspopup="false"
                    aria-label="open"
                    className={buttonClasses.root}
                    size={size}
                    onClick={handleClick}
                >
                    <MoreIcon className={cx(buttonClasses.moreIcon, iconClassName)} />
                </IconButton>
            )}
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorPosition={menuPosition}
                anchorReference="anchorPosition"
                classes={menuClasses}
                keepMounted={false}
                open={!!menuPosition}
                onClose={e => {
                    e.stopPropagation();
                    setMenuPosition(null);
                    setIsIndividualMenu(false);
                }}
                {...props}
            >
                {recursiveRender(children)}
            </Menu>
        </Box>
    );
};

ContextMenu.propTypes = {
    size: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    iconClassName: PropTypes.string,
    position: PropTypes.object,
    onOpen: PropTypes.func,
};

ContextMenu.defaultProps = {
    size: 'medium',
    iconClassName: '',
    position: null,
    onOpen: null,
};

export default ContextMenu;
