import React from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { disabled }) => ({
    prev: {
        display: 'flex',
        alignItems: 'center',
        color: disabled ? theme.palette.text.disabled : 'inherit',
        cursor: disabled ? 'not-allowed' : 'pointer',
    },
}));

const PrevItem = ({ onClick, showLabel, disabled }) => {
    const { classes } = useStyles({ disabled });

    return (
        <div className={classes.prev} onClick={disabled ? () => {} : onClick}>
            <NavigateBeforeIcon />
            {showLabel && 'Previous'}
        </div>
    );
};

PrevItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    showLabel: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
};

PrevItem.defaultProps = {
    showLabel: false,
    disabled: false,
};

export default PrevItem;
