import { configService } from '@mediabank/client';

const configServiceWrapper = {};

for (const [methodName, method] of Object.entries(configService)) {
    configServiceWrapper[methodName] = (...args) =>
        method(...args).catch(error => {
            console.error(`mediabank-next-transition error: ${methodName} service.`, error);

            if (error?.response?.status === 401) {
                window.location.href = '/logout';
            }

            return error.response;
        });
}

export default { ...configServiceWrapper };
