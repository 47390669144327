import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@development-nl/components-library';
import { ReactComponent as WarningIcon } from '@mediabank/assets/icons/warning-asset.svg';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useAssetWarningsStyle = makeStyles()(theme => ({
    tooltipRow: {
        marginBottom: theme.spacing(0.5),
        color: '#fafafa',
    },
}));

const resolveAssetWarnings = assetWarnings => {
    if (!assetWarnings) {
        return [];
    }

    return assetWarnings.map(assetWarning => {
        const assetWarningArray = assetWarning.split(':');
        const metakey = assetWarningArray[0];
        const metavalue = assetWarningArray[1];
        const info = assetWarningArray[3];
        const type = assetWarningArray[2];

        return {
            metakey,
            metavalue,
            type,
            info,
        };
    });
};

const AssetWarningsTooltip = ({ assetmeta, assetWarnings }) => {
    const [warningInfoList, setWarningInfoList] = useState([]);

    const { classes } = useAssetWarningsStyle();

    useEffect(() => {
        let _assetWarnings = [];

        resolveAssetWarnings(assetWarnings).forEach(item => {
            const value = assetmeta[item.metakey];
            if (value === item.metavalue) {
                _assetWarnings.push({ key: item.type, value: item.info });
            }
        });

        setWarningInfoList(_assetWarnings);

        return () => (_assetWarnings = []);
    }, [assetmeta, assetWarnings]);

    const groupedWarnings = groupBy(warningInfoList, 'key');

    return (
        warningInfoList.length > 0 && (
            <Tooltip
                disableInteractive={true}
                enterDelay={10}
                placement="top"
                title={
                    <Box>
                        {groupedWarnings?.info && (
                            <>
                                <Typography className={classes.tooltipRow} color="inherit">
                                    <b>Info:</b>
                                </Typography>
                                <Typography className={classes.tooltipRow} color="inherit">
                                    {groupedWarnings.info.map(i => i.value).join()}
                                </Typography>
                            </>
                        )}

                        {groupedWarnings?.warning && (
                            <>
                                <Typography className={classes.tooltipRow} color="inherit">
                                    <b>Warning:</b>
                                </Typography>
                                <Typography className={classes.tooltipRow} color="inherit">
                                    {groupedWarnings.warning.map(i => i.value).join()}
                                </Typography>
                            </>
                        )}
                    </Box>
                }
            >
                <span>
                    <WarningIcon />
                </span>
            </Tooltip>
        )
    );
};
AssetWarningsTooltip.defaultProps = {
    assetmeta: {},
    assetWarnings: [],
};

AssetWarningsTooltip.propTypes = {
    assetmeta: PropTypes.object.isRequired,
    assetWarnings: PropTypes.array.isRequired,
};

export default AssetWarningsTooltip;
