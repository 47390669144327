import React from 'react';
import playerLoading from '@mediabank/assets/icons/player-loading.png';

import { usePlayerLoadingStyles } from './styles';

const LivePlayerLoading = () => {
    const { classes } = usePlayerLoadingStyles();

    return (
        <div className={classes.root} role="progressbar">
            <img alt="player loading" className={classes.image} src={playerLoading} />
            <h3 className={classes.loading}>{'LOADING VIDEO'}</h3>
        </div>
    );
};

export default LivePlayerLoading;
