import Zendesk, { ZendeskAPI } from 'react-zendesk';

const ZENDESK_KEY = '6df57687-4738-4e15-825a-7206b5cfd61e';

const setting = {
    webWidget: {
        offset: {
            horizontal: '50px',
            vertical: '20px',
        },
        zIndex: 10,
        helpCenter: {
            filter: {
                category: '115000409711,115000409731,115000412792,360000031792',
            },
        },
    },
};

const ZenDesk = () => {
    const handleLoaded = () => {
        //Hide the Support button on load
        ZendeskAPI('webWidget', 'hide');

        //Hide the Support button when user minimize the Chat Widget
        ZendeskAPI('webWidget:on', 'close', function () {
            ZendeskAPI('webWidget', 'hide');
        });
    };

    return <Zendesk defer={true} zendeskKey={ZENDESK_KEY} {...setting} onLoaded={handleLoaded} />;
};

export default ZenDesk;
