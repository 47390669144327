import { createSelector, createSlice } from '@reduxjs/toolkit';

import { SEARCHTYPE } from '../../hooks/useCombinedSearchMode/useCombinedSearchMode';
import { assetListMapper } from '../assets/assetSearchResult';

const initialState = {
    requesting: false,
    data: [],
    combinedData: [],
    filters: [],
    panelFilters: [],
    selectedEvents: [],
    selected: [],
    selectedGroup: { group: [], focusedId: null },
    assetsByEvents: {},
    panelEvents: [],
    nrOfEvents: 0,
    nrOfCombinedPanelEvents: 0,
    focusedId: null,
    activeEvent: null,
    focusedIndex: null,
};

const getAllEventsFromStore = (state, isActiveTabAll) => {
    const data = isActiveTabAll ? state.combinedData.events : state.data.events || [];

    return [...state.panelEvents, ...data];
};

const eventSearchSlice = createSlice({
    name: 'eventSearch',
    initialState,
    reducers: {
        openEvent: (state, { payload: { activeItemId } }) => {
            state.activeEvent = activeItemId;
        },
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        fetchSuccess: (state, { payload: { data: events, combinedData: combinedEvents, searchType = 'events' } }) => {
            try {
                const isCombinedSearch = searchType === SEARCHTYPE.all;
                if (isCombinedSearch) {
                    state.combinedData = combinedEvents.data;
                    state.nrOfCombinedPanelEvents = combinedEvents.data.hits;
                } else {
                    state.data = events.data;
                    state.nrOfEvents = events.data.hits;
                }

                state.requesting = false;
            } catch (error) {
                console.error(error);
            }
        },
        fetchSuccessRelatedEvents: (state, action) => {
            const { payload } = action;

            state.panelEvents = payload.events;
            state.requesting = false;
        },
        updateFilters: (state, action) => {
            const { payload } = action;
            state.filters = payload.filters;
        },
        updatePanelFilters: (state, action) => {
            const { payload } = action;
            state.panelFilters = payload.filters;
        },
        updateAssetsByEvents: (state, action) => {
            const {
                payload: { assetsWithEventId, eventsWithoutAssetId },
            } = action;
            const updatedAssets = {};
            assetsWithEventId.forEach(assetWithEventId => {
                const { eventId } = assetWithEventId;
                const mappedAsset = assetListMapper([assetWithEventId])[0];

                updatedAssets[eventId] = mappedAsset;
            });

            state.assetsByEvents = {
                ...state.assetsByEvents,
                ...updatedAssets,
            };

            state.eventsWithoutAssetId = eventsWithoutAssetId;
        },
        selectItem: (state, { payload: { id, multiSelect, isActiveTabAll, focusedIndex } }) => {
            const events = getAllEventsFromStore(state, isActiveTabAll);

            state.focusedId = id;
            state.focusedIndex = focusedIndex;

            const newEvent = events.find(({ event_id }) => event_id === id);

            if (multiSelect !== true) {
                state.selected = [id];
                state.selectedEvents = [newEvent];
            } else {
                if (state.selected.includes(id)) {
                    state.selected = state.selected.filter(item => item !== id);
                    state.selectedEvents = state.selectedEvents?.filter(({ event_id }) => event_id !== id);
                } else {
                    state.selected.push(id);

                    if (state.selectedEvents && state.selectedEvents.length) {
                        state.selectedEvents = [...state.selectedEvents, newEvent];
                    } else {
                        state.selectedEvents = [newEvent];
                    }
                }
            }
        },
        selectItems: (
            state,
            { payload: { startID, endID, focusedId, isActiveTabAll, multiSelect, keyboardSelect, focusedIndex } }
        ) => {
            const events = getAllEventsFromStore(state, isActiveTabAll);

            const startIndex = events.findIndex(event => event.event_id === startID);
            const lastIndex = events.findIndex(event => event.event_id === endID);
            const selectedIdsGroup = events
                .slice(Math.min(startIndex, lastIndex), Math.max(startIndex, lastIndex) + 1)
                .map(item => item.event_id);

            const isCurrentGroup = state.selectedGroup.focusedId === focusedId;

            let deselectItems = [];

            if (isCurrentGroup && (!multiSelect || keyboardSelect)) {
                deselectItems = state.selectedGroup.group?.filter(item => !selectedIdsGroup.includes(item)) || [];
            }

            let selectedIds = [...state.selected, ...selectedIdsGroup.filter(item => !state.selected.includes(item))];

            if (!!deselectItems.length) {
                selectedIds = selectedIds.filter(item => !deselectItems.includes(item));
            }

            state.selected = selectedIds;
            state.selectedGroup = { group: selectedIdsGroup, focusedId };
            state.focusedId = focusedId;
            state.focusedIndex = focusedIndex;

            if (events && !!selectedIds.length) {
                const newSelectedEvents = events.filter(({ event_id }) => selectedIds.includes(event_id));
                state.selectedEvents = [...newSelectedEvents];
            }
        },

        resetSelection: state => {
            state.selected = [];
            state.selectedEvents = [];
            state.selectedGroup = { group: [], focusedId: null };
            state.focusedId = null;
            state.focusedIndex = null;
        },

        setSelectedItems: (state, { payload: { ids, multiSelect, isActiveTabAll } }) => {
            const events = getAllEventsFromStore(state, isActiveTabAll);
            let selectedIds = [];

            if (multiSelect) {
                selectedIds = [...state.selected, ...ids.filter(item => !state.selected.includes(item))];
            } else {
                selectedIds = ids;
            }

            state.selected = selectedIds;

            if (events && !!selectedIds.length) {
                const newSelectedAssets = events.filter(({ event_id }) => selectedIds.includes(event_id));
                state.selectedAssets = [...newSelectedAssets];
            }
        },
        clear: state => ({
            ...initialState,
            combinedData: state.combinedData,
        }),
        clearSelectItems: state => {
            state.selected = [];
            state.selectedEvents = [];
            state.selectedGroup = { group: [], focusedId: null };
            state.focusedId = null;
            state.focusedIndex = null;
        },
        resetEvents: state => {
            state.data = [];
            state.nrOfEvents = 0;
            state.requesting = false;
        },
        resetCombinedEvents: state => {
            state.combinedData = [];
            state.searchType = SEARCHTYPE.all;
            state.nrOfEvents = 0;
            state.requesting = false;
        },
        resetEventPanel: state => {
            state.panelEvents = [];
            state.panelFilters = [];
        },
        resetFilters: state => {
            state.filters = [];
            state.panelFilters = [];
        },
    },
});

const isRequesting = state => state.eventSearch.requesting;
const events = state => state.eventSearch.data.events || [];
const selectedEvents = state => state.eventSearch.selectedEvents;
const panelEvents = state => state.eventSearch.panelEvents || [];

const selectAssetsByEvents = state => state.eventSearch.assetsByEvents;
const selectEventsWithoutAssetId = state => state.eventSearch.eventsWithoutAssetId;
const getFocusedId = state => state.eventSearch.focusedId;
const getFocusedIndex = state => state.eventSearch.focusedIndex;

const getAssetsByEvents = eventIds =>
    createSelector(selectAssetsByEvents, assetsByEvents =>
        eventIds.length === 1 ? assetsByEvents[eventIds[0]] : eventIds.map(eventId => assetsByEvents[eventId])
    );

const getEventsWithoutAssetId = () =>
    createSelector(selectEventsWithoutAssetId, eventsWithoutAssetId => eventsWithoutAssetId);

const getActiveEventId = state => state.eventSearch.activeEvent;

export default {
    ...eventSearchSlice,
    selectors: {
        getAssetsByEvents,
        isRequesting,
        events,
        selectedEvents,
        getEventsWithoutAssetId,
        panelEvents,
        getFocusedId,
        getActiveEventId,
        getFocusedIndex,
    },
};
