import React, { useContext } from 'react';
import { NotificationsContext } from '@mediabank/uikit-v5';

import useInjectEpic from '../../hooks/useInjectEpic';
import { epic as eventSearchEpic } from '../../store/eventSearch';
import epic from './epic';

const ConnectedSearchProvider = () => {
    const { addToastError } = useContext(NotificationsContext);

    useInjectEpic({
        key: 'searchProvider',
        epic: (...args) => epic(...args, { addToastError }),
    });

    useInjectEpic({
        key: 'eventSearch',
        epic: (...args) => eventSearchEpic(...args, { addToastError }),
    });

    return <></>;
};

export { ConnectedSearchProvider };
