import './web-components/publicPath';

import React from 'react';
import ReactDOM from 'react-dom';

import NextApp from './web-components/NextApp';

const defineCustomReactElement = (tag, ReactElement) =>
    customElements.define(
        tag,
        class extends HTMLElement {
            connectedCallback() {
                ReactDOM.render(<ReactElement />, this);
            }
        }
    );

defineCustomReactElement('obx-mediabank-next-transition', NextApp);
