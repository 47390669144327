import { makeStyles } from 'tss-react/mui';

const useDragLayerStyles = makeStyles()((theme, { tileSize }) => ({
    root: {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 1600,
        transition: 'transform 0.2s ease, opacity 0.4s ease',
        transformOrigin: 'center bottom',
        opacity: 0,
        '&.shrinkFadeOut-enter': {
            transform: 'scale(0.5)',
        },
        '&.shrinkFadeOut-exit-done': {
            transform: 'scale(0.3)',
            opacity: 0,
        },
    },
    dragging: {
        opacity: 1,
    },
    scaleDown: {
        transform: 'scale(0.5)',
        opacity: 0.5,
    },
    tile: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderBottomLeftRadius: theme.spacing(1.25),
        borderBottomRightRadius: theme.spacing(1.25),
        width: `${tileSize}px`,
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        '&:last-child': {
            position: 'relative',
        },
    },
    numIndicator: {
        position: 'absolute',
        right: theme.spacing(-1),
        bottom: theme.spacing(-1),
        zIndex: 1000,
    },
}));

export default useDragLayerStyles;
