import DateFnsUtils from '@date-io/date-fns';
import { assetsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { SEARCHTYPE } from '../../hooks/useCombinedSearchMode/useCombinedSearchMode';
import activeAssets from './activeAssets';

const dateFns = new DateFnsUtils();

export const assetListMapper = (assetList = []) =>
    assetList.map(asset =>
        asset.metaData
            ? { ...asset }
            : {
                  imgSrc: asset.attributes.assetmeta.Image,
                  posterCount: asset.attributes.assetmeta.NumberOfPosters,
                  selectedPosterCount: null,
                  metaData: {
                      title: asset.attributes.assetmeta.Title,
                      eventType: asset.attributes.assetmeta.SubTitle,
                      eventInfo: '',
                      Category: asset.attributes.assetmeta.Category,
                      SegmentType: asset.attributes.assetmeta.SegmentType,
                      date:
                          asset.attributes.assetcreated &&
                          dateFns.format(new Date(`${asset.attributes.assetcreated}00`), 'dd MMM yyyy'), // it's missing the '00' to be valid format
                      extra: [],
                      uploadCompanyName: asset.attributes.assetmeta.UploadCompanyName,
                      frameRate: asset.attributes.assetmeta.VideoFrameRate
                          ? asset.attributes.assetmeta.VideoFrameRate
                          : '24',
                  },
                  isLive: false,
                  isLocked: asset.attributes.assetlocked && Boolean(asset.attributes.assetlocked === '1'),
                  isMirrored:
                      asset.attributes.assetmeta.IsMirror && Boolean(asset.attributes.assetmeta.IsMirror === 'yes'),
                  id: String(asset.attributes.assetid),
                  duration: asset.attributes.assetduration && Number(asset.attributes.assetduration),
                  ProgressiveURL: asset.attributes.ProgressiveURL,
                  attributes: { ...asset.attributes, assetid: String(asset.attributes.assetid) },
                  dataFeeds: asset.dataFeeds,
              }
    );

const initialState = {
    loading: true,
    data: [],
    combinedData: [],
    loadingMore: false,
    fetchDataFeed: false,
    hasMore: false,
    nextLink: null,
    nrOfItems: 0,
    nrOfCombinedPanelItems: 0,
    selected: [],
    selectedAssets: [],
    selectedGroup: { group: [], focusedId: null },
    page: 1,
    focusedId: null,
    focusedIndex: null,
};

const assetSearchResultSlice = createSlice({
    name: 'assetSearchResult',
    initialState,
    reducers: {
        resetSelection: state => {
            state.selected = [];
            state.selectedAssets = [];
            state.selectedGroup = { group: [], focusedId: null };
            state.focusedId = null;
            state.focusedIndex = null;
        },
        selectItem: (state, { payload: { id, multiSelect, isActiveTabAll, focusedId, focusedIndex } }) => {
            const assets = isActiveTabAll ? state.combinedData : state.data;

            const newAsset = assets?.find(({ id: itemId }) => itemId === id);
            state.focusedId = focusedId;
            state.focusedIndex = focusedIndex;

            if (multiSelect !== true) {
                state.selected = [id];
                state.selectedAssets = [newAsset];
            } else {
                if (state.selected.includes(id)) {
                    state.selected = state.selected.filter(item => item !== id);
                    state.selectedAssets = state.selectedAssets?.filter(({ id: itemId }) => itemId !== id);
                } else {
                    state.selected.push(id);

                    if (state.selectedAssets && state.selectedAssets.length) {
                        state.selectedAssets = [...state.selectedAssets, newAsset];
                    } else {
                        state.selectedAssets = [newAsset];
                    }
                }
            }
        },
        selectItems: (
            state,
            { payload: { startID, endID, focusedId, multiSelect, keyboardSelect, isActiveTabAll, focusedIndex } }
        ) => {
            const assets = isActiveTabAll ? state.combinedData : state.data;

            const startIndex = assets.findIndex(asset => asset.id === startID);
            const lastIndex = assets.findIndex(asset => asset.id === endID);

            const selectedIdsGroup = assets
                .slice(Math.min(startIndex, lastIndex), Math.max(startIndex, lastIndex) + 1)
                .map(item => item.id);

            const isCurrentGroup = state.selectedGroup.focusedId === focusedId;

            let deselectItems = [];
            if (isCurrentGroup && (!multiSelect || keyboardSelect)) {
                deselectItems = state.selectedGroup.group?.filter(item => !selectedIdsGroup.includes(item)) || [];
            }
            let selectedIds = [...state.selected, ...selectedIdsGroup.filter(item => !state.selected.includes(item))];

            if (!!deselectItems.length) {
                selectedIds = selectedIds.filter(item => !deselectItems.includes(item));
            }

            state.selected = selectedIds;
            state.selectedGroup = { group: selectedIdsGroup, focusedId };
            state.focusedId = focusedId;
            state.focusedIndex = focusedIndex;

            if (assets && !!selectedIds.length) {
                const newSelectedAssets = assets.filter(({ id }) => selectedIds.includes(id));
                state.selectedAssets = [...newSelectedAssets];
            }
        },
        setSelectedItems: (state, { payload: { ids, multiSelect, isActiveTabAll } }) => {
            const assets = isActiveTabAll ? state.combinedData : state.data;
            let selectedIds = [];

            if (multiSelect) {
                selectedIds = [...state.selected, ...ids.filter(item => !state.selected.includes(item))];
            } else {
                selectedIds = ids;
            }

            state.selected = selectedIds;

            if (assets && !!selectedIds.length) {
                const newSelectedAssets = assets.filter(({ id }) => selectedIds.includes(id));
                state.selectedAssets = [...newSelectedAssets];
            }
        },
        fetchStart: (state, action) => {
            state.loading = action.payload.page === 1;
            state.hasMore = false;
            state.loadingMore = action.payload.page !== 1;
        },
        fetchDataFeed: (state, action) => {
            state.fetchDataFeed = action.payload;
        },
        fetchSuccess: (
            state,
            { payload: { data: assets, combinedData: combinedAssets, page, searchType = SEARCHTYPE.assets } }
        ) => {
            try {
                const isCombinedSearch = searchType === SEARCHTYPE.all;
                const assetData = isCombinedSearch ? combinedAssets.data : assets.data;
                const parsedData = assetListMapper(assetData);

                if (isCombinedSearch) {
                    state.combinedData = parsedData;
                    state.nrOfCombinedPanelItems = get(combinedAssets, 'meta.noItems', 0);
                } else {
                    state.data = page === 1 ? parsedData : [...state.data, ...parsedData];
                    state.page = page;
                    state.hasMore = get(assets, 'meta.noItems', 0) > state.data.length;
                    state.nextLink = get(assets, 'links.next', null);
                    state.nrOfItems = get(assets, 'meta.noItems', 0);
                }
                state.loading = false;
                state.loadingMore = false;
            } catch {
                state.loading = false;
                state.loadingMore = false;
                state.hasMore = false;
            }
        },
        setLoading: (state, { payload }) => {
            if (payload && payload.page === 1) {
                state.loading = true;
                state.hasMore = false;
            }
        },
        clearSelectItems: state => {
            state.selected = [];
            state.selectedAssets = [];
            state.selectedGroup = { group: [], focusedId: null };
            state.focusedId = null;
            state.focusedIndex = null;
        },
        setAssetPosterCount: (state, { payload: { assetId, poster, url } }) => {
            // TODO STATE.DATA!
            state.data = state.data.map(dataItem =>
                dataItem.id !== assetId
                    ? dataItem
                    : {
                          ...dataItem,
                          selectedPosterCount: poster,
                          selectedPosterURL: url,
                      }
            );
        },
        clear: state => ({ ...initialState, data: state.data || [], combinedData: state.combinedData || [] }),
        setDataFeeds: (state, { payload: { id, dataFeeds } }) => {
            // TODO STATE.DATA!
            state.data = state.data.map(dataItem =>
                dataItem.id.toString() !== id.toString()
                    ? dataItem
                    : {
                          ...dataItem,
                          dataFeeds,
                      }
            );
        },
        // TODO STATE.DATA!
        clearPage: state => {
            state.loading = true;
            state.data = [];
            state.selected = [];
            state.selectedAssets = [];
        },
    },
});

const getAllSearchResults = state => state.assetSearchResult.data;
const getSelectedIds = state => state.assetSearchResult.selected;
const isDataFeedFetching = state => state.assetSearchResult.fetchDataFeed;
const getFocusedId = state => state.assetSearchResult.focusedId;
const getFocusedIndex = state => state.assetSearchResult.focusedIndex;

const { fetchDataFeed } = assetSearchResultSlice.actions;

const getDataFeeds = ({ id }) => async dispatch => {
    dispatch(fetchDataFeed(true));

    try {
        const response = await assetsService.getDataFeeds({ id });
        const {
            status,
            data: { data },
        } = response;

        if (status === 200) {
            dispatch(assetSearchResultSlice.actions.setDataFeeds({ id, dataFeeds: data.feeds }));
            dispatch(activeAssets.actions.setDataFeeds({ id, dataFeeds: data.feeds }));
        }
    } catch (ex) {
        console.log(ex.message);
    } finally {
        dispatch(fetchDataFeed(false));
    }
};

export default {
    ...assetSearchResultSlice,
    selectors: { getAllSearchResults, getSelectedIds, isDataFeedFetching, getFocusedId, getFocusedIndex },
    getDataFeeds,
};
