import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    data: {},
};

const miniEnvDialogSlice = createSlice({
    name: '@miniEnvDialog',
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setData: (state, { payload: { propertyName, propertyValue } }) => {
            state[propertyName] = propertyValue;
        },
    },
});

const getIsOpen = ({ miniEnvDialog }) => miniEnvDialog.isOpen;

export default {
    ...miniEnvDialogSlice,
    selectors: { getIsOpen },
};
