// import { updateUrlQueryString } from '@mediabank/utils';
import { filter, ignoreElements, tap } from 'rxjs/operators';

import { getEntryFromAction } from './ui';

export const updateUrlQueryString = ({ path, params }) => {
    const urlParams = new URLSearchParams(window.location.search);
    let isChanged = false;
    params.forEach(param => {
        if (urlParams.get(param.key) !== String(param.value)) {
            urlParams.set(param.key, param.value);
            isChanged = true;
        }
    });

    let pathName = path ? path : window.location.pathname;

    if (!isChanged && path) {
        const dashboardSlug = window.location.pathname.split('/')[2];

        if (dashboardSlug !== path) {
            //Check if url path is changed
            isChanged = true;
        }
    }

    if (isChanged) {
        let keysForDelete = [];
        //Delete null or empty params
        urlParams.forEach((value, key) => {
            const falsyValues = [null, undefined, NaN, '', 0];
            const isValueFalsy = falsyValues.some(falsyValue => String(falsyValue) === value);

            if (isValueFalsy) {
                keysForDelete.push(key);
            }
        });
        keysForDelete.forEach(key => {
            urlParams.delete(key);
        });

        let url = !!urlParams.toString().length ? `${pathName}?${urlParams.toString()}` : pathName;

        window.history.pushState(null, '', url);
    }
};

/**
 * The purpose of the following epic is to update url params when a specific action is dispatched.
 * It will only update url params for actions present in the actionToUrlParamMapping.
 * For any new acctions that should be ampped to url params the action needs to be added to
 * the actionToUrlParamMapping. Also sync from url param to store needs to be configured.
 * This is done from the UIParamsToStateProvider which also needs to be updated so it maps
 * url param changes to store.
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
export const syncURLParamsEpic = action$ => {
    let aggregatedActions = [];
    const aggregationTime = 1000; // In milliseconds
    let interval = null;

    return action$.pipe(
        filter(action => /^ui\/set.*/.test(action.type)), // Only match uiSet* actions
        tap(action => {
            aggregatedActions.push(action);

            if (!interval) {
                interval = setInterval(() => {
                    const urlParams = {};
                    let path = null;
                    aggregatedActions.forEach(action => {
                        const entry = getEntryFromAction(action.type);

                        // Only update url param if a mapping exists between action and url param
                        if (entry) {
                            const setValue = entry.set || (val => val);
                            if (entry.key === 'dashboardSlug') {
                                path = setValue(action.payload);
                            } else {
                                urlParams[entry.key] = setValue(action.payload);
                            }
                        }
                    });

                    // Silent update
                    updateUrlQueryString({
                        path,
                        params: Object.keys(urlParams).map(key => ({
                            key,
                            value: urlParams[key],
                        })),
                    });

                    clearInterval(interval);
                    interval = null;
                    aggregatedActions = [];
                }, aggregationTime);
            }
        }),
        ignoreElements()
    );
};

export default syncURLParamsEpic;
