import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { NotificationsContext } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';

import mBoxSlice, { getMboxes } from '../../../store/mBox';
import userSlice from '../../../store/user';
import { getAssetsForEvents } from '../../EventSearchResult/utils';
import MBoxDetailModal from './MBoxDetailModal';
import MBoxesDndArea from './MBoxesDndArea';

// exported for testing puposes
export const formatErrorMessage = error => {
    let message;

    if (!!error.response?.data?.errors?.length) {
        message = error.response.data.errors
            .filter(error => error.detail)
            .map(error => error.detail.replace(/\.$/, ''))
            .join('. ');
    }

    if (message && error.config?.url && error.config.url.includes('/quicklinks')) {
        message += '. Make sure email/phone is valid!';
    }

    return message || error.message;
};

const {
    selectors: { getMBoxSlice, getOpenedBoxId },
    actions: { addAssets, addPreSelectedAssets, setPanelOpen, setSelectedItems, removeItemsById, setOpenedBoxId },
} = mBoxSlice;

const MISSING_ROLE_ERROR_MSG = 'Missing roles for /api/v1/destinations';
const GENERIC_ERROR_MSG = 'Could not fetch MBoxes.';

const ConnectedMBoxesDndArea = props => {
    const { addToastError, addToastSuccess } = useContext(NotificationsContext);
    const dispatch = useDispatch();

    const { isPanelOpen, boxes, selectedItems, preSelectedAssets } = useSelector(getMBoxSlice);
    const openedBoxId = useSelector(getOpenedBoxId);
    const user = useSelector(userSlice.selectors.getUser);

    const [executeMboxState, executeMbox] = useAsyncFn(async (id, data, executeMethod) => {
        try {
            if (typeof executeMethod !== 'function') {
                throw new Error('invalid executeMethod');
            }
            const response = await executeMethod({ id, attributes: data });
            const assetUniqueIds = data.uniqueIds ? data.uniqueIds : parseUniqueIds(data.assets);

            addToastSuccess({
                title: 'The job has been successfully added to the queue.',
                subtitle:
                    'Please note that file creation and delivery may take some time. You will receive an email as soon as the file is ready to download',
            });

            dispatch(removeItemsById({ id, itemIds: assetUniqueIds }));
            dispatch(setOpenedBoxId(null));

            return response;
        } catch (err) {
            showError({
                title: 'Error',
                subtitle: formatErrorMessage(err),
            });
        }
    }, []);

    const showError = details => {
        addToastError(details);
    };

    const parseUniqueIds = assets => assets?.map(asset => asset.uniqueId) || [];

    const closePanel = () => {
        dispatch(setPanelOpen(false));
        dispatch(setSelectedItems(null));
        dispatch(addPreSelectedAssets({ assets: [] }));
    };

    const dropItem = async (mBoxId, items) => {
        try {
            const { assetsWithEventId, eventsWithoutAssetId } = await getAssetsForEvents(items);
            if (eventsWithoutAssetId && eventsWithoutAssetId.length > 0) {
                eventsWithoutAssetId.forEach(event =>
                    showError({
                        subtitle: `Asset for this event could not be found: ${event.event_id}`,
                    })
                );
            }

            dispatch(addAssets({ targetMBoxIds: mBoxId, assets: assetsWithEventId }));
            dispatch(setSelectedItems(null));
        } catch (err) {
            console.error(`ERROR: ConnectedMBoxesDndArea/dropItem: ${err.message}`);
            showError({
                title:
                    'Something went wrong while fetching asset for this event. Please refresh your browser and try again.',
            });
        }
    };

    const removeItems = itemIds => {
        dispatch(removeItemsById({ id: openedBoxId, itemIds }));
    };

    useEffect(() => {
        dispatch(getMboxes());

        if (user?.error && user.error !== '' && user.error !== null) {
            showError({
                title: user.error,
                subtitle: user.error?.indexOf('403') !== -1 ? MISSING_ROLE_ERROR_MSG : GENERIC_ERROR_MSG,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, user?.error]);

    return (
        <>
            {openedBoxId && (
                <MBoxDetailModal
                    loading={executeMboxState.loading}
                    mBox={boxes.find(box => box.id === openedBoxId)}
                    open={true}
                    preSelectedAssets={preSelectedAssets}
                    onClose={() => dispatch(setOpenedBoxId(null))}
                    onExecute={(data, callback) => executeMbox(openedBoxId, data, callback)}
                    onRemoveItems={removeItems}
                />
            )}
            <MBoxesDndArea
                boxes={boxes}
                isOpen={isPanelOpen}
                selectedItems={selectedItems}
                showError={showError}
                onClose={closePanel}
                onDrop={dropItem}
                onOpenBox={data => dispatch(setOpenedBoxId(data.id))}
                {...props}
            />
        </>
    );
};

ConnectedMBoxesDndArea.propTypes = {
    children: PropTypes.node,
};

ConnectedMBoxesDndArea.defaultProps = {
    children: undefined,
};

export default ConnectedMBoxesDndArea;
