export const SEARCH_PROVIDER_ASSET_SEARCH_SUCCESS = '@@searchProvider/assetSearchSuccess';
export const SEARCH_PROVIDER_COMBINED_ASSET_SEARCH_SUCCESS = '@@searchProvider/combinedAssetSearchSuccess';
export const SEARCH_PROVIDER_EVENT_SEARCH_SUCCESS = '@@searchProvider/eventSearchSuccess';
export const SEARCH_PROVIDER_COMBINED_EVENT_SEARCH_SUCCESS = '@@searchProvider/combinedEventSearchSuccess';

export const SEARCH_PROVIDER_REQUEST_ASSET_DEFAULT = '@@searchProvider/requestDefaultAsset';
export const SEARCH_PROVIDER_REQUEST_COMBINED_ASSET_DEFAULT = '@@searchProvider/requestDefaultCombinedAsset';
export const SEARCH_PROVIDER_REQUEST_EVENT_DEFAULT = '@@searchProvider/requestDefaultEvent';
export const SEARCH_PROVIDER_REQUEST_COMBINED_EVENT_DEFAULT = '@@searchProvider/requestDefaultCombinedEvent';

export const SEARCH_PROVIDER_POPULATE_ASSETS_WITH_EMPTY_DATA = '@@searchProvider/populateAssetsWithEmptyData';
export const SEARCH_PROVIDER_POPULATE_EVENTS_WITH_EMPTY_DATA = '@@searchProvider/populateEventsWithEmptyData';

export const SEARCH_PROVIDER_SUBMIT_ASSET_QUERY = '@@searchProvider/submitAssetQuery';
export const SEARCH_PROVIDER_SUBMIT_COMBINED_ASSET_QUERY = '@@searchProvider/submitCombinedAssetQuery';

export const SEARCH_PROVIDER_SUBMIT_EVENT_QUERY = '@@searchProvider/submitEventQuery';
export const SEARCH_PROVIDER_SUBMIT_COMBINED_EVENT_QUERY = '@@searchProvider/submitCombinedEventQuery';

export const SEARCH_PROVIDER_LOAD_COLLECTION = '@@searchProvider/loadCollection';
export const SEARCH_PROVIDER_CLEAR_COLLECTION = '@@searchProvider/clearCollection';
export const SEARCH_PROVIDER_RESET_RESULT = '@@searchProvider/resetResult';
export const WORKSPACES = [
    {
        name: 'Full results',
        path: 'results',
        panelLabels: ['Results'],
    },
    {
        name: 'Asset view',
        path: 'results,viewer',
        panelLabels: ['Results', 'Player', 'Timeline'],
    },
    {
        name: 'Asset quick view',
        path: 'results,preview',
        panelLabels: ['Results', 'Preview'],
        urlParams: {
            tab: 'properties',
        },
    },
    {
        name: 'Collections',
        path: 'collections,results',
        panelLabels: ['Collections', 'Results'],
    },
    {
        name: 'Calendar',
        path: 'calendar,results',
        panelLabels: ['Calendar', 'Results'],
    },
    {
        name: 'Asset detailed view',
        path: 'results,viewer,properties',
        panelLabels: ['Results', 'Player', 'Properties', 'Timeline'],
        urlParams: {
            timeline: true,
            timelineOpen: true,
        },
        assetWorkspace: true,
    },
];
