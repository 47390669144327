import React from 'react';
import { ReactComponent as KeyboardArrowDownIcon } from '@mediabank/assets/icons/chevron-down.svg';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        paddingRight: theme.spacing(0.75),
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
    icon: {
        fontSize: 24,
    },
}));

const SelectDropdownIndicator = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.root}>
            <KeyboardArrowDownIcon className={classes.icon} />
        </div>
    );
};

SelectDropdownIndicator.propTypes = {
    selectProps: PropTypes.object.isRequired,
};
SelectDropdownIndicator.defaultProps = {};

export default SelectDropdownIndicator;
