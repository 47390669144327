import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Alert from '../Alert';

const useStyles = makeStyles()(theme => ({
    warning: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const AssetMetaConditionalWrapper = ({ assetMetaField, settings }) => {
    const { classes } = useStyles();

    const { fieldCondition, fieldValue, messageOnMatch } = settings || {};

    const getConditionMatch = ({ fieldCondition, fieldValue, assetMetaField }) => {
        switch (fieldCondition) {
            case 'isEmpty':
                return !assetMetaField;
            case 'isNotEmpty':
                return !!assetMetaField;
            case 'isEquals':
                return fieldValue === assetMetaField;
            case 'isNotEquals':
                return fieldValue !== assetMetaField;
            default:
                return false;
        }
    };

    const displayMessage = getConditionMatch({ fieldCondition, fieldValue, assetMetaField });

    return (
        <>
            {displayMessage && (
                <Alert className={classes.warning} severity="warning">
                    {messageOnMatch}
                </Alert>
            )}
        </>
    );
};

AssetMetaConditionalWrapper.propTypes = {
    assetMetaField: PropTypes.string,
    settings: PropTypes.object,
};

AssetMetaConditionalWrapper.defaultProps = {
    assetMetaField: null,
    settings: null,
};

const FormAssetMetaConditionalField = ({ name, ...fieldProps }) => (
    <Field
        name={name}
        render={fieldRenderProps => <AssetMetaConditionalWrapper name={name} {...fieldRenderProps} />}
        {...fieldProps}
    />
);

FormAssetMetaConditionalField.propTypes = {
    name: PropTypes.string.isRequired,
};

export { AssetMetaConditionalWrapper };

export default FormAssetMetaConditionalField;
