import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    activeDialog: null,
    isOpenDateRange: false,
    selectedEvent: {},
    searchedEvent: '',
};

const searchHappenings = createSlice({
    name: 'searchHappenings',
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload.isOpen;
            state.activeDialog = action.payload.dialogId || null;
        },
        setIsOpenDateRange: (state, action) => {
            state.isOpenDateRange = action.payload;
        },
        setSelectedEvent: (state, action) => {
            state.selectedEvent = action.payload;
        },
        setSearchedEvent: (state, action) => {
            state.searchedEvent = action.payload;
        },
    },
});

const selectors = {
    getIsOpen: state => Boolean(state.searchHappenings.isOpen),
    getActiveDialog: state => state.searchHappenings.activeDialog,
    getIsOpenDateRange: state => Boolean(state.searchHappenings.isOpenDateRange),
    getSelectedEvent: state => state.searchHappenings.selectedEvent,
    getSearchedEvent: state => state.searchHappenings.searchedEvent,
};

export default {
    ...searchHappenings,
    selectors,
};
