import { createSelector, createSlice } from '@reduxjs/toolkit';

import { assetsService } from '../../services';

const initialState = {
    requesting: false,
    loading: false,
    asset: {},
    viewHistory: [],
    isDeleteDialogOpen: false,
    isRestoreDialogOpen: false,
    assets: [],
};

const assetSlice = createSlice({
    name: 'asset',
    initialState,
    reducers: {
        requestStart: state => {
            state.requesting = true;
        },
        startCall: state => {
            state.loading = true;
        },
        toggleIsDeleteDialogOpen: state => {
            state.isDeleteDialogOpen = !state.isDeleteDialogOpen;
        },
        toggleIsRestoreDialogOpen: state => {
            state.isRestoreDialogOpen = !state.isRestoreDialogOpen;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        endCall: state => {
            state.loading = false;
        },
        update: (state, action) => {
            state.asset = action.payload.asset;
        },
        addToHistory: (state, action) => {
            state.viewHistory.push(action.payload);
        },
        addAsset: (state, action) => {
            const asset = { ...action.payload, assetid: action.payload.assetid };
            const exist = state.assets.some(item => item.assetid === asset.assetid);
            !exist && state.assets.push(asset);
        },
        addAssets: (state, action) => {
            const {
                payload: { data: assets, page },
            } = action;
            const parsedData = (assets?.data || []).map(asset => asset.attributes);
            state.assets = page === 1 ? parsedData : [...state.assets, ...parsedData];
        },
        clear: () => initialState,
    },
});

const { requestStart, requestFinished, update } = assetSlice.actions;

const updateAsset = (assetId, assettitle, assetmeta, updateMirrors) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.updateAsset({ assetId, assettitle, assetmeta, updateMirrors });
        const { status } = response;

        dispatch(requestFinished());

        if (status === 200) {
            const asset = response?.data?.data?.attributes || {};
            dispatch(update({ assetId, asset }));
        } else {
            throw Error(status);
        }

        return response;
    } catch (error) {
        dispatch(requestFinished());
        throw Error(error);
    }
};

const bulkAssetMeta = (assetIds, assetmeta, lock) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.bulkAssetsProperties({
            attributes: {
                assets: assetIds,
                lock,
                skipOnRequirement: true,
                properties: assetmeta,
            },
        });

        dispatch(requestFinished());

        return response;
    } catch (error) {
        dispatch(requestFinished());
        throw Error(error);
    }
};

const bulkUpdateAssetMediaInfo = (assetIds, skipOnRequirement) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.bulkUpdateAssetMediaInfo({ assetIds, skipOnRequirement });

        dispatch(requestFinished());

        return response;
    } catch (error) {
        dispatch(requestFinished());
        throw Error(error);
    }
};

const bulkGenerateScrubbingPosters = (assetIds, skipOnRequirement) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.bulkGenerateScrubbingPosters({ assetIds, skipOnRequirement });

        dispatch(requestFinished());

        return response;
    } catch (error) {
        dispatch(requestFinished());
        throw Error(error);
    }
};

const bulkUpdateProxyFiles = assetIds => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.bulkUpdateProxyFiles({ assetIds });

        dispatch(requestFinished());

        return response;
    } catch (error) {
        dispatch(requestFinished());
        throw Error(error);
    }
};

const bulkDeleteAsset = (assetIds, type) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.bulkDeleteAsset({ assetIds, type });

        dispatch(requestFinished());

        return response;
    } catch (error) {
        dispatch(requestFinished());
        throw Error(error);
    }
};

const bulkRestoreAsset = (assetIds, type) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.bulkRestoreAsset({ assetIds, type });

        dispatch(requestFinished());

        return response;
    } catch (error) {
        dispatch(requestFinished());
        throw Error(error);
    }
};

const isAssetDeleteDialogOpen = state => state.asset.isDeleteDialogOpen;
const isAssetRestoreDialogOpen = state => state.asset.isRestoreDialogOpen;
const isLoading = state => state.asset.loading;
const isRequesting = state => state.asset.requesting;
const getPreviousAsset = state =>
    state.asset.viewHistory.length ? state.asset.viewHistory[state.asset.viewHistory.length - 2] : null;
const getAllAssets = state => state.asset.assets;
const getAssetById = assetid => createSelector(getAllAssets, assets => assets.find(item => item.assetid === assetid));

export default {
    ...assetSlice,
    updateAsset,
    bulkRestoreAsset,
    bulkDeleteAsset,
    bulkAssetMeta,
    bulkGenerateScrubbingPosters,
    bulkUpdateAssetMediaInfo,
    bulkUpdateProxyFiles,
    selectors: {
        isLoading,
        isRequesting,
        isAssetDeleteDialogOpen,
        isAssetRestoreDialogOpen,
        getAllAssets,
        getAssetById,
        getPreviousAsset,
    },
};
