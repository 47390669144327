import {
    SEARCH_RESULTS_GET_ASSET_BY_EVENT,
    SEARCH_RESULTS_GET_EVENTS_BY_HAPPENING_SUCCESS,
    SEARCH_RESULTS_INIT_PANEL_EVENT_SEARCH,
} from './constants';

export const initPanelEventSearch = payload => ({
    type: SEARCH_RESULTS_INIT_PANEL_EVENT_SEARCH,
    payload,
});

export const getAssetsByEventsEpicAction = events => ({
    type: SEARCH_RESULTS_GET_ASSET_BY_EVENT,
    payload: events,
});

export const relatedEventSearchSuccess = result => ({
    type: SEARCH_RESULTS_GET_EVENTS_BY_HAPPENING_SUCCESS,
    payload: {
        events: result.data.data.events,
    },
});

export const resetRelatedPanelEventsEpicAction = () => ({
    type: SEARCH_RESULTS_GET_EVENTS_BY_HAPPENING_SUCCESS,
    payload: {
        events: [],
    },
});
