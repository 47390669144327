import React, { useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from '@mediabank/assets/icons/more-horizontal.svg';
import { IconButton, PanelTab, Popper } from '@mediabank/uikit-v5';
import { Box, ClickAwayListener, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    tab: {
        margin: theme.spacing(1, 0.5, 0),
        height: theme.spacing(2.5),
        '& .MuiButton-label': {
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    tabs: {
        display: 'flex',
    },
    moreButton: {
        marginTop: theme.spacing(0.7),
    },
    moreIcon: {
        width: theme.spacing(1.8),
        height: theme.spacing(1.8),
        fontSize: 'inherit',
    },
    popperItem: {
        cursor: 'pointer',
        padding: theme.spacing(1),
        borderRadius: 4,
    },
    active: {
        backgroundColor: theme.palette.text.secondary,
        color: theme.palette.background.paper,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        border: theme.palette.border?.light,
        borderRadius: theme.spacing(0.5),
        overflow: 'auto',
    },
}));

export const TabbedPanel = ({ showing, tabs, activeTab, onTabClick, popperPlacement }) => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [visibleTabs, setVisibleTabs] = useState(tabs.slice(0, showing));

    const { classes, cx } = useStyles();

    const handleTabClick = key => {
        onTabClick(key);
    };

    const handleShowMoreClick = () => {
        setOpen(!open);
    };

    const handlePopperItemClick = tab => {
        handleTabClick(tab.key);
    };

    const activeTabItemFromVisible = visibleTabs.find(visibleTab => visibleTab.key === activeTab);
    const activeTabItemFromTabs = tabs.find(visibleTab => visibleTab.key === activeTab);

    // If activeTab is not in the visible tabs it needs to be added
    if (!activeTabItemFromVisible) {
        if (activeTabItemFromTabs) {
            visibleTabs.shift();
            visibleTabs.push(activeTabItemFromTabs);
            setVisibleTabs([...visibleTabs]);
            // If the activeTab is not found in any tabs, use first tab
        } else {
            onTabClick(tabs[0].key);
        }
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.tabs}>
                {visibleTabs.map(tab => (
                    <PanelTab
                        key={tab.key}
                        className={classes.tab}
                        label={tab.label}
                        selected={tab.key === activeTab}
                        variant="level2"
                        onClick={() => handleTabClick(tab.key)}
                    />
                ))}
                {tabs.length > showing && (
                    <Box className={classes.moreButton}>
                        <IconButton ref={ref} variant="dark" onClick={handleShowMoreClick}>
                            <MoreIcon className={classes.moreIcon} fontSize="inherit" />
                        </IconButton>
                        {ref.current && (
                            <Popper anchorEl={ref.current} offset="150, 10" open={open} placement={popperPlacement}>
                                <ClickAwayListener
                                    mouseEvent="onMouseDown"
                                    touchEvent="onTouchStart"
                                    onClickAway={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <Paper>
                                        <Box p={2}>
                                            {tabs.map(tab => (
                                                <Typography
                                                    key={`popper-item=${tab.key}`}
                                                    className={
                                                        activeTab === tab.key
                                                            ? cx(classes.popperItem, classes.active)
                                                            : classes.popperItem
                                                    }
                                                    variant="body2"
                                                    onClick={() => handlePopperItemClick(tab)}
                                                >
                                                    {tab.label}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Paper>
                                </ClickAwayListener>
                            </Popper>
                        )}
                    </Box>
                )}
            </Box>
            <Box className={classes.content}>
                {tabs.map(tab => {
                    const TabContent = tab.component;

                    return tab.key === activeTab ? <TabContent key={`tab-tontent=${tab.key}`} {...tab.props} /> : null;
                })}
            </Box>
        </Box>
    );
};

TabbedPanel.propTypes = {
    tabs: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    onTabClick: PropTypes.func,
    showing: PropTypes.number,
    popperPlacement: PropTypes.string,
};

TabbedPanel.defaultProps = {
    onTabClick: () => null,
    showing: 3,
    popperPlacement: 'bottom',
};

export default TabbedPanel;
