import React from 'react';
import { Box, Typography } from '@development-nl/components-library';
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { OverlayBox } from '../Thumbnail';

// eslint-disable-next-line react/prop-types
const SelectScrubButton = ({ canPlay, onClick, ...props }) => (
    <ButtonBase component={Box} disableRipple={true} {...props}>
        <Box
            bgcolor="rgba(0, 0, 0, 0.74)"
            border={1}
            data-testid="videoTile-scrubbing-btn"
            px={1}
            py={0.5}
            sx={{ borderRadius: '4px', borderColor: 'rgba(255, 255, 255, 0.37)' }}
        >
            <Typography data-test="videoTile-scrubbing-btn" variant="caption" onClick={onClick}>
                Click to select{canPlay && '/scrub'}
            </Typography>
        </Box>
    </ButtonBase>
);

const usePlayerOverlayStyles = makeStyles()(theme => ({
    root: {
        backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0))',
        color: theme.palette.text.secondary,
        zIndex: '1',
    },
    controls: {
        display: 'flex',
    },
    topLeft: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px 0 ${theme.spacing(0.5)}px`,
    },
}));

const PlayerOverlay = ({ isPlaying, onScrub, canPlay }) => {
    const { classes } = usePlayerOverlayStyles();

    return (
        <OverlayBox className={classes.root} data-test="asdf" display="flex" flexDirection="column">
            {!isPlaying && (
                <SelectScrubButton
                    canPlay={canPlay}
                    data-test="videoTile-playerOverlay"
                    flex="1"
                    pb={2}
                    onClick={onScrub}
                />
            )}
        </OverlayBox>
    );
};

PlayerOverlay.propTypes = {
    canPlay: PropTypes.bool.isRequired,
    onScrub: PropTypes.func.isRequired,
    isPlaying: PropTypes.bool,
};

PlayerOverlay.defaultProps = {
    isPlaying: false,
};

export default PlayerOverlay;
