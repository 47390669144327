import { rgba2rgb } from '@mediabank/theme';
import { makeStyles } from 'tss-react/mui';

export const useOverlayPanelStyles = makeStyles()((theme, { active }) => ({
    root: {
        left: 0,
        right: 0,
        bottom: active ? 0 : '-50vh',
        transition: 'bottom 400ms ease-in-out',
        borderRadius: '8px 8px 0px 0px',
        backgroundColor: rgba2rgb(theme.custom.productBase[1], theme.custom.accentLight[1], 0.07),
        boxShadow: `0 -4px 8px 0 ${theme.custom.accentDark[0.25]}`,
        height: '50vh',
        overflow: 'hidden',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.custom.neutral400}`,
    },
    icon: {
        cursor: 'pointer',
        stroke: 'currentColor',
    },
    title: {
        fontWeight: '500',
    },
}));
