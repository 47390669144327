import { useContext } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import { sortByProperty } from '../DynamicFilters/utils';
import { CircleLoading } from '../Loading';
import DropDownSearch from './DropDownSearch';
import SearchableDropDownContext from './SearchableDropDownContext';

const DropDownContent = () => {
    const {
        setIsOpened,
        listData,
        handleOnChange,
        listLabel,
        buttonType,
        filterPhrase,
        setFilterPhrase,
        isLoading,
        classes: { useSearchableDropDownContentStyle },
    } = useContext(SearchableDropDownContext);

    const { classes: searchableDropDownContentStyle } = useSearchableDropDownContentStyle({ isLoading });

    const close = () => {
        setIsOpened(false);
        setFilterPhrase('');
    };

    const list = (listData || []).filter(
        item => (item?.visible ?? true) && item.value.toLowerCase().includes(filterPhrase.toLowerCase())
    );

    const checkedList = list.filter(item => item.checked);
    const notCheckedList = list.filter(item => !item.checked);

    return (
        <Box className={searchableDropDownContentStyle.wrapper}>
            <Box className={searchableDropDownContentStyle.loading} />
            <DropDownSearch />

            <Box className={searchableDropDownContentStyle.contentWrapper}>
                {listData && buttonType === 'dropdown' && (
                    <>
                        {checkedList?.length > 0 && (
                            <FormGroup className={searchableDropDownContentStyle.listGroup}>
                                {checkedList
                                    .filter(dataItem =>
                                        dataItem.value.toLowerCase().includes(filterPhrase.toLowerCase())
                                    )
                                    .map(dataItem => (
                                        <FormControlLabel
                                            key={dataItem.id}
                                            className={searchableDropDownContentStyle.listItem}
                                            control={
                                                <Checkbox
                                                    checked={dataItem.checked}
                                                    className={searchableDropDownContentStyle.listItem}
                                                    onChange={() => {
                                                        handleOnChange(dataItem.id, !dataItem.checked, close);
                                                    }}
                                                />
                                            }
                                            label={dataItem.value}
                                        />
                                    ))}
                            </FormGroup>
                        )}
                        {listLabel && (
                            <Typography className={searchableDropDownContentStyle.listTitleText}>
                                {listLabel}
                            </Typography>
                        )}
                        {notCheckedList?.length > 0 && (
                            <FormGroup className={searchableDropDownContentStyle.listGroup}>
                                {notCheckedList
                                    .filter(dataItem =>
                                        dataItem.value.toLowerCase().includes(filterPhrase.toLowerCase())
                                    )
                                    .map(dataItem => (
                                        <FormControlLabel
                                            key={dataItem.id}
                                            className={searchableDropDownContentStyle.listItem}
                                            control={
                                                <Checkbox
                                                    checked={dataItem.checked}
                                                    className={searchableDropDownContentStyle.listItem}
                                                    onChange={() =>
                                                        handleOnChange(dataItem.id, !dataItem.checked, close)
                                                    }
                                                />
                                            }
                                            label={dataItem.value}
                                        />
                                    ))}
                            </FormGroup>
                        )}
                    </>
                )}

                {listData && buttonType === 'select' && (
                    <FormGroup className={searchableDropDownContentStyle.listGroup}>
                        {sortByProperty(list, 'checked', true)
                            .filter(dataItem => dataItem.value.toLowerCase().includes(filterPhrase.toLowerCase()))
                            .map(dataItem => (
                                <FormControlLabel
                                    key={dataItem.id}
                                    control={
                                        <Checkbox
                                            checked={dataItem.checked}
                                            className={searchableDropDownContentStyle.listItem}
                                            onChange={() => handleOnChange(dataItem.id, !dataItem.checked, close)}
                                        />
                                    }
                                    label={dataItem.value}
                                />
                            ))}
                    </FormGroup>
                )}
            </Box>

            <Box className={searchableDropDownContentStyle.loading}>
                <CircleLoading />
            </Box>
        </Box>
    );
};

export default DropDownContent;
