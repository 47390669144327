import { assetsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedAsset: undefined,
    visibleAddToFolderModal: false,
    visibleAttachmentModal: false,
    visibleEditMetadataModal: false,
};

const contextMenuSlice = createSlice({
    name: 'contextMenu',
    initialState,
    reducers: {
        toggleAttachmentModal: state => {
            state.visibleAttachmentModal = !state.visibleAttachmentModal;
        },
        toggleEditMetadataModal: state => {
            state.visibleEditMetadataModal = !state.visibleEditMetadataModal;
        },
        toggleAddToFolderModal: state => {
            state.visibleAddToFolderModal = !state.visibleAddToFolderModal;
        },
        setSelectedAsset: (state, { payload: selectedAsset }) => {
            state.selectedAsset = selectedAsset;
        },
        clear: () => initialState,
    },
});

const getContextMenuSlice = state => state.contextMenu;

export const getAttachments = async assetId => {
    const response = await assetsService.getAttachments({ assetId });

    const attachments = response?.data?.data || [];

    return attachments;
};

export const createAttachment = async (assetId, attachment) => {
    const response = await assetsService.createAttachment({ assetId, attachment });

    const { status } = response;

    if (status === 201) {
        const createdAttachment = response?.data?.data || {};

        return createdAttachment;
    } else {
        throw Error(status);
    }
};

export const deleteAttachment = async assetId => await assetsService.deleteAsset({ assetId });

export default { ...contextMenuSlice, selectors: { getContextMenuSlice } };
