import { createSlice } from '@reduxjs/toolkit';

import { SEARCHTYPE } from '../../hooks/useCombinedSearchMode/useCombinedSearchMode';

const initialState = {
    id: null,
    keyword: '',
    combinedLimit: 40,
    orderBy: 'assetid',
    combinedOrderBy: 'assetid',
    sortOrder: 'desc', // desc, asc
    combinedSortOrder: 'desc',
    matchType: 'all', // all, any
    filters: [],
    resultCriteria: [],
    createdPeriod: {
        enabled: false,
        type: 'hours', // [hours, days, weeks, months]
        value: null,
    },
    createdFilter: {
        enabled: false,
        type: 'before', // ["within", "before", "after"]
        value: null, // format: yyyy-mm-dd hh:mm
    },
    smartSearchId: null,
    eventSortOrder: 'desc', // desc, asc
    searchType: SEARCHTYPE.assets,
    dynamicAssetFilters: [],
};

const searchProvider = createSlice({
    name: '@@searchProvider',
    initialState,
    reducers: {
        setQuery: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        resetQuery: state => {
            state.filters = initialState.filters;
            state.keyword = initialState.keyword;
            state.id = initialState.id;
            state.createdPeriod = initialState.createdPeriod;
            state.createdFilter = initialState.createdFilter;
            state.resultCriteria = [];
            state.smartSearchId = null;
            state.dynamicAssetFilters = initialState.dynamicAssetFilters;
            state.orderBy = initialState.orderBy;

            return state;
        },
    },
});

const selectors = {
    getQuery: state => state.searchProvider,
};

export default {
    ...searchProvider,
    selectors,
};
