import { clipListsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    requesting: false,
    data: [],
};

const clipListDetailsSlice = createSlice({
    name: 'clipListDetails',
    initialState,
    reducers: {
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        updateDetails: (state, action) => {
            const { payload } = action;
            state.data = payload.clipListDetails;
        },
    },
});

const { requestStart, requestFinished, updateDetails } = clipListDetailsSlice.actions;

const fetchClipListDetails = id => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await clipListsService.getClipListDetails(id);

        if (response?.status === 200) {
            dispatch(requestFinished());
            const clipListDetails = response?.data?.data || [];
            dispatch(updateDetails({ clipListDetails }));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const reorderClipListDetails = (id, newListItems) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await clipListsService.reorderClipListDetails(id, newListItems);

        if (response?.status === 200) {
            dispatch(requestFinished());
            dispatch(requestStart());
            try {
                const dataResponse = await clipListsService.getClipListDetails(id);
                if (dataResponse?.status === 200) {
                    dispatch(requestFinished());
                    const clipListDetails = dataResponse?.data?.data || [];
                    dispatch(updateDetails({ clipListDetails }));
                } else {
                    dispatch(requestFinished());
                    throw Error(dataResponse?.status);
                }
            } catch (error) {
                dispatch(requestFinished());
            }
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const deleteClip = ({ clipListId, clipId }) => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await clipListsService.deleteClip({ clipListId, clipId });

        if (response?.status === 204) {
            dispatch(requestFinished());
            dispatch(fetchClipListDetails(clipListId));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const isRequesting = state => state.clipListDetails.requesting;
const clipListDetails = state => state.clipListDetails.data;

export default {
    ...clipListDetailsSlice,
    deleteClip,
    fetchClipListDetails,
    reorderClipListDetails,
    selectors: { isRequesting, clipListDetails },
};
