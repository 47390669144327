import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { APP_VERSION } from '../../constants';

const useStyles = makeStyles()(theme => ({
    footer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '10px',
        color: theme.custom?.accentLight[0.4],
    },
    loginFooter: {
        padding: '10px',
        color: theme.custom?.accentDark[0.7],
        position: 'fixed',
        bottom: '0',
        width: '100%',
    },
}));

const AppInfo = () => {
    const { classes } = useStyles();

    // display app info at the footer of the login screen
    // EXAMPLE: "Version 1.1.0-497"
    const APP_INFO_TEXT = `Version ${APP_VERSION}`;

    return (
        <footer className={classes.loginFooter}>
            <span data-testid="appInfo-version">{APP_INFO_TEXT}</span>
        </footer>
    );
};

export { AppInfo };
