import { makeStyles } from 'tss-react/mui';

import { DATE_RANGE_FIELD_LABEL } from './constants';

export const useDynamicFiltersStyles = makeStyles()((theme, { separatorColor, hasSelectedValues }) => ({
    wrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.5rem',
        backgroundColor: theme.custom.dynamicFilters.background,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        zIndex: 20,
    },
    filterLabelText: {
        color: theme.custom.dynamicFilters.label,
    },
    clearWrapper: {
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'columns',
        justifyContent: 'flex-end',
        alignSelf: 'stretch',
        alignItems: 'flex-end',
    },
    clearText: {
        color: hasSelectedValues
            ? theme.custom.dynamicFilters.clearFiltersActiveColor || '#55D6EB'
            : theme.custom.dynamicFilters.clearFiltersColor || '#414445',
        cursor: hasSelectedValues ? 'pointer' : 'auto',
        fontSize: '1.2rem',
        fontWeight: 500,
        padding: `${theme.spacing(0.8)} ${theme.spacing(1)}`,
        '&::before': {
            content: '""',
            borderLeft: '1px solid transparent',
            marginRight: '2rem',
            borderColor: separatorColor || theme.custom.dynamicFilters.separatorColor || '#313536',
        },
    },
}));

export const useDynamicFilterListStyles = makeStyles()(() => ({
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1.25rem',
    },
}));

export const useDynamicFilterStyles = makeStyles()(theme => ({
    filterLabelText: {
        color: theme.custom.dynamicFilters.label,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
    },
}));

export const useDateRangePickerStyles = makeStyles()(
    (theme, { dateRangeFieldLabel, activeDateRangeInputBackgroundColor }) => ({
        filterLabelText: {
            color: theme.custom.dynamicFilters.label,
            fontSize: '1.2rem',
            lineHeight: '1.8rem',
        },
        dateRangeWrapper: {
            position: 'absolute',
            overflow: 'hidden',
            borderRadius: '0.8rem',
            zIndex: '50',
            background: theme.custom.dynamicFilters.pickerBackground,
        },
        dateRangeButtons: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        dateRangeButtonsWrapper: {
            width: 332,
            display: 'flex',
            gap: '1.5rem',
            justifyContent: 'center',
            paddingBottom: '2rem',
        },
        dateRangeButton: {
            width: 106,
        },
        dateRangePickerWrapper: {
            fontFamily: theme.typography,
            '.rdrStaticRangeLabel': {
                color: theme.custom.dynamicFilters.staticRangeLabelColor,
                fontSize: '1.2rem',
            },
            '.rdrDefinedRangesWrapper,.rdrStaticRange,.rdrCalendarWrapper,.rdrDateDisplayWrapper': {
                background: theme.custom.dynamicFilters.pickerBackground,
            },

            '.rdrDayNumber': {
                span: {
                    color: theme.custom.dynamicFilters.staticRangeLabelColor,
                },
            },
            'button.rdrStaticRange': {
                borderBottom: 0,
            },
            '.rdrDefinedRangesWrapper': {
                borderRight: 0,
                position: 'relative',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    borderRight: '1px solid #414445',
                    top: 12,
                    right: 0,
                    width: 1,
                    height: 308,
                },
            },
            '.rdrNextPrevButton': {
                width: 20,
                height: 20,
                background: theme.custom.dynamicFilters.nextPrevButtonBackground,
                '&:hover': {
                    background: theme.custom.dynamicFilters.nextPrevButtonBackground,
                },
            },
            '.rdrNextButton i': {
                borderColor: `transparent transparent transparent ${theme.custom.dynamicFilters.staticRangeLabelColor}`,
                marginLeft: 5,
            },
            '.rdrPprevButton i': {
                borderColor: `transparent ${theme.custom.dynamicFilters.staticRangeLabelColor} transparent transparent`,
            },
            '.rdrSelected': {
                borderRadius: 0,
            },
            '.rdrDayPassive .rdrDayNumber span': {
                color: theme.custom.dynamicFilters.passiveDayColor,
            },
            '.rdrDayPassive .rdrInRange ~ .rdrDayNumber': {
                backgroundColor: `${theme.custom.dynamicFilters.dateRangeDayBackground}`,
                span: {
                    color: theme.custom.dynamicFilters.staticRangeLabelColor,
                },
            },
            '.rdrInRange': {
                borderRadius: '0px!important',
            },
            '.rdrDayToday .rdrDayNumber span:after': {
                width: 2,
                background: theme.custom.dynamicFilters.currentDateColor,
            },
            '.rdrDayToday .rdrDayNumber span': {
                color: theme.custom.dynamicFilters.currentDateColor,
            },
            '.rdrMonthAndYearPickers select': {
                color: theme.custom.dynamicFilters.staticRangeLabelColor,
                background:
                    "url(\"data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='white' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>\") no-repeat",
                backgroundPosition: 'right 8px center',
            },
            '.rdrStaticRange:focus .rdrStaticRangeLabel, .rdrStaticRange:hover .rdrStaticRangeLabel': {
                background: theme.custom.dynamicFilters.pickerBackground,
                color: theme.custom.dynamicFilters.activeStaticRangeColor,
            },
            '.rdrInputRangeInput': {
                color: theme.custom.dynamicFilters.staticRangeLabelColor,
                borderColor: theme.custom.dynamicFilters.dateRangeInputBorderColor,
                background: theme.custom.dynamicFilters.dateRangeInputBackground,
            },
            '.rdrInputRangeInput:focus, .rdrInputRangeInput:hover': {
                color: theme.custom.dynamicFilters.staticRangeLabelColor,
                borderColor: theme.custom.dynamicFilters.dateRangeInputBorderColor,
            },
            '.rdrDateDisplayItem': {
                backgroundColor: theme.custom.dynamicFilters.pickerBackground,
                borderColor: theme.custom.dynamicFilters.dateRangeInputBorderColor,
            },
        },
        dateRangeInput: {
            cursor: 'pointer',
            display: 'flex',
            gap: '21px',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor:
                dateRangeFieldLabel === DATE_RANGE_FIELD_LABEL
                    ? theme.custom.dynamicFilters.pickerInputBackground
                    : activeDateRangeInputBackgroundColor || '#00404A',
            padding: `${theme.spacing(0.4)} ${theme.spacing(1)}`,
            borderRadius: theme.spacing(0.5),
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.custom.dynamicFilters.pickerInputBorderColor}`,
            '&:hover, &:focus': {
                borderColor: theme.custom.dynamicFilters.pickerInputActiveBorderColor,
            },
        },
        dateRangeInputText: {
            fontSize: '1.2rem',
            lineHeight: '1.8rem',
            color:
                dateRangeFieldLabel === DATE_RANGE_FIELD_LABEL
                    ? theme.custom.dynamicFilters.dateRangeInputTextColor || 'inherit'
                    : theme.custom.dynamicFilters.filtersActiveButtonLabel || '#E9E9E9',
        },
        arrowDown: {
            border: `solid ${theme.custom.dynamicFilters.staticRangeLabelColor}`,
            borderWidth: '0 1px 1px 0',
            display: 'inline-block',
            padding: '3px',
            transform: 'rotate(45deg)',
        },
        iconWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        dateRangeLabelWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        indicatorClearIcon: {
            cursor: 'pointer',
            fill: 'transparent',
            '& path': {
                stroke: theme.custom.dynamicFilters.label || '#A5A7A7',
            },
        },
    })
);
