import { rgba2rgb } from '../themeFunctions';

export default (theme, colors) => ({
    root: {
        '&.MuiOutlinedInput-root': {
            '& fieldset': {
                border: `1px solid ${colors.accentLight[0.2]}`,
            },
            '&:hover fieldset': {
                borderColor: colors.accentLight[0.4],
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.focused[0.5],
                borderWidth: 1,
            },
            '&:disabled fieldset': {
                borderColor: colors.accentLight[0.2],
            },
        },
        '& .MuiSelect-select': {
            padding: theme.spacing(0, 1),
            border: `1px solid ${colors.accentLight[0.2]}`,
            backgroundColor: rgba2rgb(colors.productBase[1], colors.accentDark[1], 0.1),
        },
    },
});
