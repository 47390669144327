import { groupService, tokenService, userService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

const initialState = {
    loading: false,
    error: null,
    authenticated: false,
    token: localStorage.getItem('authToken'),
    profile: {
        roles: [],
    },
    groups: {
        loading: false,
        error: null,
        data: null,
    },
    fullProfile: null,
};

// TODO: revisit this localStorage token storing solution
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchStart: state => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            state.token = action.payload.token;
            state.profile = action.payload.decodedToken.user;
            state.authenticated = true;
            state.loading = false;
            state.error = null;
        },
        fetchError: (state, action) => {
            state.token = null;
            state.error = action.payload;
            state.loading = false;
        },
        fetchGroupStart: state => {
            state.groups.loading = true;
        },
        fetchGroupSuccess: (state, action) => {
            state.groups.data = action.payload.data;
            state.groups.loading = false;
            state.groups.error = null;
        },
        fetchGroupError: (state, action) => {
            state.groups.loading = false;
            state.groups.error = action.payload;
        },
        fetchFullProfileSuccess: (state, action) => {
            state.fullProfile = action.payload.data;
            state.loading = false;
        },
        clear: () => {
            removeToken();

            return initialState;
        },
    },
});

const {
    fetchStart,
    fetchSuccess,
    fetchError,
    fetchGroupStart,
    fetchGroupSuccess,
    fetchGroupError,
    fetchFullProfileSuccess,
} = userSlice.actions;

const selectors = {
    getUser: state => state.user,
    getUserRoles: state => state.user?.profile.roles,
    getUserGroups: state => state.user?.groups,
    getUserCompany: state => state.user?.profile.company_id,
    getUserFullProfile: state => state.user?.fullProfile,
    getToken: state => state.user.token,
    isAuthenticated: state => !!state.user?.authenticated,
    isError: state => state.error,
};

export default { ...userSlice, selectors };

export const fetchToken = ({ email, password, onSuccess, onFail }) => async dispatch => {
    try {
        dispatch(fetchStart());
        const {
            data: { token },
            status,
        } = await tokenService.authWithCred({ email, password });
        if (token) {
            dispatch(triggerSuccessAndStartAutoRenewToken({ token, onSuccess }));
        } else {
            throw Error(status);
        }
    } catch (err) {
        dispatch(fetchError(err.message));
        onFail && onFail();
    }
};

export const validateToken = ({ token, onSuccess }) => async dispatch => {
    dispatch(fetchStart());
    try {
        const response = await tokenService.validate({ token });

        if (!response) {
            dispatch(refreshToken({ token }));
        } else if (response.status === 200) {
            dispatch(triggerSuccessAndStartAutoRenewToken({ token, onSuccess }));
        } else {
            dispatch(fetchError('Error while validating token.'));
        }
    } catch (err) {
        dispatch(fetchError(err.message));
    }
};

const removeToken = () => {
    localStorage.removeItem('authToken');
};

const refreshToken = ({ token }) => async dispatch => {
    try {
        const response = await tokenService.refresh({ token });
        if (!response) {
            removeToken();
            dispatch(fetchError('EXPIRED_TOKEN'));
        } else {
            const {
                data: { token },
            } = response;
            dispatch(triggerSuccessAndStartAutoRenewToken({ token }));
        }
    } catch (err) {
        dispatch(fetchError(err.message));
    }
};

const triggerSuccessAndStartAutoRenewToken = ({ token, onSuccess }) => async dispatch => {
    localStorage.setItem('authToken', token);
    const decodedToken = jwtDecode(token);
    dispatch(fetchSuccess({ token, decodedToken }));
    onSuccess && onSuccess({ token, decodedToken });
    const expiringIn = decodedToken.exp * 1000 - Date.now() - 60 * 1000;
    setTimeout(() => dispatch(refreshToken({ token })), expiringIn);
};

export const fetchGroups = ({ companyId, onSuccess, onFail }) => async (dispatch, getState) => {
    try {
        let cId = companyId;
        if (!cId) {
            const state = getState();
            cId = state.user.profile.company_id;
        }
        dispatch(fetchGroupStart());
        const { data, status } = await groupService.getAll({ companyId: cId });
        if (data) {
            dispatch(fetchGroupSuccess(data));
            onSuccess && onSuccess(data);
        } else {
            throw Error(status);
        }
    } catch (err) {
        dispatch(fetchGroupError(err.message));
        onFail && onFail();
    }
};

export const fetchFullProfile = ({ companyId, userId, onSuccess, onFail }) => async (dispatch, getState) => {
    try {
        let cId = companyId;
        let uId = userId;
        const state = getState();

        if (!cId) {
            cId = state.user.profile.company_id;
        }

        if (!uId) {
            uId = state.user.profile.id;
        }

        dispatch(fetchStart());
        const { data, status } = await userService.getOne({ companyId: cId, userId: uId });

        if (data) {
            dispatch(fetchFullProfileSuccess(data));
            onSuccess && onSuccess(data);
        } else {
            throw Error(status);
        }
    } catch (err) {
        onFail && onFail();
    }
};
