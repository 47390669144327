import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { GlobalStyles } from '@mediabank/theme-v5';
import { NotificationsProvider, ToastProvider } from '@mediabank/uikit-v5';
import { sentryInit } from '@mediabank/utils';
import { CssBaseline, ThemeProvider } from '@mui/material';

import ZenDesk from '../../components/Zendesk/ZenDesk';
import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_IS_ACTIVE, SENTRY_RELEASE } from '../../constants';
import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import AuthCookieProvider from '../../providers/AuthCookieProvider';
import Providers from '../../providers/index';
import AppRoutes from '../../routes/AppRoutes';
import store from '../../store';

if (SENTRY_IS_ACTIVE) {
    // Sentry initialization
    sentryInit({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: SENTRY_RELEASE,
    });
}

const muiCache = createCache({
    key: 'mui',
    prepend: true,
});

const NextAppExport = () => {
    const [appTheme] = useThemeSwitcher();

    return (
        <Provider store={store}>
            <ZenDesk />
            <CacheProvider value={muiCache}>
                <ThemeProvider theme={appTheme}>
                    <GlobalStyles />
                    <CssBaseline />
                    <AuthCookieProvider>
                        <BrowserRouter basename="apps/medialibrary">
                            <NotificationsProvider>
                                <ToastProvider>
                                    <Providers>
                                        <AppRoutes />
                                    </Providers>
                                </ToastProvider>
                            </NotificationsProvider>
                        </BrowserRouter>
                    </AuthCookieProvider>
                </ThemeProvider>
            </CacheProvider>
        </Provider>
    );
};

export default NextAppExport;
