import React from 'react';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        '&:hover, &:hover&$selected': {
            background: theme.custom.accentLight[0.1],
        },
        fontSize: '1.2rem',
        lineHeight: '1.4rem',
        color: theme.custom.accentLight[0.8],
        padding: theme.spacing(0.625, 1.25),
    },
    selected: {
        '&.Mui-selected': {
            background: theme.custom.accentLight[0.2],
            color: theme.palette.text.primary,
            '&:hover': {
                background: theme.custom.accentLight[0.1],
            },
        },
    },
}));

const SelectOption = ({ value, isSelected, innerProps, innerRef, children }) => {
    const { classes } = useStyles();

    return (
        <MenuItem
            ref={innerRef}
            classes={classes}
            component="div"
            data-cy={`advancedSearch-options-${value}`}
            dense={true}
            selected={isSelected}
            {...innerProps}
        >
            {children}
        </MenuItem>
    );
};

SelectOption.defaultProps = {
    innerRef: () => {},
    value: '',
};

SelectOption.propTypes = {
    children: PropTypes.node.isRequired,
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        onMouseMove: PropTypes.func.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired,
    }).isRequired,
    innerRef: PropTypes.func.isRequired,
    isFocused: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SelectOption;
