import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker as MuiMobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker as MuiMobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { breadCrumb, showPickerIcon }) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& input': {
                border: breadCrumb ? 'none' : '1px solid',
                borderColor: theme.custom.accentLight[0.2],
                height: theme.spacing(3),
                borderRadius: '6px',
                padding: '0px 15px',
                top: 0,
                textAlign: 'center',
            },
            '& fieldset': {
                border: 'none!important',
            },
            paddingRight: '0px',
        },
        '& .MuiButtonBase-root': {
            color: theme.palette.text.primary,
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd': {
            marginLeft: '0px',
            paddingLeft: '0px',
            paddingRight: '0px',
            minWidth: '26px',
        },
        '& .MuiInputAdornment-positionEnd': {
            minWidth: '20px',
            display: showPickerIcon ? 'inherit' : 'none',
        },
        '& .MuiInputBase-root': {
            minWidth: '60px',
        },
        '& .MuiInputBase-input': {
            padding: breadCrumb ? theme.spacing(0.5, 0.25) : theme.spacing(0.5, 2),
        },
    },
}));

const Pickers = {
    Date: MuiDatePicker,
    DateTime: MuiDateTimePicker,
    MobileDate: MuiMobileDatePicker,
    MobileDateTime: MuiMobileDateTimePicker,
};

const DatePicker = ({ views, type, breadCrumb, onChange, 'data-cy': dataCy, showPickerIcon, ...forwardProps }) => {
    const { classes } = useStyles({ breadCrumb, showPickerIcon });
    const [popupOpen, setPopupOpen] = useState(false);

    const PickerComponent = Pickers[type];

    return (
        <div data-cy={dataCy}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {type === 'MobileDate' || type === 'MobileDateTime' ? (
                    <PickerComponent
                        ampm={false}
                        className={classes.root}
                        closeOnSelect={true}
                        componentsProps={{
                            actionBar: {
                                actions: [],
                            },
                        }}
                        data-cy="datePickerElement"
                        data-test="datePickerElement"
                        minutesStep={5}
                        renderInput={props => {
                            if (!forwardProps?.value) {
                                onChange(new Date(props.inputProps?.value));
                            }

                            return <TextField {...props} />;
                        }}
                        views={views}
                        onChange={onChange}
                        {...forwardProps}
                    />
                ) : (
                    <PickerComponent
                        ampm={false}
                        className={classes.root}
                        componentsProps={{
                            actionBar: {
                                actions: [],
                            },
                        }}
                        data-cy="datePickerElement"
                        data-test="datePickerElement"
                        minutesStep={5}
                        open={popupOpen}
                        renderInput={props => (
                            <TextField
                                {...props}
                                onClick={() => setPopupOpen(true)}
                                onKeyDown={e => e.preventDefault()}
                            />
                        )}
                        views={views}
                        onChange={onChange}
                        onClose={() => setPopupOpen(false)}
                        {...forwardProps}
                    />
                )}
            </LocalizationProvider>
        </div>
    );
};

DatePicker.propTypes = {
    inputProps: PropTypes.object,
    breadCrumb: PropTypes.bool,
    views: PropTypes.arrayOf(PropTypes.oneOf(['year', 'month', 'day', 'minutes', 'hours'])),
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    format: PropTypes.string,
    'data-cy': PropTypes.string,
    showPickerIcon: PropTypes.bool,
    type: PropTypes.oneOf(['Date', 'DateTime', 'MobileDate', 'MobileDateTime']),
};

DatePicker.defaultProps = {
    inputProps: undefined,
    breadCrumb: false,
    views: ['date'],
    value: undefined,
    onChange: undefined,
    format: 'yyyy-MM-dd',
    'data-cy': 'datePickerElement',
    showPickerIcon: true,
    type: 'Date',
};

export default DatePicker;
