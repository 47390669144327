import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const downloadClipFromLive = async ({ assetId, attributes }) => {
    const response = await api.post(`/assets/${assetId}/live/downloads`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'live/downloads',
                attributes,
            },
        },
    });

    return response;
};

const getDownloadClipFromLive = async ({ assetId }) => {
    const response = await api.get(`/assets/${assetId}/live/downloads`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const createClipFromLive = async ({ assetId, attributes }) => {
    const response = await api.post(`/assets/${assetId}/live/clips`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'live/clips',
                attributes,
            },
        },
    });

    return response;
};

export default {
    downloadClipFromLive,
    getDownloadClipFromLive,
    createClipFromLive,
};
