export default (theme, colors) => ({
    root: {
        marginLeft: 'initial',
    },
    label: {
        color: colors.accentLight[0.75],
        fontSize: '1.2rem',
        '&.Mui-disabled': {
            color: colors.accentLight[0.25],
        },
    },
});
