export const SEARCH_RESULTS_LOAD_MORE = '@@searchResult/loadMore';
export const SEARCH_RESULTS_UPDATE_SORT_ORDER = '@@searchResult/updateSortOrder';
export const SEARCH_RESULTS_UPDATE_ASSET_SORTING_PROPS = '@@searchResult/updateAssetSortingProps';
export const SEARCH_RESULTS_UPDATE_COMBINED_ASSET_SORTING_PROPS = '@@searchResult/updateCombinedAssetSortingProps';
export const SEARCH_RESULTS_UPDATE_PAGE = '@@searchResult/updatePage';
export const SEARCH_RESULTS_UPDATE_FILTERS = '@@searchResult/updateFilters';

export const displayTypes = {
    thumbnail: 'tile',
    row: 'list',
};

export const sortingOptions = [
    { value: 'desc', label: 'Most Recent' },
    { value: 'asc', label: 'Least Recent' },
];

export const MOCK_SEARCH_RESULT_ASSETS = Array(10)
    .fill({})
    .map((_, index) => ({
        imgSrc: 'https://static-eu1.mediabank.me/resources/videos/uploading.png',
        attributes: {
            assetmeta: {},
        },
        metaData: {
            title: 'RANDOM TEST TITLE: 1375932410',
            eventType: 'RANDOM TEST SUB-TITLE: 1645552828',
            eventInfo: '',
            date: '30 Mar 2020',
            extra: [],
        },
        isLive: false,
        id: index,
        duration: null,
        ProgressiveURL: '/api/v1/assets/11497/media/proxy_play',
    }));

export const mockApiCall = async () =>
    await new Promise(resolve => {
        setTimeout(() => resolve(MOCK_SEARCH_RESULT_ASSETS), 500);
    });
