import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { useContext, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { ReactComponent as ArrowDownIcon } from '@mediabank/assets/icons/chevron-down.svg';
import { ReactComponent as ClearFilterIcon } from '@mediabank/assets/icons/clear-search-text-disabled.svg';
import { Box, Button, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import DynamicFiltersContext, { defaultDateRange } from './DynamicFiltersContext';
import { filterType, sendSignalAboutChanges } from './utils';

const DynamicDateRangeFilter = ({ filter }) => {
    const { label } = filter;

    const {
        dateRange,
        setDateRange,
        metaKeys,
        onChange,
        classes: { useDynamicFilterStyles, useDateRangePickerStyles },
        dateRangeFieldLabel,
        resetDateRange,
        onAnyFieldActive,
    } = useContext(DynamicFiltersContext);

    const { classes } = useDateRangePickerStyles({ dateRangeFieldLabel });
    const { classes: dynamicFilterClasses } = useDynamicFilterStyles();
    const [showPicker, setShowPicker] = useState(false);
    const [tempDateRange, setTempDateRange] = useState(defaultDateRange);

    const isFilterSet = dateRange[0]?.startDate && dateRange[0]?.endDate;
    const isDateRangeSet = tempDateRange[0]?.startDate && tempDateRange[0]?.endDate;

    useEffect(() => {
        if (!isDateRangeSet && isFilterSet) {
            setTempDateRange(dateRange);
        }
    }, [isFilterSet, dateRange, isDateRangeSet]);

    const onApply = () => {
        if (!isDateRangeSet) return;

        sendSignalAboutChanges(metaKeys, tempDateRange, onChange);
        setDateRange(tempDateRange);
        setPickerVisibility(false);
    };

    const onClearDateRange = () => {
        resetDateRange();
        setPickerVisibility(false);
        setTempDateRange(defaultDateRange);
        sendSignalAboutChanges(metaKeys, defaultDateRange, onChange);
    };

    const setPickerVisibility = isOpen => {
        setShowPicker(isOpen);
        onAnyFieldActive(isOpen);

        if (!isOpen && !isFilterSet) setTempDateRange(defaultDateRange);
    };

    return (
        <ClickAwayListener onClickAway={() => setPickerVisibility(false)}>
            <Box>
                <Box className={classes.dateRangeLabelWrapper}>
                    <Typography className={dynamicFilterClasses.filterLabelText}>{label}</Typography>
                    {isFilterSet && (
                        <ClearFilterIcon className={classes.indicatorClearIcon} onClick={onClearDateRange} />
                    )}
                </Box>
                <Box sx={{ position: 'relative' }}>
                    <Box className={classes.dateRangeInput} onClick={() => setPickerVisibility(true)}>
                        <Box>
                            <Typography className={classes.dateRangeInputText}>{dateRangeFieldLabel}</Typography>
                        </Box>
                        <Box className={classes.iconWrapper}>
                            <ArrowDownIcon />
                        </Box>
                    </Box>
                    {showPicker && (
                        <Box className={classes.dateRangeWrapper}>
                            <DateRangePicker
                                className={classes.dateRangePickerWrapper}
                                direction="vertical"
                                months={1}
                                moveRangeOnFirstSelection={false}
                                rangeColors={['#00404A']}
                                ranges={tempDateRange}
                                showSelectionPreview={true}
                                onChange={item => setTempDateRange([item.selection])}
                            />
                            <Box className={classes.dateRangeButtons}>
                                <Box className={classes.dateRangeButtonsWrapper}>
                                    <Button
                                        className={classes.dateRangeButton}
                                        color="secondary"
                                        type="button"
                                        variant="contained"
                                        onClick={() => setPickerVisibility(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={classes.dateRangeButton}
                                        color="primary"
                                        disabled={!isDateRangeSet}
                                        type="button"
                                        variant="contained"
                                        onClick={onApply}
                                    >
                                        Apply
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

DynamicDateRangeFilter.propTypes = {
    filter: filterType.isRequired,
};

export default DynamicDateRangeFilter;
