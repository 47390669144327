import { mediabankApi as api, mediabankHeaders } from '../../mediabank';
import { createDynamicAggregateParams, createDynamicFilterParams } from './utils';

const getEvents = async ({
    eventSortOrder = null,
    eventResultPage: page,
    eventResultLimit: limit,
    combinedLimit = null,
    searchType,
    keyword,
    filters = [],
}) => {
    const isCombinedSearch = searchType === 'all';
    const dynamicFilters = [...filters];

    if (!!keyword) {
        dynamicFilters.push({
            id: 'freetext',
            type: 'freetext',
            value: keyword,
        });
    }

    const filterParams = createDynamicFilterParams(dynamicFilters);

    const response = await api.get('/tags/events', {
        headers: mediabankHeaders(),
        urlParams: {
            page,
            limit: isCombinedSearch ? combinedLimit : limit,
            sortOrder: eventSortOrder,
            ...filterParams,
        },
    });

    return response;
};

const getEventsByHappening = async ({ happeningId }) => {
    const response = await api.get(
        `/tags/events?filters[0][key]=match_id&filters[0][verb]=equal&filters[0][value]=${happeningId}`,
        {
            headers: mediabankHeaders(),
        }
    );

    return response;
};

const getAllMetaValuesById = async (option = {}) => {
    const { metaKeyId, filters = [] } = option;
    const filterParams = createDynamicFilterParams(filters);

    const response = await api.get(`/tags/events`, {
        headers: mediabankHeaders(),
        urlParams: {
            limit: 0,
            'aggregate[0]': metaKeyId,
            ...filterParams,
        },
    });
    return response;
};

const getMetaAggregates = async (option = {}) => {
    const { metaKeys = [], filters = [] } = option;

    const aggregateParams = createDynamicAggregateParams(metaKeys);
    const filterParams = createDynamicFilterParams(filters, 'smartsearchfilters', 'assets');

    const response = await api.get(`/tags/events`, {
        headers: mediabankHeaders(),
        urlParams: {
            limit: 0,
            ...aggregateParams,
            ...filterParams,
        },
    });
    return response;
};

export default {
    getEvents,
    getEventsByHappening,
    getAllMetaValuesById,
    getMetaAggregates,
};
