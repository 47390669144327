import React from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

import ShowMoreArea from './ShowMoreArea';

const DroppableWrapper = ({ onShowing, ...props }) => {
    const [, drop] = useDrop({
        accept: 'VideoTile',
        collect: monitor => {
            const isOver = monitor.isOver({ shallow: true });

            isOver && onShowing && onShowing(true);
        },
    });

    return <ShowMoreArea ref={drop} onShowing={onShowing} {...props} />;
};

DroppableWrapper.propTypes = {
    onShowing: PropTypes.func,
};

DroppableWrapper.defaultProps = {
    onShowing: undefined,
};

export default DroppableWrapper;
