import { useSelector } from 'react-redux';

import { selectIsQuickFilter, selectIsSavedSearch } from '../../components/AdvancedSearch/selectors';
import { MODE_PREVIEW, MODE_PROPERTIES, MODE_RESULTS, MODE_VIEWER } from '../../components/Header/constants';
import configSlice from '../../store/config';
import uiSlice from '../../store/ui';

export const SEARCHTYPE = {
    all: 'all',
    assets: 'assets',
    events: 'events',
};

const {
    selectors: { getActiveSearchResultTab, getCurrentSearchMode },
} = uiSlice;

const {
    selectors: { getConfig },
} = configSlice;

const useCombinedSearchMode = () => {
    const { EventSearchFilters } = useSelector(getConfig) || {};

    const activeSearchResultTab = useSelector(getActiveSearchResultTab);
    const currentSearchMode = useSelector(getCurrentSearchMode);
    const isQuickFilter = useSelector(selectIsQuickFilter);
    const isSavedSearch = useSelector(selectIsSavedSearch);

    const isResultsMode =
        currentSearchMode === MODE_RESULTS ||
        currentSearchMode === MODE_PROPERTIES ||
        currentSearchMode === MODE_PREVIEW ||
        currentSearchMode === MODE_VIEWER ||
        !currentSearchMode;

    const isEventSearchFiltersActive = Boolean(EventSearchFilters);
    const isCombinedSearchMode = isEventSearchFiltersActive && isResultsMode && !isQuickFilter && !isSavedSearch;
    const isActiveTabAll = activeSearchResultTab === SEARCHTYPE.all;
    const isActiveTabAssets = activeSearchResultTab === SEARCHTYPE.assets;
    const isActiveTabEvents = activeSearchResultTab === SEARCHTYPE.events;
    const showDynamicFilter = isCombinedSearchMode && !isActiveTabAll;

    return {
        isResultsMode,
        isEventSearchFiltersActive,
        isCombinedSearchMode,
        isActiveTabAll,
        isActiveTabAssets,
        isActiveTabEvents,
        showDynamicFilter,
    };
};

export default useCombinedSearchMode;
