import { mediabankApi as api, mediabankHeaders } from '..';

const listAll = async () =>
    await api.get(`/destinations`, {
        headers: mediabankHeaders(),
    });

const usage = async id =>
    await api.get(`/destinations/${id}/usage`, {
        headers: mediabankHeaders(),
    });

export const execute = async ({ id, attributes }) => {
    const response = await api.post(`/destinations/${id}/execute`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'mboxJobRequests',
                attributes,
            },
        },
    });
    return response;
};

export default {
    execute,
    listAll,
    usage,
};
