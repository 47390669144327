import { useContext } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import SearchableDropDown from '../SearchableDropDown/SearchableDropDown';
import DynamicFiltersContext from './DynamicFiltersContext';
import { sendSignalAboutChanges } from './utils';

const DynamicFilterList = ({ onDropdownOpen }) => {
    const {
        dateRange,
        setMetaKeys,
        filtersChangedByUser,
        onChange,
        isLoading,
        selectedMetaKeys,
        classes: { useDynamicFilterListStyles },
    } = useContext(DynamicFiltersContext);
    const { classes } = useDynamicFilterListStyles();

    if (!selectedMetaKeys?.length) return null;

    const handleOnChange = metaKey => (id, newStatus) => {
        setMetaKeys(oldMetaKeys => {
            const newData = oldMetaKeys.map(oldMetaKeyItem => {
                if (oldMetaKeyItem.id === metaKey) {
                    return {
                        ...oldMetaKeyItem,
                        options: oldMetaKeyItem.options.map(optionsItem => {
                            if (optionsItem.id === id) {
                                return {
                                    ...optionsItem,
                                    checked: newStatus,
                                };
                            }

                            return optionsItem;
                        }),
                    };
                }

                return oldMetaKeyItem;
            });

            sendSignalAboutChanges(newData, dateRange, onChange);

            return newData;
        });
    };

    const handleClearSelection = metaKey => {
        setMetaKeys(oldMetaKeys => {
            const newData = oldMetaKeys.map(oldMetaKeyItem => {
                if (oldMetaKeyItem.id === metaKey) {
                    return {
                        ...oldMetaKeyItem,
                        options: oldMetaKeyItem.options.map(optionsItem => ({ ...optionsItem, checked: false })),
                    };
                }

                return oldMetaKeyItem;
            });

            sendSignalAboutChanges(newData, dateRange, onChange);

            return newData;
        });
    };

    return (
        <Box className={classes.wrapper}>
            {selectedMetaKeys.map((metaKey, index) => {
                const isLastElement = selectedMetaKeys.length - 1 === index;

                return (
                    <SearchableDropDown
                        key={index}
                        buttonLabel={'Select'}
                        buttonType="select"
                        handleOnChange={handleOnChange(metaKey.id)}
                        isLoading={isLoading}
                        label={metaKey.value}
                        listData={metaKey.options || []}
                        opened={isLastElement && filtersChangedByUser}
                        selectedItemsNumber={metaKey?.options?.filter(optionItem => optionItem.checked)?.length || 0}
                        showSelectedNumber={true}
                        onClearSelection={() => handleClearSelection(metaKey.id)}
                        onOpen={() => onDropdownOpen && onDropdownOpen(metaKey.id, true)}
                    />
                );
            })}
        </Box>
    );
};

DynamicFilterList.propTypes = {
    onDropdownOpen: PropTypes.func,
};

DynamicFilterList.defaultProps = {
    onDropdownOpen: null,
};

export default DynamicFilterList;
