import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const usePanelStyles = makeStyles()((theme, { isHidden, toggleButtonPosition, panelBorder }) => ({
    toggleButton: {
        display: 'flex',
        position: 'absolute',
        color: theme.palette.text.secondary,
        textTransform: 'uppercase',
        zIndex: isHidden ? 2 : 1,
        padding: 2,
        backgroundColor: isHidden ? theme.palette.background.root : 'transparent',
        borderRadius: toggleButtonPosition === 'left' ? '0px 4px 4px 0px' : '4px 0px 0px 4px',
        marginTop: 7,
        cursor: 'pointer',
        alignItems: 'center',
        fontWeight: 500,
        '& svg': {
            color: theme.palette.text.primary,
        },
        left:
            (isHidden && toggleButtonPosition === 'right') || (!isHidden && toggleButtonPosition === 'left') ? 0 : null,
        right:
            (!isHidden && toggleButtonPosition === 'right') || (isHidden && toggleButtonPosition === 'left') ? 0 : null,
    },
    root: {
        position: 'relative',
        '&:not(:last-child)': {
            borderRight: !panelBorder ? `3px solid ${theme.palette.background.root}` : panelBorder,
        },
    },
    content: {
        display: isHidden ? 'none' : 'block',
        width: '100%',
    },
    expand: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            display: 'none',
            overflow: 'hidden',
        },
        '&:hover div': {
            display: 'inline',
            paddingRight: '5px',
        },
    },
}));

const CollapsiblePanel = ({ children, width, onClick, name, toggleButtonPosition, panelBorder }) => {
    const isHidden = !width;
    const { classes } = usePanelStyles({ isHidden, toggleButtonPosition, panelBorder });
    const ChevronCollapse = toggleButtonPosition === 'left' ? ChevronRight : ChevronLeft;
    const ChevronExpand = toggleButtonPosition === 'left' ? ChevronLeft : ChevronRight;

    return (
        <Box className={classes.root} style={{ width: `${width}%` }}>
            {name && (
                <Box className={classes.toggleButton} data-testid="toggle-button" onClick={onClick}>
                    {width ? (
                        <Tooltip title={<Typography>{`Hide ${name}`}</Typography>}>
                            <ChevronCollapse />
                        </Tooltip>
                    ) : (
                        <Box className={classes.expand}>
                            <ChevronExpand /> <Box>{name}</Box>
                        </Box>
                    )}
                </Box>
            )}
            <Box className={classes.content}>{children}</Box>
        </Box>
    );
};

CollapsiblePanel.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    name: PropTypes.string,
    toggleButtonPosition: PropTypes.oneOf(['left', 'right']),
    panelBorder: PropTypes.string,
};

CollapsiblePanel.defaultProps = {
    onClick: () => null,
    name: '',
    toggleButtonPosition: 'right',
    panelBorder: '',
    width: 0,
};

export default CollapsiblePanel;
