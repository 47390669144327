import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.custom.accentLight[0.6],
        fontWeight: 400,
        fontSize: '1.2rem',
        border: `1px solid ${theme.custom.accentLight[0.1]}`,
    },
    tooltipPlacementBottom: {
        marginTop: theme.spacing(0.5),
    },
}));

const Tooltip = ({ children, ...forwardProps }) => {
    const { classes } = useStyles();

    return (
        <MuiTooltip classes={classes} {...forwardProps}>
            {children}
        </MuiTooltip>
    );
};

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Tooltip;
