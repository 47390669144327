import truncateMiddle from 'truncate-middle';

import { preferredMetaKeys } from './constants';

export const truncateValue = (value, maxLength = 15) => {
    if (!value || value.length < maxLength) {
        return value;
    }
    const separator = value.includes(' ') ? ' ' : value.includes('-') ? '-' : null;
    if (!separator) {
        return value;
    }
    const frontPos = separator && value.indexOf(separator);

    return frontPos && frontPos > 0 && truncateMiddle(value, frontPos, maxLength - (frontPos + 4), ' ...');
};

export const makeFilterFromKey = (key, id) => ({ id, key, verb: 'equal', value: null });

export const nextPreferredKey = (assetMetaKeys, filters) =>
    preferredMetaKeys
        .filter(key => (assetMetaKeys.data || []).map(f => f.key).includes(key))
        .find(key => !filters.map(f => f.key).includes(key)) || null;

export const isDateMode = verb => ['beforedate', 'afterdate', 'withindates'].includes(verb);

export const isMulti = verb => ['some', 'notsome'].includes(verb);

export const getPeriodType = () => 'hours';

export const isPeriodType = type => type === getPeriodType();

export const isGroupMode = mode => mode === 'group';

export const isAdvancedSearchChanged = (smartSearchResult, queryData) =>
    smartSearchResult &&
    parseInt(smartSearchResult.id) === queryData.id &&
    JSON.stringify(smartSearchResult) !== JSON.stringify(queryData);
