import React from 'react';
import { Box } from '@development-nl/components-library';
import closedMBox from '@mediabank/assets/icons/mBox-closed.png';
import openedMBox from '@mediabank/assets/icons/mBox-opened.png';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import mBox from '../../../store/mBox';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles()((theme, { hoverImageUrl, imageUrl, variant }) => ({
    closedImageContainer: {
        backgroundImage: variant === 'opened' ? `url(${openedMBox})` : `url(${closedMBox})`,
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(-1),
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'contain',
    },
    closedFrontImageContainer: {
        height: theme.spacing(10),
        marginRight: imageUrl && hoverImageUrl ? theme.spacing(-12) : 0,
    },
    closedBackImageContainer: {
        height: theme.spacing(10),
    },
}));

const MBoxIconsContainer = ({ hoverImageUrl, imageUrl, variant }) => {
    const { classes } = useStyles({ hoverImageUrl, imageUrl, variant });

    return (
        <Box className={classes.closedImageContainer} data-testid="cardFront-image">
            {!hoverImageUrl && !imageUrl ? (
                <img alt={mBox.hoverImageName} className={classes.closedFrontImageContainer} src={closedMBox} />
            ) : (
                <>
                    <img
                        alt={mBox.hoverImageName}
                        className={classes.closedFrontImageContainer}
                        data-cy="closedFrontImageContainer"
                        src={hoverImageUrl}
                    />
                    <img
                        alt={mBox.shortName}
                        className={classes.closedBackImageContainer}
                        data-cy="closedBackImageContainer"
                        src={imageUrl}
                    />
                </>
            )}
        </Box>
    );
};

MBoxIconsContainer.propTypes = {
    hoverImageUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    variant: PropTypes.oneOf(['closed', 'opened']).isRequired,
};

MBoxIconsContainer.defaultProps = {
    hoverImageUrl: undefined,
    imageUrl: undefined,
};

export { MBoxIconsContainer };
