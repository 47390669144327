import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    useSearchableDropDownButtonStyle,
    useSearchableDropDownContentStyle,
    useSearchableDropDownStyle,
} from './styles';

export const SearchableDropDownContext = createContext(null);

const classMaker = (makeStyleFN, customTheme) => customProps =>
    makeStyleFN({ ...customTheme, ...(customProps ? customProps : {}) });

export const SearchableDropDownContextProvider = ({
    children,
    customTheme,
    hideIcon,
    buttonLabel,
    iconElement,
    opened,
    listData,
    listLabel,
    handleOnChange,
    label,
    buttonType,
    onClearSelection,
    selectedItemsNumber,
    showSelectedNumber,
    isLoading,
    onOpen,
}) => {
    const { classes: searchableDropDownStyle } = useSearchableDropDownStyle({ ...customTheme });

    const [isOpened, setIsOpened] = useState(opened);
    const [filterPhrase, setFilterPhrase] = useState('');

    useEffect(() => {
        setIsOpened(opened);
    }, [opened]);

    return (
        <SearchableDropDownContext.Provider
            value={{
                customTheme,
                iconElement,
                buttonType,
                listData,
                hideIcon,
                buttonLabel,
                listLabel,
                handleOnChange,
                isOpened,
                setIsOpened,
                filterPhrase,
                setFilterPhrase,
                label,
                onClearSelection,
                selectedItemsNumber,
                showSelectedNumber,
                isLoading,
                onOpen,
                classes: {
                    useSearchableDropDownButtonStyle: classMaker(useSearchableDropDownButtonStyle, customTheme),
                    searchableDropDownStyle,
                    useSearchableDropDownContentStyle: classMaker(useSearchableDropDownContentStyle, customTheme),
                },
            }}
        >
            {children}
        </SearchableDropDownContext.Provider>
    );
};

SearchableDropDownContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    customTheme: PropTypes.object,
    hideIcon: PropTypes.bool,
    opened: PropTypes.bool,
    buttonLabel: PropTypes.string,
    iconElement: PropTypes.element,
    listData: PropTypes.arrayOf(PropTypes.object),
    handleOnChange: PropTypes.func,
    listLabel: PropTypes.string,
    label: PropTypes.string,
    buttonType: PropTypes.string, //'select' || 'dropdown'
    onClearSelection: PropTypes.func,
    selectedItemsNumber: PropTypes.number,
    showSelectedNumber: PropTypes.bool,
    isLoading: PropTypes.bool,
    onOpen: PropTypes.func,
};

SearchableDropDownContextProvider.defaultProps = {
    customTheme: null,
    hideIcon: false,
    buttonLabel: 'Filters',
    iconElement: null,
    opened: false,
    listData: null,
    handleOnChange: () => null,
    listLabel: null,
    label: null,
    buttonType: 'dropdown',
    onClearSelection: null,
    selectedItemsNumber: null,
    showSelectedNumber: false,
    isLoading: false,
    onOpen: null,
};

export default SearchableDropDownContext;
