import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { safeParseJSON } from '@mediabank/utils';

import configSlice from '../../store/config';

const {
    selectors: { getConfig },
} = configSlice;

const APP_THEMES = [
    { index: 0, name: 'default' },
    { index: 1, name: 'dark' },
    { index: 2, name: 'light' },
];

const ThemeProvider = () => {
    const { Theme } = useSelector(getConfig) || {};
    const themeFromConfig = (!!Theme && safeParseJSON(Theme, null)) || null;

    /* This implementation supports so far only the "first stage" implementation
        for handling the name from config - accepted values are default, dark, light
        the theming works based on indexes therefore we need to map the solution to inject the right index
        maybe a dynamic import in mbtheme.js could be considered for future improvements? Or an index could be added to the config?
        I am keeping this "open" now as the theme settings implementation will be configured later, which will effect that part anyway.
    */

    // Use this to mock the config change
    /* themeFromConfig = {
        name: 'light'
    } */

    const switchTheme = index => {
        localStorage.setItem('appTheme', `${index}`);
        window.dispatchEvent(new Event('storage'));
    };

    useEffect(() => {
        const storedTheme = localStorage.getItem('appTheme');
        if (themeFromConfig !== null) {
            APP_THEMES?.forEach(theme => {
                if (theme && theme.name === themeFromConfig?.name) {
                    switchTheme(theme.index);
                }
            });
        } else {
            // Always fallback default dark theme if no config set (even if there is a stored theme greater than 0, 1, 2)
            if (storedTheme > 2) {
                switchTheme(1);
            }
        }
    }, [themeFromConfig]);

    return <></>;
};

export default ThemeProvider;
