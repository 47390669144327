import React from 'react';
import { FormControl, FormLabel, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useLabelStyles = makeStyles()((theme, _params, classes) => ({
    root: {
        color: theme.palette.text.primary,
        fontSize: '1.2rem',
        [`&.${classes.focused}`]: {
            color: theme.palette.text.primary,
        },
    },
    focused: {},
}));

const RadioButtonGroup = ({ children, name, value, onChange, legend, ...forwardProps }) => {
    const { classes: labelClasses } = useLabelStyles();

    const handleChange = event => {
        onChange(event.target.value);
    };

    return (
        <FormControl>
            {legend && (
                <FormLabel
                    classes={{
                        root: labelClasses.root,
                    }}
                >
                    {legend}
                </FormLabel>
            )}
            <RadioGroup name={name} value={value} onChange={handleChange} {...forwardProps}>
                {children}
            </RadioGroup>
        </FormControl>
    );
};

RadioButtonGroup.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    legend: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

RadioButtonGroup.defaultProps = {
    value: undefined,
    name: '',
    legend: undefined,
    onChange: () => null,
};

export default RadioButtonGroup;
