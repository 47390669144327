import React from 'react';
import { ReactComponent as ChevronDown } from '@mediabank/assets/icons/chevron-down.svg';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { isFullWidth }) => ({
    wrapper: {
        width: isFullWidth ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        paddingRight: theme.spacing(1),
    },
}));

const useSelectStyles = makeStyles()(theme => ({
    select: {
        '&.MuiSelect-select': {
            padding: theme.spacing(0, 1),
            width: '100%',
            fontSize: '1.2rem',
        },
    },
    icon: {
        top: '9px',
        color: theme.palette.text.primary,
    },
    menuItemRoot: {
        display: 'flex',
        padding: theme.spacing(0.75, 1),
        justifyContent: 'flex-start',
        fontSize: '1.2rem',
        '&:hover': {
            backgroundColor: theme.custom.accentLight[0.1],
        },
    },
    menuItemSelected: {
        '&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover': {
            backgroundColor: theme.custom.accentLight[0.2],
        },
        '&.Mui-selected': {
            background: theme.custom.accentLight[0.2],
            '&:hover': {
                background: theme.custom.accentLight[0.1],
            },
        },
    },
}));

const LimitSelector = ({ itemsPerPage, itemsPerPageOptions, onItemsPerPageChange, isFullWidth }) => {
    const { classes } = useStyles({ isFullWidth });
    const { classes: selectClasses } = useSelectStyles();
    const itemExists = !!itemsPerPageOptions.find(item => item.value === itemsPerPage);

    if (!itemExists) {
        itemsPerPageOptions.push({ label: itemsPerPage, value: itemsPerPage });
    }

    itemsPerPageOptions.sort((a, b) => a.value - b.value);

    return (
        <div className={classes.wrapper}>
            <Box>
                <Typography className={classes.text} variant="inherit">
                    Items per page:
                </Typography>
            </Box>
            <Box style={{ marginLeft: 'auto' }}>
                <Select
                    classes={{
                        select: selectClasses.select,
                        icon: selectClasses.icon,
                    }}
                    data-cy="pagerItemsPerPage"
                    IconComponent={ChevronDown}
                    value={itemsPerPage}
                    onChange={onItemsPerPageChange}
                >
                    {itemsPerPageOptions.map(option => (
                        <MenuItem
                            key={option.value}
                            classes={{
                                root: selectClasses.menuItemRoot,
                                selected: selectClasses.menuItemSelected,
                            }}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </div>
    );
};

LimitSelector.propTypes = {
    itemsPerPage: PropTypes.number,
    onItemsPerPageChange: PropTypes.func.isRequired,
    itemsPerPageOptions: PropTypes.array,
    isFullWidth: PropTypes.bool.isRequired,
};

LimitSelector.defaultProps = {
    itemsPerPage: 25,
    itemsPerPageOptions: [
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 75, value: 75 },
        { label: 100, value: 100 },
    ],
};

export default LimitSelector;
