import { smartSearchService } from '@mediabank/client';

const smartSearchServiceWrapper = {};

for (const [methodName, method] of Object.entries(smartSearchService)) {
    smartSearchServiceWrapper[methodName] = (...args) =>
        method(...args).catch(error => {
            console.error(`mediabank-next-transition error: ${methodName} service.`, error);

            if (error?.response?.status === 401) {
                window.location.href = '/logout';
            }

            return error.response;
        });
}

export default { ...smartSearchServiceWrapper };
