import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    aspectRatio: '16:9',
    displayCropping: false,
    mediaThumbs: [],
    mediaThumbName: null,
};

const mediaPlayerSlice = createSlice({
    name: 'mediaPlayer',
    initialState,
    reducers: {
        closeCropMode: state => {
            state.displayCropping = false;
        },
        openCropMode: (state, action) => {
            state.displayCropping = true;
            state.aspectRatio = action.payload?.aspectRatio;
            state.mediaThumbName = action.payload?.name;
        },
        setMediaThumbCroppingDone: (state, action) => {
            const mediaThumbIndex = state.mediaThumbs.findIndex(item => item.name === action.payload.name);
            if (mediaThumbIndex !== -1) {
                state.mediaThumbs[mediaThumbIndex].croppingDone = action.payload.cropping;
            }
        },
        addMediaThumb: (state, action) => {
            const cropped = action.payload;

            const mediaThumb = {
                name: cropped?.name,
                croppedImage: cropped?.croppedImage,
                croppingDone: !!cropped?.croppingDone,
                cropSelection: cropped?.cropSelection,
                croppedPosition: cropped?.croppedPosition,
                thumbnailTimecode: cropped?.croppedTimecode,
                displayCropping: false,
            };
            const mediaThumbIndex = state.mediaThumbs.findIndex(item => item.name === mediaThumb.name);
            if (mediaThumbIndex !== -1) {
                state.mediaThumbs[mediaThumbIndex] = mediaThumb;
            } else {
                state.mediaThumbs.push(mediaThumb);
            }
            state.displayCropping = false;
            state.mediaThumbName = null;
        },
        resetCropMode: state => {
            state.displayCropping = false;
            state.mediaThumbs = [];
            state.mediaThumbName = null;
        },
    },
});

export const generateThumbnailParam = ({ x, y, height, width, videoHeight, videoWidth, croppedTimecode }) =>
    `hasnewthumb=yes,x=${x},y=${y},width=${width},height=${height},proxyWidth=${videoWidth},proxyHeight=${videoHeight},tc=${croppedTimecode},thumburl=`;

const selectMediaPlayer = state => state.mediaPlayer;
const getMediaThumb = name =>
    createSelector(selectMediaPlayer, ({ mediaThumbs }) => mediaThumbs.find(item => item.name === name));

export default {
    ...mediaPlayerSlice,
    selectors: { getMediaThumb, selectMediaPlayer },
};

export const actions = mediaPlayerSlice.actions;
