import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid } from '@development-nl/components-library';
import { NotificationsContext } from '@mediabank/uikit-v5';
import { Hidden } from '@mui/material';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import LoginForm from '../../components/LoginForm';
import useAuth from '../../hooks/useAuth';
import useNetwork from '../../hooks/useNetwork';
import { AppInfo } from '../AppInfo/index';

const useStyles = makeStyles()(theme => ({
    root: {
        height: '100vh',
        background: theme.palette.common.loginBackground,
    },
    video: {
        overflow: 'hidden',
        '& video': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        },
    },
    loginForm: {
        margin: theme.spacing(20, 4),
    },
}));

const Login = ({ location }) => {
    const { classes } = useStyles();
    const { addToastError } = useContext(NotificationsContext);

    const authenticated = useAuth();
    const { online, since } = useNetwork();
    const pathname = get(location, 'state.from.pathname') || '/';
    const redirectTo = pathname === '/login.php' ? '/' : pathname;

    const fail = () => {
        addToastError({
            title: online ? 'Auth fail' : 'Network connection error',
            subtitle: online
                ? 'Incorrect email/password'
                : `Please check your internet connection, you are offline since ${since}`,
            cy: online ? 'authFail' : 'authFail-networkError',
        });
    };

    return (
        <>
            {authenticated ? (
                <Redirect to={redirectTo} />
            ) : (
                <Grid className={classes.root} component="main" container={true}>
                    <Grid item={true} md={4} sm={8} xs={12}>
                        <LoginForm className={classes.loginForm} onFail={fail} />
                    </Grid>
                    <Hidden xsDown={true}>
                        <Grid className={classes.video} item={true} md={8} sm={4}>
                            <video
                                autoPlay={true}
                                loop={true}
                                muted={true}
                                playsInline={true}
                                poster="https://assets.website-files.com/59e7a8e1814fcf000133bb55/59f86cdd78cc2d0001fce5bc_nep237696001_proxy-poster-00001.jpg"
                            >
                                <source src="https://assets.website-files.com/59e7a8e1814fcf000133bb55/59f86cdd78cc2d0001fce5bc_nep237696001_proxy-transcode.mp4" />
                                <source src="https://assets.website-files.com/59e7a8e1814fcf000133bb55/59f86cdd78cc2d0001fce5bc_nep237696001_proxy-transcode.webm" />
                            </video>
                        </Grid>
                    </Hidden>
                    <AppInfo />
                </Grid>
            )}
        </>
    );
};

Login.propTypes = {
    location: PropTypes.object,
};

Login.defaultProps = {
    location: {},
};

export default Login;
