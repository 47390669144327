import { createSlice } from '@reduxjs/toolkit';

import { configService } from '../../services';

export const initialState = {
    loading: false,
    error: null,
    data: {
        LogoUrl: '',
    },
};

const companyConfigSlice = createSlice({
    name: 'companyConfig',
    initialState,
    reducers: {
        fetchStart: state => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            try {
                state.data = action.payload.data;
                state.loading = false;
                state.error = null;
            } catch {
                fetchError();
            }
        },
        fetchError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        clear: () => initialState,
    },
});

const { fetchStart, fetchSuccess, fetchError } = companyConfigSlice.actions;

const selectors = {
    getClientLogo: ({ companyConfig }) => (companyConfig.data ? companyConfig.data.LogoUrl : false),
};

export default { ...companyConfigSlice, selectors };

export const fetchCompanyConfig = () => async dispatch => {
    try {
        dispatch(fetchStart());
        const { data } = await configService.getCompanyConfig();
        if (data) {
            dispatch(fetchSuccess({ data: data.attributes }));
        } else {
            throw Error('400 Error');
        }
    } catch (err) {
        if (err.response?.status === 401) {
            dispatch(companyConfigSlice.actions.clear());
        }

        dispatch(fetchError(err.message));
    }
};
