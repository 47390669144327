import { makeStyles } from 'tss-react/mui';

export const useSearchableDropDownStyle = makeStyles()((theme, { labelColor, labelIndicatorColor }) => ({
    wrapper: {
        position: 'relative',
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    label: {
        fontSize: '1.2rem',
        color: labelColor || theme.custom.dynamicFilters.label || '#A5A7A7',
        lineHeight: '1.8rem',
    },
    labelWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    labelIndicator: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '1rem',
    },
    indicatorNumber: {
        color: labelIndicatorColor || theme.custom.dynamicFilters.labelIndicatorColor || '#55D6EB',
    },
    indicatorClearIcon: {
        cursor: 'pointer',
        fill: 'transparent',
        '& path': {
            stroke: labelColor || theme.custom.dynamicFilters.label || '#A5A7A7',
        },
    },
}));

export const useSearchableDropDownButtonStyle = makeStyles()(
    (theme, { buttonBackgroundColor, activeButtonColor, isActive, buttonType, buttonLabelColor, hasSelectedItem }) => ({
        wrapper: {
            width: buttonType === 'select' ? 120 : 'auto',
            backgroundColor:
                buttonType === 'dropdown'
                    ? isActive
                        ? activeButtonColor || '#00404A'
                        : buttonBackgroundColor || theme.custom.dynamicFilters.pickerBackground || '#414445'
                    : hasSelectedItem
                    ? activeButtonColor || '#00404A'
                    : theme.custom.dynamicFilters.pickerBackground || '#191C1D',
            padding: buttonType === 'dropdown' ? '0.8rem' : `${theme.spacing(0.8)} ${theme.spacing(1)}`,
            cursor: 'pointer',
            borderRadius: '0.4rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: buttonType === 'select' ? '1px solid #575A5B' : 0,
            color:
                buttonType === 'dropdown'
                    ? isActive
                        ? buttonLabelColor || theme.custom.dynamicFilters.filtersActiveButtonLabel || '#E9E9E9'
                        : buttonLabelColor || theme.custom.dynamicFilters.filtersButtonLabel || 'inherit'
                    : hasSelectedItem
                    ? buttonLabelColor || theme.custom.dynamicFilters.filtersActiveButtonLabel || '#E9E9E9'
                    : buttonLabelColor || theme.custom.dynamicFilters.filtersButtonLabel || 'inherit',
        },
        label: {
            fontSize: '1.2rem',
            lineHeight: '1.2rem',
            fontWeight: 500,
        },
        icon: {
            width: theme.spacing(1.25),
            height: theme.spacing(1.25),
            marginRight: buttonType === 'dropdown' ? '0.8rem' : '0',
            marginLeft: buttonType === 'dropdown' ? '0' : '0.8rem',
        },
    })
);

export const useSearchableDropDownContentStyle = makeStyles()(
    (
        theme,
        {
            listBackgroundColor,
            searchBackgroundColor,
            listBorderColor,
            listTitleColor,
            searchInputTextColor,
            clearTextColor,
            selectedListItemColor,
            isLoading,
        }
    ) => ({
        wrapper: {
            width: '240px',
            height: '320px',
            backgroundColor: listBackgroundColor || '#191C1D',
            position: 'absolute',
            top: 50,
            borderRadius: '0.4rem',
            border: `1px solid ${listBorderColor || '#414445'}`,
            display: 'grid',
            gridTemplateRows: 'max-content auto',
            zIndex: 100,
        },
        searchWrapper: {
            backgroundColor: searchBackgroundColor || theme.custom.dynamicFilters.searchBackgroundColor || '#000000',
            borderTopLeftRadius: '0.4rem',
            borderTopRightRadius: '0.4rem',
            position: 'sticky',
            top: 0,
        },
        contentWrapper: {
            overflowX: 'hidden',
            overflowY: 'auto',
            padding: theme.spacing(1.25),
            backgroundColor: theme.custom.dynamicFilters.pickerBackground,
        },
        listTitleText: {
            color: listTitleColor || '#A5A7A7',
            lineHeight: '1.8rem',
            fontSize: '1.2rem',
            marginBottom: '1rem',
        },
        listGroup: {
            rowGap: '1rem',
            marginBottom: '1rem',
        },
        searchBox: {
            display: 'grid',
            gridTemplateColumns: 'max-content auto max-content',
            alignItems: 'center',
            padding: '0.8rem',
            gap: '1rem',
        },
        searchIcon: {
            marginLeft: '8px',
        },
        clearSearchIcon: {
            marginRight: '8px',
            cursor: 'pointer',
            fill: theme.custom.accentLight[0.3],
            '& path': {
                stroke: theme.palette.buttonSecondary.text,
            },
        },
        searchInput: {
            backgroundColor: 'transparent',
            border: 0,
            outline: 'none',
            color: searchInputTextColor || '#575A5B',
            fontSize: '1.2rem',
            lineHeight: '1.8rem',
        },
        clearWrapper: {
            padding: '1rem',
            backgroundColor:
                listBackgroundColor || theme.custom.dynamicFilters.clearWrapperListBackgroundColor || '#191C1D',
        },
        clearText: {
            fontSize: '1.2rem',
            color: clearTextColor || theme.custom.dynamicFilters.searchDropdownClearSelectionColor || '#55D6EB',
            fontWeight: 500,
            cursor: 'pointer',
        },
        listItem: {
            '& .Mui-checked + .MuiFormControlLabel-label': {
                color: selectedListItemColor || '#55D6EB',
            },
        },
        loading: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.4)',
            zIndex: 400,
            alignItems: 'center',
            justifyContent: 'center',
            display: isLoading ? 'flex' : 'none',
        },
    })
);
