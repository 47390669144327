import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const getClipLists = async () => {
    const response = await api.get('/cliplists', {
        headers: mediabankHeaders(),
    });

    return response;
};

const getClipListDetails = async id => {
    const response = await api.get(`/cliplists/${id}/clips`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const reorderClipListDetails = async (id, newListItems) => {
    const data = {
        type: 'clips/listorder',
        attributes: {
            listorder: newListItems,
        },
    };
    const response = await api.put(`/cliplists/${id}/listorder`, {
        headers: mediabankHeaders(),
        data: { data },
    });

    return response;
};

const createClipList = async data => {
    const response = await api.post('/cliplists', {
        headers: mediabankHeaders(),
        data,
    });

    return response;
};

const createClip = async ({ id, attributes }) => {
    const response = await api.post(`/cliplists/${id}/clips`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'clips',
                attributes,
            },
        },
    });

    return response;
};

const modifyClipList = async (id, newData) => {
    const data = { data: newData };
    const response = await api.patch(`/cliplists/${id}`, {
        headers: mediabankHeaders(),
        data,
    });

    return response;
};

const deleteClipList = async id => {
    const response = await api.delete(`/cliplists/${id}`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const updateClip = async ({ clipListId, clipId, attributes }) => {
    const response = await api.patch(`/cliplists/${clipListId}/clips/${clipId}`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'clips',
                attributes,
            },
        },
    });

    return response;
};

const deleteClip = async ({ clipListId, clipId }) => {
    const response = await api.delete(`/cliplists/${clipListId}/clips/${clipId}`, {
        headers: mediabankHeaders(),
    });

    return response;
};

export default {
    createClipList,
    deleteClipList,
    deleteClip,
    getClipLists,
    getClipListDetails,
    modifyClipList,
    reorderClipListDetails,
    createClip,
    updateClip,
};
