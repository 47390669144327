import React from 'react';
import { Box } from '@development-nl/components-library';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useOverlayBoxStyles = makeStyles()(() => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    },
}));

const OverlayBox = React.forwardRef(({ children, className, ...props }, ref) => {
    const { classes, cx } = useOverlayBoxStyles();

    return (
        <Box {...props} ref={ref} className={cx(classes.root, className)}>
            {children}
        </Box>
    );
});

OverlayBox.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

OverlayBox.defaultProps = {
    className: '',
};

export default OverlayBox;
