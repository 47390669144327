import React from 'react';
import { ReactComponent as AdExIcon } from '@mediabank/assets/icons/ad-ex.svg';
import { ReactComponent as ComplianceIcon } from '@mediabank/assets/icons/compliance.svg';
import { ReactComponent as DashboardIcon } from '@mediabank/assets/icons/dashboard.svg';
import { ReactComponent as HelpIcon } from '@mediabank/assets/icons/help-filled.svg';
import { ReactComponent as LibraryIcon } from '@mediabank/assets/icons/library.svg';
import { ReactComponent as ReviewIcon } from '@mediabank/assets/icons/review.svg';
import { ReactComponent as SettingsIcon } from '@mediabank/assets/icons/settings.svg';
import { configService } from '@mediabank/client';
import { createSelector, createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    data: [],
};

const appsSlice = createSlice({
    name: 'apps',
    initialState,
    reducers: {
        fetchStart: state => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            try {
                state.data = action.payload.data ? Object.values(action.payload.data) : [];
                state.loading = false;
                state.error = null;
            } catch {
                fetchError();
            }
        },
        fetchError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        clear: () => initialState,
    },
});

const selectApps = state => state.apps;

const getApps = createSelector(selectApps, ({ data }) => {
    const dashboardObject = [...data].find(app => app.longname === 'Dashboard');
    //Put Dashboard icon under the Library icon
    const appsWithDashboard = [...[...data].filter(app => app.longname !== 'Dashboard'), { ...dashboardObject }];

    return appsWithDashboard
        .filter(
            app =>
                app.enabled && ['AdEx', 'R & A', 'Compliance', 'Admin', 'Library', 'Dashboard'].includes(app.shortname)
        )
        .map(app => {
            let Icon;

            switch (app.shortname) {
                case 'AdEx':
                    Icon = AdExIcon;
                    break;
                case 'R & A':
                    Icon = ReviewIcon;
                    break;
                case 'Compliance':
                    Icon = ComplianceIcon;
                    break;
                case 'Admin':
                    Icon = SettingsIcon;
                    break;
                case 'Library':
                    Icon = LibraryIcon;
                    break;
                case 'Dashboard':
                    Icon = DashboardIcon;
                    break;
                default:
                    Icon = HelpIcon;
            }

            return { name: app.shortname, icon: <Icon />, link: app.path };
        });
});

const selectors = {
    getApps,
};

const { fetchStart, fetchSuccess, fetchError } = appsSlice.actions;

export default { ...appsSlice, selectors };

export const fetchApps = () => async dispatch => {
    try {
        dispatch(fetchStart());
        const { data } = await configService.getApps();
        if (data) {
            dispatch(fetchSuccess({ data: data.attributes }));
        } else {
            throw Error('400 Error');
        }
    } catch (err) {
        dispatch(fetchError(err.message));
    }
};
