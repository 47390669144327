import { useState } from 'react';
import { useDragLayer } from 'react-dnd';

const checkCanDrop = monitor =>
    monitor.getTargetIds().some(id => monitor.isOverTarget(id) && monitor.canDropOnTarget(id));

const useDragLayerState = ({ type }) => {
    const [dragItems, setDragItems] = useState([]);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const { canDrop, didDrop, isDragging, isAsset, isEvent } = useDragLayer(monitor => {
        const item = monitor.getItem();
        const currentOffset = monitor.getClientOffset();
        const canDrop = checkCanDrop(monitor);
        const isDragging = monitor.isDragging();

        item && setDragItems(item.selectedItems);
        currentOffset && setOffset(currentOffset);

        return {
            isDragging,
            canDrop,
            didDrop: monitor.didDrop(),
            isAsset: !!item?.selectedItems?.[0]?.metaData,
            isEvent: !!item?.selectedItems?.[0]?.event_type,
        };
    });

    // Only enable layer if asset or event dragging

    if (type === 'asset') {
        if (!isAsset) {
            return [false, [], { x: 0, y: 0 }, false, false];
        }
    } else {
        if (!isEvent) {
            return [false, [], { x: 0, y: 0 }, false, false];
        }
    }

    return [isDragging, dragItems, offset, canDrop, didDrop];
};

export default useDragLayerState;
