const textClipCount = 1;
const textClips = Array.from({ length: textClipCount }).map((_, idx) => ({
    id: 'Text' + idx,
    in: idx + 15,
    out: idx + 30,
    title: 'Text ' + idx,
}));
const textTrackLength = Math.max(...textClips.map(c => c.out));

export default {
    name: 'SEQUENCE 1',
    duration: Math.max(textTrackLength, 70),
    tracks: [
        {
            id: 'text',
            type: 'text',
            clips: textClips,
        },
        {
            id: 'overlay',
            type: 'overlay',
            clips: [
                {
                    id: 'Overlay0',
                    in: 0,
                    out: 30,
                    title: 'Overlay',
                },
                {
                    id: 'Overlay2',
                    in: 40,
                    out: 50,
                    title: 'Overlay 2',
                },
            ],
        },
        {
            id: 'video',
            type: 'video',
            clips: [
                {
                    id: 'Clip0',
                    in: 0,
                    out: 10,
                    length: 10,
                    title: 'Clip 0',
                    segment: {
                        assetId: 'Clip asset 0',
                        in: 0,
                        out: 10,
                        length: 15,
                    },
                },
                {
                    id: 'Image1',
                    in: 10,
                    out: 20,
                    length: 10,
                    title: 'Image 1',
                    type: 'image',
                    segment: {
                        assetId: 'Clip asset 1',
                        in: 5,
                        out: 15,
                        length: 16,
                    },
                },
                {
                    id: 'Clip2',
                    in: 20,
                    out: 30,
                    length: 10,
                    title: 'Clip 2',
                    subtitle: 'subtitle for clip 2',
                    segment: {
                        assetId: 'Clip asset 0',
                        in: 3,
                        out: 13,
                        length: 20,
                    },
                },
                {
                    id: 'Clip3',
                    in: 30,
                    out: 70,
                    length: 40,
                    title: 'Clip 3',
                },
            ],
        },
        {
            id: 'audio',
            type: 'audio',
            clips: [
                {
                    id: 'Audio0',
                    in: 0,
                    out: 10,
                    title: 'Audio 0',
                    segment: {
                        assetId: 'Audio asset 0',
                        in: 0,
                        out: 10,
                        length: 15,
                    },
                },
                {
                    id: 'Audio1',
                    in: 20,
                    out: 30,
                    title: 'Audio 1',
                    segment: {
                        assetId: 'Audio asset 1',
                        in: 1,
                        out: 11,
                        length: 12,
                    },
                },
                {
                    id: 'Audio2',
                    in: 45,
                    out: 50,
                    title: 'Audio 2',
                    segment: {
                        assetId: 'Audio asset 2',
                        in: 5,
                        out: 10,
                        length: 12,
                    },
                },
            ],
        },
        {
            id: 'commentary',
            type: 'commentary',
            clips: [
                {
                    id: 'Commentary a',
                    in: 30,
                    out: 40,
                    title: 'Commentary a',
                },
                {
                    id: 'Commentary b',
                    in: 50,
                    out: 60,
                    title: 'Commentary b',
                },
            ],
        },
    ],
};
