import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const getForm = async () =>
    await api.get(`/conditional/form`, {
        headers: mediabankHeaders(),
    });

const getValues = async urlParams =>
    await api.get(`/conditional/values`, {
        urlParams,
        headers: mediabankHeaders(),
    });

export default { getForm, getValues };
