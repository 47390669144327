import { foldersService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

import foldersSlice from '../../store/folders/folders';

const initialState = {
    requesting: false,
    isOpen: false,
};

const folderEditSlice = createSlice({
    name: 'folderEdit',
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        clear: () => initialState,
    },
});

const { requestStart, requestFinished } = folderEditSlice.actions;

const createFolder = ({ data }) => async dispatch => {
    dispatch(requestStart());

    const response = await foldersService.createFolder(data);
    const { status } = response;

    dispatch(requestFinished());
    if (status === 201) {
        dispatch(foldersSlice.fetchFolders());
    }
};

const updateFolder = ({ id, data }) => async dispatch => {
    dispatch(requestStart());

    const response = await foldersService.updateFolder(id, data);
    const { status } = response;

    dispatch(requestFinished());
    if (status === 200) {
        dispatch(foldersSlice.fetchFolders());
    }
};

const deleteFolder = id => async dispatch => {
    dispatch(requestStart());

    const response = await foldersService.deleteFolder(id);
    const { status } = response;

    dispatch(requestFinished());
    if (status === 204) {
        dispatch(foldersSlice.fetchFolders());
    }
};

// not in use, but leaving here for future use just in case...
const removeAssetFromFolder = (folderId, assetId) => async dispatch => {
    dispatch(requestStart());

    const response = await foldersService.removeAssetFromFolder(folderId, assetId);
    const { status } = response;

    dispatch(requestFinished());
    if (status === 204) {
        dispatch(foldersSlice.fetchFolders());
    }
};

const selectors = {
    getIsOpen: state => state.folderEdit?.isOpen,
};

export default {
    ...folderEditSlice,
    createFolder,
    updateFolder,
    deleteFolder,
    removeAssetFromFolder,
    selectors,
};
