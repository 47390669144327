import PropTypes from 'prop-types';

import { DynamicFiltersProvider } from './DynamicFiltersContext';
import DynamicFiltersWrapper from './DynamicFiltersWrapper';

const DynamicFilters = ({
    additionalFilters,
    customTheme,
    initValues,
    sx,
    eventFilters,
    onChange,
    onClear,
    onError,
    onFieldActive,
    onGetMetaAggregates,
    onGetMetaKeys,
    onGetMetaValuesById,
}) => (
    <DynamicFiltersProvider
        customTheme={customTheme}
        eventFilters={eventFilters}
        initValues={initValues}
        onChange={onChange}
        onClear={onClear}
        onError={onError}
        onFieldActive={onFieldActive}
        onGetMetaAggregates={onGetMetaAggregates}
        onGetMetaKeys={onGetMetaKeys}
        onGetMetaValuesById={onGetMetaValuesById}
    >
        <DynamicFiltersWrapper additionalFilters={additionalFilters} sx={sx} />
    </DynamicFiltersProvider>
);

DynamicFilters.propTypes = {
    customTheme: PropTypes.object,
    initValues: PropTypes.array,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    sx: PropTypes.object,
    eventFilters: PropTypes.array,
    additionalFilters: PropTypes.array,
    onError: PropTypes.func,
    onFieldActive: PropTypes.func,
    onGetMetaAggregates: PropTypes.func,
    onGetMetaKeys: PropTypes.func,
    onGetMetaValuesById: PropTypes.func,
};

DynamicFilters.defaultProps = {
    customTheme: null,
    initValues: [],
    onChange: () => null,
    onClear: null,
    sx: null,
    eventFilters: [],
    additionalFilters: [],
    onError: null,
    onFieldActive: null,
    onGetMetaAggregates: () => null,
    onGetMetaKeys: () => null,
    onGetMetaValuesById: () => null,
};

export default DynamicFilters;
