import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@development-nl/components-library';
import { ReactComponent as Logo } from '@mediabank/assets/icons/logo.svg';
import { ContentLoading, Form, FormTextField } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import userSlice, { fetchToken } from '../../store/user';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& svg': {
            fill: theme.custom.accentDark[1],
        },
    },
    form: {
        '& label, & input': {
            color: '#3d3d3d',
            '&:WebkitAutofill': {
                WebkitBoxShadow: 'initial',
                WebkitTextFillColor: 'initial',
            },
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1),
        background: theme.custom.accentMedium[1],
        '&:hover': {
            background: theme.custom.accentMedium[1],
        },
    },
    loginButtonText: {
        padding: theme.spacing(0.5),
    },
}));

const useInputStyles = makeStyles()((theme, _, classes) => ({
    root: {
        [`&:hover .${classes.notchedOutline}`]: {
            borderColor: theme.custom.accentMedium[1],
        },
        [`&:focused .${classes.notchedOutline}`]: {
            borderColor: theme.custom.accentMedium[1],
        },
    },
    notchedOutline: {
        borderColor: theme.custom.accentMedium[1],
        color: 'red',
    },
}));

const LoginForm = ({ className, onSuccess, onFail, children }) => {
    const dispatch = useDispatch();
    const userState = useSelector(userSlice.selectors.getUser);
    const { loading = false, error: loginError = null } = userState || {};
    const { classes, cx } = useStyles();
    const { classes: inputClasses } = useInputStyles();

    const login = ({ username, password }) => {
        if (!loading) {
            dispatch(fetchToken({ email: username.trim(), password: password.trim(), onSuccess, onFail }));
        }
    };

    return (
        <div className={cx(classes.root, className)}>
            <Box sx={{ mb: 2, textAlign: 'center' }}>
                <Logo style={{ width: '50%' }} />
            </Box>
            <Box>
                <Form className={classes.form} data-testid="login-form" onSubmit={login}>
                    <FormTextField
                        autoComplete="username"
                        data-cy="username"
                        fullWidth={true}
                        id="username"
                        InputProps={{ classes: inputClasses }}
                        label="Username"
                        margin="normal"
                        name="username"
                        required={true}
                        variant="outlined"
                    />
                    <FormTextField
                        autoComplete="new-password"
                        data-cy="password"
                        fullWidth={true}
                        id="password"
                        InputProps={{ classes: inputClasses }}
                        label="Password"
                        margin="normal"
                        name="password"
                        required={true}
                        type="password"
                        variant="outlined"
                    />
                    {children}
                    <Button
                        className={classes.submit}
                        data-cy="submit"
                        fullWidth={true}
                        type="submit"
                        variant="contained"
                    >
                        {loading ? <ContentLoading /> : <Box className={classes.loginButtonText}>Log In</Box>}
                    </Button>
                    {!loading && loginError ? <Box data-testid="loginErrorText">Error: {loginError}</Box> : null}
                </Form>
            </Box>
        </div>
    );
};

LoginForm.propTypes = {
    className: PropTypes.string,
    onSuccess: PropTypes.func,
    onFail: PropTypes.func,
    children: PropTypes.node,
};

LoginForm.defaultProps = {
    className: '',
    onSuccess: () => null,
    onFail: () => null,
    children: null,
};

export default LoginForm;
