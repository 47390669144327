import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const getTagLists = async id => {
    const response = await api.get(`/assets/${id}/taglists`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const getHappenings = async id => {
    const response = await api.get(`/tags/happenings/${id}`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const getEvents = async id => {
    const response = await api.get(`/tags/happenings/${id}/events`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const getMatchInfo = async ({ assetId }) => {
    const response = await api.get(`/tags/assets/${assetId}/matchinfo`, {
        headers: mediabankHeaders(),
    });

    return response;
};

export default {
    getTagLists,
    getHappenings,
    getEvents,
    getMatchInfo,
};
