import React from 'react';
import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        top: 'initial',
        left: 'initial',
        position: 'initial',
        transform: 'initial',
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: '1.4rem',
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(0.25),
        '&$focused': {
            color: theme.palette.common.white,
        },
    },
    focused: {},
}));

const SelectLabel = ({ children, ...props }) => {
    const { classes } = useStyles();

    return (
        <div>
            <InputLabel {...{ ...props, classes }} variant="standard">
                {children}
            </InputLabel>
        </div>
    );
};

SelectLabel.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SelectLabel;
