import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ApplicationLoading } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';

import useAuth from '../hooks/useAuth';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const authenticated = useAuth();
    const renderCheck = componentProps => {
        const { location } = componentProps;

        return authenticated ? (
            <Suspense fallback={<ApplicationLoading />}>
                <Component {...componentProps} />
            </Suspense>
        ) : (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: location },
                }}
            />
        );
    };

    return <Route {...rest} render={renderCheck} />;
};

ProtectedRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

ProtectedRoute.defaultProps = {
    component: null,
};

export default ProtectedRoute;
