import advancedSearch from '../../store/advancedSearch';
import {
    ADVANCED_SEARCH_CLEAR_SEARCH,
    ADVANCED_SEARCH_DELETE_SMART_SEARCH,
    ADVANCED_SEARCH_FILTER_SMART_SEARCHES,
    ADVANCED_SEARCH_GET_SMART_SEARCH,
    ADVANCED_SEARCH_GET_SMART_SEARCHES,
    ADVANCED_SEARCH_SAVE_FILTERS,
    ADVANCED_SEARCH_SHOW_SAVE_PANEL,
    ADVANCED_SEARCH_START_SEARCH,
    ADVANCED_SEARCH_UPDATE_SMART_SEARCH,
} from './constants';

export const fetchMetaKeysAction = advancedSearch.actions.fetchMetaKeys;
export const setMatchTypeAction = advancedSearch.actions.setMatchType;
export const fetchMetaKeysSuccessAction = advancedSearch.actions.fetchMetaKeysSuccess;
export const setCreatedEnabledAction = advancedSearch.actions.setCreatedEnabled;
export const setCreatedPeriodAction = advancedSearch.actions.setCreatedPeriod;
export const setCreatedFilterAction = advancedSearch.actions.setCreatedFilter;
export const toggleSavePanelAction = advancedSearch.actions.toggleSavePanel;
export const setNameAction = advancedSearch.actions.setName;
export const setPermissionTypeAction = advancedSearch.actions.setPermissionType;
export const setQuickFilterAction = advancedSearch.actions.setQuickFilter;
export const setAllowedGroupsAction = advancedSearch.actions.setAllowedGroups;
export const setEditableAction = advancedSearch.actions.setEditable;
export const setIdAction = advancedSearch.actions.setId;
export const setSmartSearchesAction = advancedSearch.actions.setSmartSearches;
export const setSmartSearchTermAction = advancedSearch.actions.setSmartSearchTerm;
export const setLoadingAction = advancedSearch.actions.setLoading;
export const loadSmartSearchAction = advancedSearch.actions.loadSmartSearch;
export const resetSearchAction = advancedSearch.actions.resetSearch;
export const addOrUpdateFilterAction = advancedSearch.actions.addOrUpdateFilter;
export const removeFilterAction = advancedSearch.actions.removeFilter;
export const setFiltersAction = advancedSearch.actions.setFilters;
export const setKeywordAction = advancedSearch.actions.setKeyword;
export const setDefaultSearchAction = advancedSearch.actions.setDefaultSearch;
export const addToUpdatingAction = advancedSearch.actions.addToUpdating;
export const removeFromUpdatingAction = advancedSearch.actions.removeFromUpdating;
export const modifySmartSearchAction = advancedSearch.actions.modifySmartSearch;
export const removeSmartSearchAction = advancedSearch.actions.removeSmartSearch;
export const setSmartSearchResultAction = advancedSearch.actions.setSmartSearchResult;

export const startSearchEpicAction = () => ({
    type: ADVANCED_SEARCH_START_SEARCH,
});

export const showSavePanelEpicAction = () => ({
    type: ADVANCED_SEARCH_SHOW_SAVE_PANEL,
});

export const saveFiltersEpicAction = ({ isNew = true }) => ({
    type: ADVANCED_SEARCH_SAVE_FILTERS,
    payload: { isNew },
});

export const getSmartSearchesEpicAction = () => ({
    type: ADVANCED_SEARCH_GET_SMART_SEARCHES,
});

export const filterSmartSearchesEpicAction = term => ({
    type: ADVANCED_SEARCH_FILTER_SMART_SEARCHES,
    payload: term,
});

export const updateSmartSearchEpicAction = payload => ({
    type: ADVANCED_SEARCH_UPDATE_SMART_SEARCH,
    payload: { searchConfig: payload, query: {} },
});

export const deleteSmartSearchEpicAction = id => ({
    type: ADVANCED_SEARCH_DELETE_SMART_SEARCH,
    payload: id,
});

export const getSmartSearchEpicAction = id => ({
    type: ADVANCED_SEARCH_GET_SMART_SEARCH,
    payload: id,
});

export const clearSearchEpicAction = () => ({
    type: ADVANCED_SEARCH_CLEAR_SEARCH,
    payload: {},
});
