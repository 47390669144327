/**
 * constants.js brings in variables defined in our `.env` files
 * if they are prefixed with `REACT_APP_`
 *
 * @see https://create-react-app.dev/docs/adding-custom-environment-variables/
 */

export const API_TIMEOUT = !isNaN(process.env.REACT_APP_API_TIMEOUT)
    ? parseInt(process.env.REACT_APP_API_TIMEOUT)
    : 8000;

const FALLBACK = '[dev]';

export const SHORT_APP_VERSION = process.env.REACT_APP_VERSION;

export const GITHUB_RUN_NUMBER = process.env.REACT_APP_GITHUB_RUN_NUMBER || FALLBACK;

// used for Sentry error reporting & the footer of the login screen
// EXAMPLE: "1.1.0-528"
export const APP_VERSION = `${SHORT_APP_VERSION}-${GITHUB_RUN_NUMBER || FALLBACK}`;

export const IS_DEV_MODE = process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEV_MODE === 'true';

// Sentry error monitoring
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// TODO: the Sentry environment should be based on a URL or expression
// https://mbnepms.atlassian.net/browse/MBX-191
export const SENTRY_ENVIRONMENT = process.env.NODE_ENV;

export const SENTRY_IS_ACTIVE = !IS_DEV_MODE || process.env.REACT_APP_SENTRY_IS_ACTIVE === 'true';
export const SENTRY_RELEASE = `mediabank-next-transition@${APP_VERSION}`;

export const EVENT_ICON_URL = process.env.REACT_APP_EVENT_ICON_URL;
