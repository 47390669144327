import { useCallback, useEffect, useState } from 'react';
import { mbTheme } from '@mediabank/theme-v5';
import { createTheme } from '@mui/material/styles';

const useThemeSwitcher = () => {
    const appTheme = (index = 1) => createTheme(mbTheme[index]); //materialUiTheme[1]

    const [selectedTheme, setSelectedTheme] = useState(1);

    const switchTheme = useCallback(() => {
        const storedTheme = parseInt(localStorage.getItem('appTheme'));
        const isThemeNew = storedTheme && storedTheme !== selectedTheme;

        if (isThemeNew) {
            setSelectedTheme(parseInt(storedTheme));
        }
    }, [selectedTheme]);

    /* This should be redundant as anyway the Provider will set the default '1' theme in scope of Providers.
        Leaving it now, as this hook might change in the future, so we see the former idea here.
    */
    /* useEffect(() => {
        const storedTheme = localStorage.getItem('appTheme');
        if (storedTheme) {
            setSelectedTheme(parseInt(storedTheme));
        } else {
            localStorage.setItem('appTheme', '1');
        }
    }, []); */

    useEffect(() => {
        window.addEventListener('storage', switchTheme);

        return () => {
            window.removeEventListener('storage', switchTheme);
        };
    }, [switchTheme]);

    return [appTheme(selectedTheme)];
};

export default useThemeSwitcher;
