import { getPeriodType, makeFilterFromKey } from './utils';

export const matchTypes = [
    { value: 'all', label: 'All' },
    { value: 'any', label: 'Any' },
];

export const preferredMetaKeys = ['Category', 'ClipType', 'Customer', 'Campaign'];
export const defaultFilters = {
    all: [makeFilterFromKey(preferredMetaKeys[0], 0)],
    any: preferredMetaKeys.map(makeFilterFromKey),
};

export const verbItems = [
    { value: 'equal', label: 'is equal' },
    { value: 'notequal', label: 'is not equal' },
    { value: 'contains', label: 'contains' },
    { value: 'notcontains', label: 'does not contain' },
    { value: 'isdefined', label: 'is defined' },
    { value: 'isnotdefined', label: 'is not defined' },
    { value: 'some', label: 'is one of' },
    { value: 'notsome', label: 'is not one of' },
    { value: 'beforedate', label: 'before' },
    { value: 'afterdate', label: 'after' },
    { value: 'withindates', label: 'within' },
];

export const verbDefaultValues = {
    withindates: ['', ''],
    beforedate: [''],
    afterdate: [''],
    some: [],
    notsome: [],
};

export const rangeModes = [
    { value: 'before', label: 'before' },
    { value: 'after', label: 'after' },
    { value: 'within', label: 'within' },
    { value: getPeriodType(), label: 'in the last' },
];

export const createdPeriodTypes = [
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'months', label: 'Months' },
];

export const perMissionTypes = {
    private: 'Private',
    group: 'Group',
    public: 'Public',
};

export const ADVANCED_SEARCH_LOAD_METAKEYS = '@@advancedSearch/fetchMetaKeys';
export const ADVANCED_SEARCH_START_SEARCH = '@@advancedSearch/startSearch';
export const ADVANCED_SEARCH_SHOW_SAVE_PANEL = '@@advancedSearch/showSavePanel';
export const ADVANCED_SEARCH_TOGLE_SAVE_PANEL = '@@advancedSearch/toggleSavePanel';
export const ADVANCED_SEARCH_SAVE_FILTERS = '@@advancedSearch/saveFilters';
export const ADVANCED_SEARCH_GET_SMART_SEARCHES = '@@advancedSearch/getSmartSearches';
export const ADVANCED_SEARCH_FILTER_SMART_SEARCHES = '@@advancedSearch/filterSmartSearches';
export const ADVANCED_SEARCH_UPDATE_SMART_SEARCH = '@@advancedSearch/updateSmartSearch';
export const ADVANCED_SEARCH_DELETE_SMART_SEARCH = '@@advancedSearch/deleteSmartSearches';
export const ADVANCED_SEARCH_GET_SMART_SEARCH = '@@advancedSearch/getSmartSearch';
export const ADVANCED_SEARCH_CLEAR_SEARCH = '@@advancedSearch/clearSearch';
export const ADVANCED_SEARCH_REMOVE_SMART_SEARCH = '@@advancedSearch/removeSmartSearch';
export const ADVANCED_SEARCH_MODIFY_SMART_SEARCH = '@@advancedSearch/modifySmartSearch';
