import { assetsService, foldersService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    requesting: false,
    data: [],
    folderDetails: null,
    folderAssets: null,
};

const foldersSlice = createSlice({
    name: 'folders',
    initialState,
    reducers: {
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        update: (state, action) => {
            const { payload } = action;
            state.data = payload.folders;
        },
        updateDetails: (state, action) => {
            const { payload } = action;
            state.folderDetails = payload.folderDetails;
        },
        updateAssets: (state, action) => {
            const { payload } = action;
            state.folderAssets = payload.updatedFolderAssets;
        },
        resetFolders: state => {
            state.folderDetails = null;
        },
        clear: () => initialState,
    },
});

const { requestStart, requestFinished, update, updateAssets, updateDetails } = foldersSlice.actions;

const fetchFolders = () => async dispatch => {
    dispatch(requestStart());

    try {
        const response = await foldersService.getFolders();

        dispatch(requestFinished());
        if (response?.status === 200) {
            const folders = response?.data?.data || [];
            dispatch(update({ folders }));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const fetchFolderDetails = id => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await foldersService.getFolderDetails(id);

        if (response?.status === 200) {
            dispatch(requestFinished());
            const folderDetails = response?.data?.data || [];
            dispatch(updateDetails({ folderDetails }));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const fetchFolderAssetsByIds = assetIDs => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await assetsService.getAssetsFromIds({ ids: assetIDs });

        if (response?.status === 200) {
            dispatch(requestFinished());
            const folderAssets = response?.data?.data || [];
            dispatch(updateAssets({ updatedFolderAssets: folderAssets }));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const isRequesting = state => state.folders.requesting;
const folders = state => state.folders?.data;
const folderAssets = state => state.folders?.folderAssets;
const folderDetails = state => state.folders?.folderDetails;

export default {
    ...foldersSlice,
    fetchFolders,
    fetchFolderDetails,
    fetchFolderAssetsByIds,
    selectors: { isRequesting, folders, folderAssets, folderDetails },
};
