import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@development-nl/components-library';
import PropTypes from 'prop-types';

import NumIndicator from '../NumIndicator';
import { getMboxItems } from '../utils';
import { MBoxIconsContainer } from './MBoxIconsContainer';
import { useMboxCardStyles } from './style';

const MBoxCard = React.forwardRef(
    (
        {
            height,
            imageUrl,
            hoverImageUrl,
            shortName,
            description,
            hover: dragHover,
            selected,
            onSelect,
            onTransitionEnd,
            items,
            className,
            dndActive,
            options: { forcePublishingForm },
        },
        ref
    ) => {
        const { classes, cx } = useMboxCardStyles({ dragHover, height, hoverImageUrl, imageUrl, forcePublishingForm });
        const [isMouseOver, setIsMouseOver] = useState(false);
        const [isSelected, setIsSelected] = useState(false);

        const select = () => {
            setIsSelected(true);
            onSelect && onSelect();
        };

        const transitionEnd = () => {
            if (isSelected) {
                setIsSelected(false);
            } else {
                onTransitionEnd && onTransitionEnd();
            }
        };
        const isFlipping = (dragHover || isMouseOver) && !forcePublishingForm;

        const numberOfItems = getMboxItems(items).length;

        return (
            <CSSTransition classNames="flashBg" in={selected || isSelected} timeout={250} onEntered={transitionEnd}>
                <div
                    ref={ref}
                    className={cx(classes.cardContainer, { flipping: isFlipping }, className)}
                    data-cy="mboxCard"
                    data-testid="mboxCard"
                    onClick={select}
                    onMouseEnter={() => !dndActive && setIsMouseOver(true)}
                    onMouseLeave={() => setIsMouseOver(false)}
                >
                    <div className={cx(classes.card, classes.cardFront)}>
                        <MBoxIconsContainer hoverImageUrl={hoverImageUrl} imageUrl={imageUrl} variant={'closed'} />
                        <Typography className={classes.shortName} data-cy="mboxCardTitle" noWrap={true} variant="body2">
                            {shortName}
                        </Typography>
                        {items.length > 0 && <NumIndicator className={classes.numberOfItems} number={numberOfItems} />}
                    </div>

                    <div className={cx(classes.card, classes.cardBack)}>
                        <MBoxIconsContainer hoverImageUrl={hoverImageUrl} imageUrl={imageUrl} variant={'opened'} />
                        {description && (
                            <Typography className={classes.description} data-cy="mboxCardDescription" variant="body2">
                                {description}
                            </Typography>
                        )}
                        {!description && <Typography className={classes.shortName} />}
                        {items.length > 0 && <NumIndicator className={classes.numberOfItems} number={numberOfItems} />}
                    </div>
                </div>
            </CSSTransition>
        );
    }
);

MBoxCard.propTypes = {
    height: PropTypes.number,
    imageUrl: PropTypes.string.isRequired,
    hoverImageUrl: PropTypes.string,
    shortName: PropTypes.string,
    description: PropTypes.string,
    hover: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onTransitionEnd: PropTypes.func,
    items: PropTypes.array,
    className: PropTypes.string,
    dndActive: PropTypes.bool,
    options: PropTypes.shape({
        forcePublishingForm: PropTypes.bool,
    }),
};

MBoxCard.defaultProps = {
    height: 120,
    hoverImageUrl: null,
    shortName: null,
    description: null,
    hover: false,
    selected: false,
    onSelect: undefined,
    onTransitionEnd: undefined,
    items: [],
    className: '',
    dndActive: false,
    options: { forcePublishingForm: false },
};

export default MBoxCard;
