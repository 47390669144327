import { Typography } from '@development-nl/components-library';
import { styled } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const toolsGeneralStyle = theme => ({
    backgroundColor: theme.custom.accentDark[0.4],
    borderColor: theme.custom.accentDark[0.4],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

export const useDefaultStyles = makeStyles()(theme => ({
    root: {
        ...toolsGeneralStyle(theme),
        borderRadius: theme.spacing(0, 0, 1, 1),
        color: theme.custom.accentLight[0.9],
        position: 'relative',
        height: '100%',
    },
}));

export const useToolsStyle = makeStyles()(theme => ({
    root: {
        ...toolsGeneralStyle(theme),
        '& svg': {
            fill: theme.palette.icon.light,
        },
    },
}));

export const titleClasses = {
    root: 'Title-root',
};

export const subTitleClasses = {
    root: 'SubTitle-root',
};

export const dateClasses = {
    root: 'Date-root',
};

export const TitleTypography = styled(Typography)(({ theme, isplaceholder }) => ({
    [`&.${titleClasses.root}`]: {
        fontSize: 14,
        marginBottom: theme.spacing(0.375),
        position: 'relative',
        overflow: 'hidden',
        wordBreak: 'break-all',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
        opacity: isplaceholder === 'true' ? 0 : 1,
        lineHeight: '1.5em',
        height: '3em', // webkit-line-clamp does not work on Safari for this component, so we need a fix height that includes line-height nad font-size
    },
}));
export const SubTitleTypography = styled(Typography)(({ theme, isplaceholder }) => ({
    [`&.${subTitleClasses.root}`]: {
        fontSize: 12,
        color: theme.custom.accentLight[0.4],
        lineHeight: 1.4,
        marginBottom: theme.spacing(0.375),
        opacity: isplaceholder === 'true' ? 0 : 1,
    },
}));

export const EventSubTitleTypography = styled(SubTitleTypography)(() => ({
    [`&.${subTitleClasses.root}`]: {
        color: 'inherit',
    },
}));

export const DateTypography = styled(Typography)(({ theme, isplaceholder }) => ({
    [`&.${dateClasses.root}`]: {
        fontSize: 12,
        color: theme.custom.accentLight[0.6],
        lineHeight: 1.4,
        opacity: isplaceholder === 'true' ? 0 : 1,
    },
}));
