import { capitalize } from '@mediabank/utils';
import { createSlice } from '@reduxjs/toolkit';

import { displayTypes } from '../../components/SearchResult/constants';

/**
 * Mapping for action name, and key used in ui slice. All ui states that
 * should be mapped to a url param should be added here.
 * Format should be:
 * {
 *    action: '<name of dispatched action>',
 *    key: '<key used in url params and ui slice>',
 *    get: <method for getting value, useful if url value is in json format
 *    intitalValue: <inital value for store>
 * }
 */
export const actionToUrlParamMapping = [
    {
        action: 'ui/setDisplayType',
        key: 'displayType',
        initalValue: displayTypes.thumbnail,
    },
    {
        action: 'ui/setCurrentSearchMode',
        key: 'currentSearchMode',
        initalValue: '',
    },
    {
        action: 'ui/setAssetId',
        key: 'assetId',
        setValue: val => String(val),
        initalValue: null,
    },
    {
        action: 'ui/setTab',
        key: 'tab',
        initalValue: null,
    },
    {
        action: 'ui/setP',
        key: 'p', //PlayerTime
        initalValue: null,
    },
    {
        action: 'ui/setPropertiesSubTab',
        key: 'propertiesSubTab',
        initalValue: null,
    },
    {
        action: 'ui/setPropertiesTab',
        key: 'propertiesTab',
        initalValue: 'properties',
    },
    {
        action: 'ui/setPublishingSubTab',
        key: 'publishingSubTab',
        initalValue: null,
    },
    {
        action: 'ui/setTimedEventsTab',
        key: 'timedEventsTab',
        initalValue: 'taglist',
    },
    {
        action: 'ui/setTimeline',
        key: 'timeline',
        setValue: val => {
            if (val === 'false' || !val) {
                return false;
            }

            return true;
        },
        initalValue: false,
    },
    {
        action: 'ui/setTimelineOpen',
        key: 'timelineOpen',
        setValue: val => {
            if (val === 'false' || !val) {
                return false;
            }

            return true;
        },
        initalValue: false,
    },
    {
        action: 'ui/setLimit',
        key: 'limit',
        getValue: (val, state) => val ?? Number(state.config.data.DefaultItemsPerPage ?? 10),
        setValue: val => Number(val),
        initalValue: null,
    },
    {
        action: 'ui/setPage',
        key: 'page',
        getValue: val => val ?? 1,
        setValue: val => Number(val),
        initalValue: 1,
    },
    {
        action: 'ui/setEventResultLimit',
        key: 'eventResultLimit',
        getValue: (val, state) => val ?? Number(state.config.data.DefaultItemsPerPage ?? 10),
        setValue: val => Number(val),
        initalValue: null,
    },
    {
        action: 'ui/setEventResultPage',
        key: 'eventResultPage',
        getValue: val => val ?? 1,
        setValue: val => Number(val),
        initalValue: 1,
    },
    {
        action: 'ui/setIsLayoutSelection',
        key: 'isLayoutSelection',
        initalValue: null,
    },
    {
        action: 'ui/setOpenedClipListId',
        key: 'openedClipListId',
        initalValue: null,
    },
    {
        action: 'ui/setInfoTab',
        key: 'infoTab',
        initalValue: 'lineUp',
    },
    {
        action: 'ui/setDashboardSlug',
        key: 'dashboardSlug',
        initalValue: '',
    },
    {
        action: 'ui/setActiveSearchResultTab',
        key: 'activeSearchResultTab',
        initalValue: 'all', // We need to check this param when the combined search is NOT active
    },
    {
        action: 'ui/setCalendarYear',
        key: 'calendarYear',
        initalValue: null,
    },
    {
        action: 'ui/setCalendarMonth',
        key: 'calendarMonth',
        initalValue: null,
    },
];

/**
 *
 * @returns Generator function for selectors
 */
const generateSelectors = () => {
    const selectors = {};

    actionToUrlParamMapping.forEach(entry => {
        const get = entry.getValue ? entry.getValue : val => val;
        selectors[`get${capitalize(entry.key)}`] = state => get(state.ui[entry.key], state, entry);
    });

    return selectors;
};

/**
 * Generator function for initalState
 * @returns object
 */
const generateInitialState = () => {
    const initialState = {};

    actionToUrlParamMapping.forEach(entry => {
        initialState[entry.key] = entry.initalValue;
    });

    return initialState;
};
/**
 * Generator function for reducers
 * @returns object
 */
export const generateReducers = () => {
    const reducers = {};

    actionToUrlParamMapping.forEach(entry => {
        reducers[`set${capitalize(entry.key)}`] = (state, action) => {
            state[entry.key] = entry.setValue ? entry.setValue(action.payload) : action.payload;
        };
    });

    return reducers;
};

export const getEntryFromParamName = name => actionToUrlParamMapping.find(entry => entry.key === name);

export const getEntryFromAction = name => actionToUrlParamMapping.find(entry => entry.action === name);

export const reducers = [];

export const uiSlice = createSlice({
    name: 'ui',
    initialState: generateInitialState(),
    reducers: generateReducers(),
});

const getPanelCount = state => {
    const entry = getEntryFromParamName('dashboardSlug');
    const dashboardSlug = state.ui[entry.key];

    return dashboardSlug?.split(',')?.length;
};

export const selectors = { ...generateSelectors(), getPanelCount };

export default { ...uiSlice, selectors };
