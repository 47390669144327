import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const getEvents = async ({ happeningId }) => {
    const response = await api.get(`/tags/happenings/${happeningId}/events`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const getAssets = async ({ eventsInfo }) => {
    const params = eventsInfo
        .map(
            (eventInfo, index) =>
                `events[${index}][happeningId]=${eventInfo.happeningId}&events[${index}][part]=${eventInfo.part}`
        )
        .join('&');

    const response = await api.get(`/tags/assets/lookup?${params}`, {
        headers: mediabankHeaders(),
    });

    return response;
};

export default {
    getEvents,
    getAssets,
};
