import PropTypes from 'prop-types';

import { SearchableDropDownContextProvider } from './SearchableDropDownContext';
import SearchableDropDownWrapper from './SearchableDropDownWrapper';

const SearchableDropDown = ({
    customTheme,
    hideIcon,
    buttonLabel,
    iconElement,
    opened,
    listData,
    isLoading,
    handleOnChange,
    listLabel,
    label,
    buttonType,
    onClearSelection,
    selectedItemsNumber,
    showSelectedNumber,
    onOpen,
}) => (
    <SearchableDropDownContextProvider
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        customTheme={customTheme}
        handleOnChange={handleOnChange}
        hideIcon={hideIcon}
        iconElement={iconElement}
        isLoading={isLoading}
        label={label}
        listData={listData}
        listLabel={listLabel}
        opened={opened}
        selectedItemsNumber={selectedItemsNumber}
        showSelectedNumber={showSelectedNumber}
        onClearSelection={onClearSelection}
        onOpen={onOpen}
    >
        <SearchableDropDownWrapper />
    </SearchableDropDownContextProvider>
);

SearchableDropDown.propTypes = {
    customTheme: PropTypes.object,
    hideIcon: PropTypes.bool,
    opened: PropTypes.bool,
    listData: PropTypes.arrayOf(PropTypes.object),
    buttonLabel: PropTypes.string,
    listLabel: PropTypes.string,
    iconElement: PropTypes.element,
    handleOnChange: PropTypes.func,
    label: PropTypes.string,
    buttonType: PropTypes.string, //'select' || 'dropdown'
    onClearSelection: PropTypes.func,
    selectedItemsNumber: PropTypes.number,
    showSelectedNumber: PropTypes.bool,
    isLoading: PropTypes.bool,
    onOpen: PropTypes.func,
};

SearchableDropDown.defaultProps = {
    customTheme: null,
    hideIcon: false,
    opened: false,
    listData: null,
    listLabel: null,
    buttonLabel: 'Filters',
    iconElement: null,
    handleOnChange: () => null,
    label: null,
    buttonType: 'dropdown',
    onClearSelection: null,
    selectedItemsNumber: null,
    showSelectedNumber: false,
    isLoading: false,
    onOpen: null,
};

export default SearchableDropDown;
