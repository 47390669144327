import { clipListsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    requesting: false,
    data: [],
};

const clipListsSlice = createSlice({
    name: 'clipLists',
    initialState,
    reducers: {
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        update: (state, action) => {
            const { payload } = action;
            state.data = payload.clipLists;
        },
        toggleVisibility: (state, action) => {
            const { payload: id } = action;
            const index = state.data.findIndex(item => item.id === id);

            if (index !== -1) {
                const newData = [...state.data];

                newData[index].attributes.openInTab = !newData[index].attributes.openInTab;

                state.data = newData;
            }
        },
    },
});

const { requestStart, requestFinished, update } = clipListsSlice.actions;

const fetchClipLists = () => async dispatch => {
    dispatch(requestStart());

    try {
        const response = await clipListsService.getClipLists();

        dispatch(requestFinished());
        if (response?.status === 200) {
            const clipLists = response?.data?.data || [];
            dispatch(update({ clipLists }));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const isRequesting = state => state.clipLists.requesting;
const clipLists = state => state.clipLists.data;

export default {
    ...clipListsSlice,
    fetchClipLists,
    selectors: { isRequesting, clipLists },
};
