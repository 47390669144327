export const downloadFile = (uri, title, target = '_blank') => {
    const anchor = document.createElement('a');
    const event = document.createEvent('MouseEvents');
    anchor.target = target;
    anchor.href = uri;
    anchor.download = title;
    // ctrl-click link
    event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, true, false, false, false, 0, null);
    anchor.dispatchEvent(event);
};

export const downloadFileBlob = (blobData, fileName, fileType) => {
    const link = document.createElement('a');
    var binaryData = [];
    binaryData.push(blobData);
    link.href = window.URL.createObjectURL(new Blob(binaryData, { type: fileType }));
    link.download = fileName;
    link.click();
};

export const downloadLiveClips = (url, title) => {
    if (url) {
        downloadFile(url, title);
    }
};

export const downloadOriginal = asset => {
    const assetTitle = asset?.assetmeta?.Title;
    const assetId = asset?.assetid;
    downloadFile(`/download/?type=original&assetid=${assetId}`, assetTitle);
};

export const downloadPoster = asset => {
    const assetTitle = asset?.assetmeta?.Title;
    // Different data format when getting one asset an making a search
    const poster = asset?.PosterURL || asset?.assetmeta?.PosterURL;
    downloadFile(poster, assetTitle);
};

export const downloadProxy = asset => {
    const assetTitle = asset?.assetmeta?.Title;
    const assetId = asset?.assetid;
    downloadFile(`/download/?type=proxy&assetid=${assetId}`, assetTitle);
};

export const SUPPORTED_DOCUMENT_MIMETYPES = {
    'application/pdf': 'pdf.png',
    'application/msword': 'doc.png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc.png',
    'application/excel': 'xls.png',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls.png',
    'application/vnd.ms-powerpoint': 'ppt.png',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt.png',
    'application/xml': 'xml.png',
    'application/x-iwork-keynote-sffkey': 'key.png',
    'application/json': 'json.png',
    'application/zip': 'zip.png',
    'text/plain': 'txt.png',
    'text/csv': 'csv.png',
};

export const getMimeTypeFull = asset => {
    const { assetmeta = {} } = asset || {};
    const { MimeTypeFull } = assetmeta;
    return MimeTypeFull;
};

export const getPoster = asset => {
    const { PosterURL } = asset;
    return PosterURL;
};

export const getDocumentPoster = asset => SUPPORTED_DOCUMENT_MIMETYPES[getMimeTypeFull(asset)];

export const isDocument = asset => !!getDocumentPoster(asset);

export const getAssetPoster = asset => (isDocument(asset) ? getDocumentPoster(asset) : getPoster(asset));

export const isDataFeed = asset => {
    const {
        assetmeta: { MediaType },
    } = asset;

    return MediaType === 'Data feed';
};
