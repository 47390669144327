import React from 'react';
import { Button, Typography } from '@development-nl/components-library';
import { ReactComponent as CloseIcon } from '@mediabank/assets/icons/close-white.svg';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.custom.accentDark[0.9],
        borderTopLeftRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
    },
    title: {
        position: 'relative',
        paddingBottom: theme.spacing(2),
    },

    closeIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0.625),
        transform: 'scale(2)',
        stroke: theme.palette.text.primary,
        padding: 0,
    },
}));

const MBoxPanel = ({ title, closePanel, className, children, ...props }) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.root, className)} {...props}>
            <div className={classes.title}>
                <Typography align="center" data-cy="mboxPanelTitle" variant="h5">
                    {title}
                </Typography>
                <Button className={classes.closeIcon} onClick={closePanel}>
                    <CloseIcon data-cy="closeMBoxPanel" />
                </Button>
            </div>
            {children}
        </div>
    );
};

MBoxPanel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    closePanel: PropTypes.func.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
};

MBoxPanel.defaultProps = {
    children: null,
    title: '',
    className: '',
};

export default MBoxPanel;
