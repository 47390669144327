import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { usePlaceholderLoadingStyles } from './styles';

const PlaceholderLoading = ({ itemCount = 4, className, style, spacing, direction, noWrapper }) => {
    const { classes, cx } = usePlaceholderLoadingStyles({ ...style });
    const content = Array(itemCount > 0 ? itemCount : 0)
        .fill('')
        .map((_, index) => {
            const item = (
                <div
                    key={index}
                    className={cx(classes.placeholder, className)}
                    data-cy="placeholderLoading"
                    role="progressbar"
                    style={{ animationDelay: `-${(itemCount - index) * 0.4}s` }}
                />
            );

            return noWrapper ? (
                item
            ) : (
                <Grid key={index} item={true}>
                    {item}
                </Grid>
            );
        });

    return noWrapper ? (
        content
    ) : (
        <Grid container={true} direction={direction} spacing={spacing}>
            {content}
        </Grid>
    );
};

PlaceholderLoading.propTypes = {
    itemCount: PropTypes.number,
    style: PropTypes.object,
    spacing: PropTypes.number,
    direction: PropTypes.string,
    noWrapper: PropTypes.bool,
    className: PropTypes.string,
};

PlaceholderLoading.defaultProps = {
    itemCount: 4,
    style: {},
    spacing: 2,
    direction: 'row',
    noWrapper: false,
    className: '',
};

export default PlaceholderLoading;
