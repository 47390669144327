import React from 'react';
import { ReactComponent as InfoIcon } from '@mediabank/assets/icons/info.svg';
import { Close } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    base: {
        position: 'relative',
        minHeight: theme.spacing(8),
        width: theme.spacing(55),
        padding: theme.spacing(1.5, 1.75, 1, 2),
        borderRadius: `${theme.spacing(0.75)}`,
        border: `${theme.spacing(0.15)} solid transparent`,
        backgroundColor: '#131516',
    },
    icon: {
        position: 'absolute',
        right: theme.spacing(1.5),
        color: theme.custom.accentLight[1],
        cursor: 'pointer',
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: '1.4rem',
        fontWeight: 500,
        lineHeight: 'normal',
        paddingLeft: `${theme.spacing(4)}`,
    },
    subtitle: {
        color: theme.id === 'light' ? theme.palette.text.secondary : 'rgba(255, 255, 255, 0.7)',
        fontSize: '1.2rem',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflowWrap: 'break-word',
        paddingLeft: `${theme.spacing(4)}`,
    },
    info: {
        borderColor: theme.palette.info.main,
    },
    infoIcon: {
        position: 'absolute',
        left: 10,
        top: 10,

        zIndex: 1,
        '& svg': {
            width: 20,
            cursor: 'pointer',
            color: '#517AFF',
        },
    },
    okButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const ToastContent = ({ setShowOverlay, subtitle, title, toastKey }) => {
    const { classes, cx } = useStyles();
    const { closeSnackbar } = useSnackbar();

    const handleClose = () => {
        setShowOverlay(false);
        closeSnackbar(toastKey);
    };

    return (
        <Paper className={cx(classes.base, classes.info)} elevation={3}>
            <Box className={classes.infoIcon}>
                <span>
                    <InfoIcon />
                </span>
            </Box>
            <Close className={cx(classes.icon)} onClick={handleClose} />
            {title && <Typography className={cx(classes.title)}>{title}</Typography>}
            {subtitle && <Typography className={cx(classes.subtitle)}>{subtitle}</Typography>}
            <Box className={classes.okButton}>
                <Button disableRipple={true} variant="contained" onClick={handleClose}>
                    Ok, got it
                </Button>
            </Box>
        </Paper>
    );
};

ToastContent.defaultProps = {
    setShowOverlay: null,
    subtitle: '',
    title: '',
};

ToastContent.propTypes = {
    setShowOverlay: PropTypes.func,
    toastKey: PropTypes.number.isRequired,
    subtitle: PropTypes.string,
    title: PropTypes.string,
};

export { ToastContent };
