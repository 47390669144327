import { useContext } from 'react';
import { ReactComponent as ClearFilterIcon } from '@mediabank/assets/icons/clear-search-text-disabled.svg';
import { Box, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import DropDownButton from './DropDownButton';
import DropDownContent from './DropDownContent';
import SearchableDropDownContext from './SearchableDropDownContext';

const SearchableDropDownWrapper = () => {
    const {
        setIsOpened,
        isOpened,
        label,
        selectedItemsNumber,
        showSelectedNumber,
        onClearSelection,
        classes: { searchableDropDownStyle },
    } = useContext(SearchableDropDownContext);

    return (
        <ClickAwayListener onClickAway={() => setIsOpened(false)}>
            <Box className={searchableDropDownStyle.wrapper}>
                <Box className={searchableDropDownStyle.labelWrapper}>
                    {label && <Typography className={searchableDropDownStyle.label}>{label}</Typography>}
                    {showSelectedNumber && parseInt(selectedItemsNumber) > 0 && (
                        <Box className={searchableDropDownStyle.labelIndicator}>
                            <Typography className={searchableDropDownStyle.indicatorNumber}>
                                {selectedItemsNumber}
                            </Typography>{' '}
                            <ClearFilterIcon
                                className={searchableDropDownStyle.indicatorClearIcon}
                                onClick={onClearSelection}
                            />
                        </Box>
                    )}
                </Box>
                <DropDownButton />
                {isOpened && <DropDownContent />}
            </Box>
        </ClickAwayListener>
    );
};

export default SearchableDropDownWrapper;
