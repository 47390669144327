import { rgba2rgb } from '@mediabank/theme';
import { makeStyles } from 'tss-react/mui';

export const useMboxCardStyles = makeStyles()((theme, { dragHover, height, forcePublishingForm }, classes) => ({
    cardContainer: {
        height: height,
        cursor: 'pointer',
        position: 'relative',
        transition: 'background 0.5s ease;',
        '&.flashBg-enter, &.flashBg-exit-active': {
            [`& .${classes.card}`]: {
                background: theme.palette.primary.light,
            },
        },
        '&.flipping': {
            [`& .${classes.cardFront}`]: {
                opacity: 0,
                transform: 'translateY(-60px) rotateX(90deg)',
            },
            [`& .${classes.cardBack}`]: {
                opacity: 1,
                transform: 'rotateX(0)',
            },
        },
    },
    card: {
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        display: 'flex',
        transition: 'transform 0.5s ease, opacity 0.8s ease',
        padding: theme.spacing(1),
        backgroundColor: rgba2rgb(theme.custom.productBase[1], theme.custom.accentDark[1], 0.1),
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: theme.spacing(1),
        position: 'relative',
    },
    cardFront: {
        opacity: forcePublishingForm ? '0.5' : '1',
        borderColor: 'transparent',
    },
    cardBack: {
        opacity: 0,
        transform: 'translateY(60px) rotateX(-90deg)',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        borderColor: dragHover ? `${theme.palette.primary.light}` : 'transparent',
    },
    shortName: {
        color: theme.custom.accentLight[0.8],
        textTransform: 'uppercase',
    },
    description: {
        height: '50%',
        color: theme.custom.accentLight[0.85],
        lineHeight: '1.4rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        justifyContent: 'center',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },
    numberOfItems: {
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        position: 'absolute',
    },
}));
