import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = [];

const activeAssetsSlice = createSlice({
    name: 'activeAssets',
    initialState,
    reducers: {
        add: (state, action) => {
            const asset = action.payload;

            const currentAssetIndex = state.findIndex(item => item.id === asset.assetid);

            if (currentAssetIndex !== -1) {
                return state;
            }

            return [
                {
                    id: asset.assetid,
                    asset: asset,
                    duration: Number(asset.assetduration),
                    imgSrc: asset.PosterURL,
                    segments: [],
                    relatedAssets: [],
                    media: {
                        playerId: '',
                        currPosition: 0,
                        duration: Number(asset.assetduration) / 1000,
                        programDateTime: undefined,
                    },
                    metaData: {
                        title: asset.assetmeta.Title,
                    },
                    updateSource: 'click',
                    errors: {
                        missingInOut: null,
                    },
                    dataFeeds: null,
                },
                ...state,
            ];
        },
        update: (state, action) => {
            const currentAssetIndex = state.findIndex(item => item.id === action.payload.id);

            state[currentAssetIndex] = { ...state[currentAssetIndex], ...action.payload.data };
        },
        setMedia: (state, action) => {
            const currentAssetIndex = state.findIndex(item => item.id === action.payload.id);
            const currentMedia = state[currentAssetIndex].media;
            state[currentAssetIndex].media = { ...currentMedia, ...action.payload.media };
        },
        setRelatedAssets: (state, action) => {
            const currentAssetIndex = state.findIndex(item => item.id === action.payload.id);
            state[currentAssetIndex].relatedAssets = action.payload.data;
        },
        setPlayerTime: (state, action) => {
            const currentAssetIndex = state.findIndex(item => item.id === action.payload.id);
            if (currentAssetIndex !== -1) {
                state[currentAssetIndex].media.currPosition = action.payload.time;
                state[currentAssetIndex].media.wasPlaying = action.payload.wasPlaying;
                state[currentAssetIndex].media.trigger = action.payload.trigger;
            }
        },
        setProgramDateTime: (state, action) => {
            const currentAssetIndex = state.findIndex(item => item.id === action.payload.id);
            if (currentAssetIndex !== -1) {
                state[currentAssetIndex].media.programDateTime = action.payload.programDateTime;
            }
        },
        setDataFeeds: (state, action) => {
            const currentAssetIndex = state.findIndex(item => item.id.toString() === action.payload.id.toString());
            if (currentAssetIndex !== -1) {
                state[currentAssetIndex].dataFeeds = action.payload.dataFeeds;
            }
        },
        overwrite: (_, action) => action.payload,
        remove: (state, action) => state.filter(item => item.id !== action.payload.id),
        clear: () => initialState,
    },
});

const getAllActiveAssets = state => state.activeAssets;
const getCurrentPlayerId = id =>
    createSelector(getAllActiveAssets, activeAssets => activeAssets?.find(item => item.id === id)?.media?.playerId);
const getAssetById = id =>
    createSelector(getAllActiveAssets, activeAssets => activeAssets.find(item => item.id === id));
const getAssetMediaById = id =>
    createSelector(getAllActiveAssets, activeAssets => activeAssets.find(item => item.id === id)?.media);

export default {
    ...activeAssetsSlice,
    selectors: { getAllActiveAssets, getAssetById, getAssetMediaById, getCurrentPlayerId },
};
