import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { CircleLoading } from '../Loading';
import SearchableDropDown from '../SearchableDropDown/SearchableDropDown';
import { DEFAULT_ORDER_NUM } from './constants';
import DynamicClearFilters from './DynamicClearFilters';
import DynamicDateRangeFilter from './DynamicDateRangeFilter';
import DynamicFilterList from './DynamicFilterList';
import DynamicFiltersContext from './DynamicFiltersContext';
import { convertDynamicFilterData, formatFilterData, sendSignalAboutChanges, sortByProperty } from './utils';

const DynamicFiltersWrapper = props => {
    const {
        metaKeys,
        initValues,
        setMetaKeys,
        setDateRange,
        dateRange,
        eventFilters,
        filtersChangedByUser,
        setFiltersChangedByUser,
        isLoading,
        selectedMetaKeys,
        classes: { useDynamicFiltersStyles },
        getMetaKeys,
        getMetaValuesById,
        getMetaAggregates,
        setIsLoading,
        onChange,
        onError,
    } = useContext(DynamicFiltersContext);

    const [isKeysLoading, setIsKeysLoading] = useState(false);

    const { classes: dynamicFiltersStyles } = useDynamicFiltersStyles();
    const isEvents = !!eventFilters?.length;

    useEffect(() => {
        const fetchMetaKeys = async () => {
            try {
                setIsKeysLoading(true);
                const preSetValues = initValues.reduce(
                    (result, value) => ({
                        ...result,
                        [value.id]: {
                            value: value.value,
                            options: value.options,
                            order: value.order,
                        },
                    }),
                    {}
                );

                let keys;

                if (isEvents) {
                    keys = eventFilters.map(({ id, value }) => ({
                        id,
                        checked: !!preSetValues[id],
                        value,
                        options: preSetValues[id]?.options || [],
                        order: preSetValues[id]?.order ?? DEFAULT_ORDER_NUM,
                        visible: true,
                    }));
                } else {
                    getMetaKeys &&
                        (await getMetaKeys(result => {
                            keys = result.map(key => ({
                                ...key,
                                checked: !!preSetValues[key.id],
                                value: key.id,
                                options: preSetValues[key.id]?.options || [],
                                order: preSetValues[key.id]?.order ?? DEFAULT_ORDER_NUM,
                                visible: true,
                            }));
                        }));
                }

                keys = sortByProperty(keys, 'value');
                setIsKeysLoading(false);

                return setMetaKeys(keys);
            } catch (e) {
                setIsKeysLoading(false);
                console.error('Error / Fetch MetaKeys:', e);
            }
        };

        if (metaKeys.length === 0) fetchMetaKeys();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setMetaKeys, eventFilters, isEvents, initValues]);

    // restore date range if any set
    useEffect(() => {
        const initDateRange = initValues.find(value => value.type === 'daterange');

        if (initDateRange) {
            const startDate = new Date(initDateRange.value[0]);
            const endDate = new Date(initDateRange.value[1]);
            setDateRange([
                {
                    startDate,
                    endDate,
                },
            ]);
        }
    }, [initValues, setDateRange]);

    const fetchMetaOptions = async metaKeyId => {
        try {
            let metaOptions;
            const { additionalFilters } = props;
            const filterMetaKeys = metaKeys.filter(key => key.id !== metaKeyId);

            const convertedFilters = convertDynamicFilterData(formatFilterData(filterMetaKeys, dateRange));
            const filters = [...convertedFilters, ...additionalFilters];

            setIsLoading(true);

            await getMetaValuesById({ metaKeyId, filters }, result => {
                if (!isEvents && Object.keys(result.aggregates[metaKeyId]).length === 0) {
                    onError('No assets found for provided search query.');
                }
                metaOptions = result;
            });

            return metaOptions;
        } catch (e) {
            onError('No results for provided search query.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAndSetMetaOptions = async (id, newStatus) => {
        const metaDetails = newStatus ? await fetchMetaOptions(id) : null;

        const fetchedMetaDetails = isEvents ? metaDetails?.aggregations[id] : metaDetails?.aggregates[id];

        const metaDetailsObject = fetchedMetaDetails || {};

        const { options: prevOptions } = metaKeys.find(metaKey => metaKey.id === id);

        const finalMetaDetails = Object.keys(metaDetailsObject)
            .filter(metaDetailsObjectKey => metaDetailsObjectKey !== '')
            .map(metaDetailsObjectItem => {
                const prevValue = (prevOptions || []).find(prevOption => metaDetailsObjectItem === prevOption.id);

                return {
                    id: metaDetailsObjectItem,
                    value: `${metaDetailsObjectItem} (${fetchedMetaDetails[metaDetailsObjectItem]})`,
                    label: metaDetailsObjectItem,
                    checked: prevValue?.checked || false,
                };
            });

        const lastItemOrder = selectedMetaKeys.pop()?.order ?? 0;

        const newMetaKeys = metaKeys.map(metaKeyItem => {
            if (metaKeyItem.id === id) {
                return {
                    ...metaKeyItem,
                    checked: newStatus,
                    options: !finalMetaDetails.length ? null : sortByProperty(finalMetaDetails, 'value'),
                    order: metaKeyItem.order !== DEFAULT_ORDER_NUM ? metaKeyItem.order : lastItemOrder + 1,
                };
            }

            return metaKeyItem;
        });

        setMetaKeys(newMetaKeys);
        if (!filtersChangedByUser) setFiltersChangedByUser(true);

        return newMetaKeys;
    };

    const fetchFilterAggregates = async newMetaKeys => {
        try {
            setIsLoading(true);

            const metaKeyIds = metaKeys.map(metaKey => metaKey.id);
            const convertedFilters = convertDynamicFilterData(formatFilterData(selectedMetaKeys, dateRange));

            await getMetaAggregates({ metaKeys: metaKeyIds, filters: convertedFilters }, metaAggregates => {
                //Get Meta aggregates that have data
                const metaAggregatesWithData = Object.keys(metaAggregates).filter(
                    metaAggregatesKey => Object.keys(metaAggregates[metaAggregatesKey]).length > 0
                );

                //Set Meta key visible as false if no aggregate
                setMetaKeys(
                    (newMetaKeys || metaKeys).map(metaKey => ({
                        ...metaKey,
                        visible: metaAggregatesWithData.includes(metaKey.id) ? true : false,
                    }))
                );
            });
        } catch (e) {
            onError('No results for provided search query.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnChangeMetaKeyListItem = async (id, newStatus, closeList) => {
        const newMetaKeys = await fetchAndSetMetaOptions(id, newStatus);

        if (!newStatus) {
            sendSignalAboutChanges(newMetaKeys, dateRange, onChange);
        }
        closeList();
    };

    return (
        <Box className={dynamicFiltersStyles.wrapper} sx={props.sx}>
            <DynamicDateRangeFilter
                filter={{
                    label: 'Date',
                    type: 'daterange',
                    filterKey: 'daterange',
                    value: '',
                }}
            />
            <DynamicFilterList onDropdownOpen={fetchAndSetMetaOptions} />

            {isKeysLoading && !!initValues.length && <CircleLoading height={20} size={20} />}

            <SearchableDropDown
                handleOnChange={handleOnChangeMetaKeyListItem}
                isLoading={isLoading}
                listData={metaKeys}
                listLabel={'Add a filter'}
                onOpen={fetchFilterAggregates}
            />

            <DynamicClearFilters />
        </Box>
    );
};

DynamicFiltersWrapper.propTypes = {
    sx: PropTypes.object,
    additionalFilters: PropTypes.array,
};

DynamicFiltersWrapper.defaultProps = {
    sx: null,
    additionalFilters: [],
};

export default DynamicFiltersWrapper;
