import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ApplicationLoading } from '@mediabank/uikit-v5';
import { safeParseJSON } from '@mediabank/utils';

import useAuth from '../../hooks/useAuth';
import configSlice from '../../store/config';
import { setUi } from '../../store/ui';

const {
    selectors: { getConfig },
} = configSlice;

const LandingPage = () => {
    const [url, setUrl] = useState(null);
    const authenticated = useAuth();

    const dispatch = useDispatch();

    const { DefaultApplication, ReactWorkspaces } = useSelector(getConfig) || {};

    const redirect = useCallback(
        (to, params) => {
            dispatch(setUi({ dashboardSlug: to, params }));
        },
        [dispatch]
    );

    useEffect(() => {
        const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

        try {
            if (typeof DefaultApplication !== 'undefined' && ReactWorkspaces !== '' && !url) {
                const view = safeParseJSON(ReactWorkspaces).find(
                    workspace => workspace.name.toLowerCase() === DefaultApplication.landingView?.library
                );
                const redirectTo = `/dashboard/${view?.path || ''}`;
                // We can check view.panelLabels here too, since that is an array by default, but we used this method before, so sticking with this one for now.
                const currentSearchMode = redirectTo && view ? view?.path.split(',')?.[0] : 'results'; // Setting 'results' as default but this should be confirmed.

                queryParams.currentSearchMode = currentSearchMode;
                redirect(redirectTo, { ...queryParams });

                const urlParams = new URLSearchParams(queryParams);
                let url = !!urlParams.toString().length ? `${redirectTo}?${urlParams.toString()}` : redirectTo;

                setUrl(url);
            }
        } catch (e) {
            redirect('/dashboard', { ...queryParams });
            setUrl('/dashboard');
        }
    }, [DefaultApplication, url, ReactWorkspaces, redirect]);

    const redirectUrl = authenticated ? url : '/login';

    if (!redirectUrl) return <ApplicationLoading />;

    return <Redirect to={redirectUrl} />;
};

export default LandingPage;
