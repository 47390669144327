import { createSlice } from '@reduxjs/toolkit';

import { defaultFilters } from '../../components/AdvancedSearch/constants';
import { selectAdvancedFilterIds } from '../../components/AdvancedSearch/selectors';
import { getPeriodType, isPeriodType } from '../../components/AdvancedSearch/utils';
import { defaultEmptyFilters } from './utils';

export const defaultSearch = {
    id: null,
    matchType: 'all', // all, any
    filters: defaultEmptyFilters, // [{ id: 0, key: "ContentType", verb: "equal", value: "Interview" }]
    createdPeriod: {
        enabled: false,
        type: 'hours', // [hours, days, weeks, months]
        value: null,
        invalid: false,
    },
    createdFilter: {
        enabled: false,
        type: 'before', // ["within", "before", "after"]
        value: null, // format: yyyy-mm-dd hh:mm
    },
    createdEnabled: false,
    name: '',
    permissionType: 'private',
    allowedGroups: [],
    quickfilter: false,
    saved: false,
    showSavePanel: false,
    editable: false,
    page: 1,
};

const metaKeys = {
    hasAll: false,
    loading: false,
    data: null,
};

export const initialState = {
    ...defaultSearch,
    keyword: '',
    loading: false,
    updating: [],
    metaKeys,
    smartSearches: [],
    smartSearchTerm: '',
    defaultInProgress: false,
    smartSearchResultInitial: null,
};

const advancedSearch = createSlice({
    name: '@@advancedSearch',
    initialState,
    reducers: {
        fetchMetaKeys: state => {
            state.metaKeys.loading = true;
        },
        fetchMetaKeysSuccess: (state, { payload: { data, getAll } }) => {
            state.metaKeys.hasAll = getAll;
            state.metaKeys.data = data;
            state.metaKeys.loading = false;
        },
        setId: (state, action) => {
            state.id = parseInt(action.payload, 10);
        },
        setCreatedEnabled: (state, action) => {
            const isPeriod = isPeriodType(state.createdFilter.type);
            state.createdEnabled = action.payload;
            state.createdPeriod.enabled = action.payload && isPeriod;
            state.createdFilter.enabled = action.payload && !isPeriod;
        },
        setCreatedPeriod: (state, action) => {
            state.createdFilter.enabled = false;
            state.createdFilter.type = getPeriodType();
            state.createdPeriod = { ...state.createdPeriod, ...action.payload };
        },
        setCreatedFilter: (state, action) => {
            state.createdPeriod.enabled = false;
            state.createdFilter = { ...state.createdFilter, ...action.payload };
        },
        addOrUpdateFilter: (state, action) => {
            const filter = action.payload;
            const filterIds = selectAdvancedFilterIds({ advancedSearch: state });
            if (!filterIds.includes(filter.id)) {
                filter.id = Math.max(...filterIds) + 1;
                state.filters.push(filter);
            } else {
                state.filters[filterIds.indexOf(filter.id)] = filter;
            }
        },
        removeFilter: (state, action) => {
            state.filters = state.filters.filter(filter => filter.id !== action.payload.id);
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setMatchType: (state, action) => {
            state.matchType = action.payload;
            state.filters = defaultFilters[action.payload] || [];
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setPermissionType: (state, action) => {
            state.permissionType = action.payload;
        },
        setEditable: (state, action) => {
            state.editable = action.payload;
        },
        setAllowedGroups: (state, action) => {
            state.allowedGroups = action.payload;
        },
        setQuickFilter: (state, action) => {
            state.quickfilter = action.payload;
        },
        loadSmartSearch: (state, action) => ({ ...state, ...action.payload, id: parseInt(action.payload.id, 10) }),
        resetSearch: state => ({
            ...state,
            ...defaultSearch,
        }),
        setDefaultSearch: (state, { payload }) => {
            state.defaultInProgress = payload;
        },
        toggleSavePanel: (state, action) => {
            state.showSavePanel = action.payload;
        },
        setSmartSearches: (state, { payload }) => {
            state.smartSearches = payload;
            state.loading = false;
            state.defaultInProgress = false;
        },
        setSmartSearchTerm: (state, { payload }) => {
            state.smartSearchTerm = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        addToUpdating: (state, { payload }) => {
            state.updating = [...state.updating, payload.id];
        },
        removeFromUpdating: (state, { payload }) => {
            state.updating = state.updating.filter(id => id !== payload.id);
        },
        setKeyword: (state, { payload }) => {
            state.keyword = payload;
        },
        removeSmartSearch: (state, { payload }) => {
            state.smartSearches = state.smartSearches.filter(({ id }) => payload !== id);
        },
        modifySmartSearch: (state, { payload }) => {
            state.smartSearches = state.smartSearches.map(search => ({
                ...search,
                ...(parseInt(search.id, 10) === parseInt(payload.id, 10) ? payload : {}),
            }));
        },
        setSmartSearchResult: (state, { payload }) => {
            state.smartSearchResultInitial = payload;
        },
    },
});

export default advancedSearch;
