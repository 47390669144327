import React from 'react';
import { PaginationItem } from '@mui/material';
import PropTypes from 'prop-types';

import NextItem from './NextItem';
import PrevItem from './PrevItem';

const PageItem = props => {
    const { showSlimPager, ...rest } = props;
    const { type } = props;

    let Component;
    const isCustomType = ['previous', 'next'].includes(type);

    switch (type) {
        case 'previous':
            Component = PrevItem;
            break;

        case 'next':
            Component = NextItem;
            break;

        default:
            Component = PaginationItem;
            break;
    }

    return showSlimPager && type !== 'previous' && type !== 'next' ? (
        <></>
    ) : isCustomType ? (
        <Component disableRipple={true} showSlimPager={showSlimPager} {...rest} />
    ) : (
        <Component disableRipple={true} {...rest} />
    );
};

PageItem.propTypes = {
    showSlimPager: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

PageItem.defaultProps = {
    showSlimPager: false,
};

export default PageItem;
