import React from 'react';
import { Box } from '@development-nl/components-library';
import { ReactComponent as ChevronDownIcon } from '@mediabank/assets/icons/chevron-down.svg';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useDefaultStyles = makeStyles()(theme => ({
    chevronContainer: {
        display: 'flex',
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: theme.palette.background.paper,
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            width: 12,
            fill: theme.palette.text.primary,
        },
    },
    openedChevronContainer: {
        backgroundColor: theme.palette.border.accent,
        transform: 'rotate(180deg)',
        svg: {
            fill: theme.palette.common.white,
        },
    },
}));

const ChevronToggleButton = ({ isOpened, sx, onClick }) => {
    const { classes, cx } = useDefaultStyles();

    return (
        <Box
            className={cx(classes.chevronContainer, isOpened && classes.openedChevronContainer)}
            sx={sx}
            onClick={e => onClick(e)}
        >
            <ChevronDownIcon />
        </Box>
    );
};

ChevronToggleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    sx: PropTypes.object,
};

ChevronToggleButton.defaultProps = {
    sx: {},
};

export default ChevronToggleButton;
