import { mediabankApi as api, mediabankHeaders } from '..';

const getByEventId = async eventId => {
    const [year, month, day, label] = eventId.split('-');
    const response = await api.get(`/calendars/${year}/${month}/${day}/${label}`, {
        headers: mediabankHeaders(),
    });

    const {
        data: { included },
    } = response;

    return included;
};

export { getByEventId };
