import { combineEpics, ofType } from 'redux-observable';
import { concat, from, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { assetsService } from '../../services';
import activeAssetsSlice from './activeAssets';
import assetSlice from './asset';
import { GET_ASSET_DATA } from './constants';

const { add, update } = activeAssetsSlice.actions;
const { addAsset, endCall, startCall } = assetSlice.actions;

const { getAllActiveAssets } = activeAssetsSlice.selectors;

const updateAsset = action$ =>
    action$.pipe(
        ofType('asset/update'),
        map(({ payload }) => update({ id: payload.assetId, data: { asset: payload.asset } }))
    );

export const getAssetDataEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(GET_ASSET_DATA),
        withLatestFrom(state$),
        distinctUntilChanged(([prevAction], [nextAction]) => prevAction.payload === nextAction.payload), //To avoid repetitive api call for same assetid
        filter(([action, state]) => !getAllActiveAssets(state).some(item => item.id === action.payload)), //Don't run if asset is already in active assets
        switchMap(([action]) =>
            concat(
                of(startCall()),
                from(assetsService.getAsset({ id: action.payload })).pipe(
                    mergeMap(({ data }) => {
                        if (!data) {
                            return concat(of(endCall()), throwError());
                        }

                        return concat(of(add(data.data.attributes)), of(addAsset(data.data.attributes)), of(endCall()));
                    }),
                    catchError(() =>
                        concat(
                            of(endCall()),
                            of(
                                addToastError({
                                    title: 'Asset fetch error',
                                })
                            )
                        )
                    )
                )
            )
        )
    );

export default combineEpics(getAssetDataEpic, updateAsset);
