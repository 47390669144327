import { smartSearchService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

import { translateResponse } from '../advancedSearch/utils';

const initialState = {
    loading: false,
    error: null,
    entities: null,
};

const quickFilterSlice = createSlice({
    name: 'quickfilter',
    initialState,
    reducers: {
        fetchStart: state => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.entities = action.payload;
        },
        fetchError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        removeOne: (state, action) => {
            state.loading = false;
            state.entities = state.entities.filter(e => e.id !== action.payload.id);
        },
        setEntities: (state, action) => {
            state.entities = [...action.payload];
        },
        clear: () => initialState,
    },
});

const { fetchStart, fetchError, fetchSuccess } = quickFilterSlice.actions;

const getQuickfilter = state => state.quickfilter;

export default { ...quickFilterSlice, selectors: { getQuickfilter } };

export const actions = quickFilterSlice.actions;

export const fetchAll = ({ onSuccess, onFail }) => async dispatch => {
    dispatch(fetchStart());
    try {
        const { data } = await smartSearchService.getAllQuickfilters();
        if (data) {
            const entitiesTranslated = data.data.map(e => translateResponse(e));

            dispatch(fetchSuccess(entitiesTranslated));
            onSuccess && onSuccess(entitiesTranslated);
        } else {
            throw Error('Fetch error.');
        }
    } catch (e) {
        dispatch(fetchError(e.message));
        onFail && onFail();
    }
};
