import React, { forwardRef, useEffect, useState } from 'react';
import AudioIcon from '@mediabank/assets/icons/volume-up-solid.svg';
import { assetsGenerateUrl } from '@mediabank/client';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { OverlayBox } from '../Thumbnail';

const useDefaultStyles = makeStyles()(theme => ({
    element: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: theme.custom.accentDark[0.9],
    },
    audio: {
        backgroundColor: theme.custom.accentDark[0.9],
        background: `url(${AudioIcon}) center center no-repeat`,
        backgroundSize: '10%',
    },
}));

const VideoOverlay = forwardRef(({ ...props }, ref) => {
    const { classes, cx } = useDefaultStyles();
    const [source, setSource] = useState(null);

    const { progressiveUrl, className, isPlaying, onStop, currTime, isAudio, setCurrTime } = props;
    const previewLength = 7;
    const [previewEndAt] = useState(currTime + previewLength);
    const Element = isAudio ? 'audio' : 'video';

    let { current: video } = ref;

    useEffect(() => {
        let isSubscribed = true;

        if (isSubscribed) {
            const getUrl = async () => setSource(await assetsGenerateUrl({ progressiveUrl }));
            getUrl();
        }

        return () => (isSubscribed = false);
    }, [progressiveUrl]);

    useEffect(() => {
        if (video) {
            !isPlaying && video.play();
            video.addEventListener('loadeddata', () => {
                video.currentTime = currTime;
            });
        }
    }, [isPlaying, video, onStop, currTime, setCurrTime, previewEndAt]);

    useEffect(() => {
        if (isPlaying && video) {
            video.addEventListener('timeupdate', () => {
                setCurrTime(parseInt(video.currentTime, 10));
                if (video.currentTime >= previewEndAt || video.currentTime >= video.duration) {
                    video.pause();
                    onStop();
                }
            });
        }
    }, [video, onStop, setCurrTime, previewEndAt, isPlaying]);

    return (
        <OverlayBox>
            <Element
                ref={ref}
                autoPlay={true}
                className={cx(classes.element, { [classes.audio]: isAudio }, className)}
                controls={false}
                playinline="true"
                preload="metadata"
                src={source}
            />
        </OverlayBox>
    );
});

VideoOverlay.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    progressiveUrl: PropTypes.string.isRequired,
    className: PropTypes.string,
    preload: PropTypes.oneOf(['none', 'auto', 'metadata']),
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    isPlaying: PropTypes.bool,
    currTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onPlay: PropTypes.func.isRequired,
    onStop: PropTypes.func.isRequired,
    isAudio: PropTypes.bool.isRequired,
    setCurrTime: PropTypes.func.isRequired,
};

VideoOverlay.defaultProps = {
    className: '',
    preload: 'auto',
    controls: false,
    autoPlay: false,
    isPlaying: false,
    currTime: 0,
};

export default VideoOverlay;
