import React from 'react';
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useDefaultStyles = makeStyles()(theme => ({
    root: {
        padding: theme.spacing(0.65, 1.75),
        backgroundColor: theme.custom.accentDark[0.3],
        borderRadius: theme.spacing(2),
        '&:hover': {
            backgroundColor: theme.custom.accentDark[0.2],
        },
        '&:disabled': {},
    },
    iconLabel: {
        minWidth: '5rem',
        minHeight: '5rem',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    textLabel: {
        position: 'absolute',
        bottom: theme.spacing(-1.5),
        display: 'inline-block',
        color: 'inherit',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'inherit',
        '&:hover': {},
    },
}));

const Lozenge = React.forwardRef(({ onClick, children, label, disabled, variant, className, ...forwardProps }, ref) => {
    const { classes, cx } = useDefaultStyles();
    const isIconLabel = variant === 'iconLabel';

    return (
        <ButtonBase
            ref={ref}
            className={cx(classes.root, isIconLabel && classes.iconLabel, className)}
            disabled={disabled}
            onClick={onClick}
            {...forwardProps}
        >
            {isIconLabel ? (
                <>
                    {children}
                    <div className={classes.textLabel}>
                        <span>{label}</span>
                    </div>
                </>
            ) : (
                <span>{label}</span>
            )}
        </ButtonBase>
    );
});

Lozenge.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.node,
    variant: PropTypes.oneOf(['iconLabel', 'text']),
    className: PropTypes.string,
};

Lozenge.defaultProps = {
    onClick: () => null,
    disabled: false,
    variant: 'iconLabel',
    className: '',
    children: null,
};

export default Lozenge;
