import { tagsService } from '@mediabank/client';

import { assetListMapper } from '../../store/assets/assetSearchResult';

const getFormattedEventType = eventType => eventType.replace(/_/g, ' ');

const getAssetsForEvents = async items => {
    const getEventsInfo = events =>
        events.map((event, i) => ({
            index: i,
            happeningId: event.happening_id,
            // Uncomment the next line to test error handling and toaster here
            // part: event.part,
            part: event.part ? event.part : 1,
        }));

    const isEvent = items.every(item => item.hasOwnProperty('asset_id'));

    if (isEvent) {
        const events = [...items];

        let data = [];

        try {
            const response = await tagsService.getAssets({ eventsInfo: getEventsInfo(events) });
            data = response.data.data;
        } catch (e) {
            const errorResponse = e.response;

            const error = errorResponse.data.errors[0];
            const validationErrors = error?.meta?.validation_errors;

            if (!validationErrors) {
                throw new Error(error.detail);
            }

            const eventsWithErrorIndices = validationErrors
                ?.map(errorObj => {
                    const regex = /\d+/g;
                    const match = errorObj.key.match(regex)?.[0];

                    return match;
                })
                .sort((a, b) => a - b);

            const eventsCopy = [...items];

            for (let i = eventsWithErrorIndices.length - 1; i >= 0; i--) {
                eventsCopy.splice(eventsWithErrorIndices[i], 1);
            }

            const response = await tagsService.getAssets({ eventsInfo: getEventsInfo(eventsCopy) });
            data = response.data.data;
        }

        const eventsWithAssetData = events.map(event => {
            const relatedAsset =
                data.length > 0 &&
                data?.find(data => parseInt(data?.happeningId, 10) === event.happening_id)?.relationships.asset.data;

            if (!relatedAsset) {
                return event;
            } else {
                const { event_id, event_timestamp, pre_roll, post_roll, happening_id, part } = event;
                const markIn = event_timestamp - pre_roll;
                const markOut = event_timestamp + post_roll;
                const playerStartTimeStamp = relatedAsset.attributes.assetmeta.PlayerStartTimestamp;

                const mappedAsset = assetListMapper([relatedAsset])[0];

                const assetId = parseInt(relatedAsset.id, 10);
                const assetData = {
                    ...mappedAsset,
                    eventId: event_id,
                    happeningId: happening_id,
                    markOut,
                    markIn,
                    part,
                    playerStartTimeStamp,
                };

                return { ...event, asset_id: assetId, assetData };
            }
        });

        const eventsWithoutAssetId = eventsWithAssetData.filter(event => !event.asset_id);
        const eventsWithAssetId = eventsWithAssetData.filter(event => event.asset_id);

        const assetsWithEventId = eventsWithAssetId.map(({ assetData, ...rest }) => ({
            eventId: rest.eventId,
            ...assetData,
            uniqueId: `${assetData.id}${assetData.eventId}${assetData.happeningId}${assetData.part}${assetData.metaData?.title}${assetData.duration}${assetData.markIn}${assetData.markOut}`,
        }));

        return { assetsWithEventId, eventsWithoutAssetId };
    } else {
        const assetsWithEventId = items.map(item => ({
            ...item,
            uniqueId: `${item.id}${item.metaData?.title}${item.duration}`,
        }));

        return { assetsWithEventId };
    }
};

const getAdditionalFiltersByFreetext = freetext =>
    !!freetext ? [{ id: 'freetext', type: 'freetext', value: freetext || '' }] : [];

export { getFormattedEventType, getAssetsForEvents, getAdditionalFiltersByFreetext };
