import { tagListsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    requesting: false,
    error: null,
    data: [],
    defaultlist: null,
};

const tagListsSlice = createSlice({
    name: 'tagLists',
    initialState,
    reducers: {
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        requestError: (state, action) => {
            state.requesting = false;
            state.error = action.payload;
            state.data = [];
            state.defaultlist = null;
        },
        requestSuccess: (state, action) => {
            state.requesting = false;
            state.error = null;
            state.data = action.payload?.data || [];
            state.defaultlist = action.payload?.meta?.defaultlist || null;
        },
    },
});

const { requestStart, requestError, requestSuccess } = tagListsSlice.actions;

const fetchTagLists = id => async dispatch => {
    dispatch(requestStart());

    try {
        const response = await tagListsService.getTagLists(id);

        if (response?.status === 200) {
            dispatch(requestSuccess(response.data));
        } else {
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestError(error.message));
    }
};

const isRequesting = state => state.tagLists.requesting;
const tagLists = state => state.tagLists.data;
const defaultlist = state => state.tagLists.defaultlist;

export default {
    ...tagListsSlice,
    fetchTagLists,
    selectors: { isRequesting, tagLists, defaultlist },
};
