import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'inline-block',
        padding: theme.spacing(0.15, 0.5),
        margin: theme.spacing(0.125),
        borderRadius: theme.spacing(0.5),
        color: theme.palette.common.black,
        fontSize: '1.2rem',
        lineHeight: 1,
        letterSpacing: '0.55px',
    },
    red: {
        backgroundColor: theme.custom.sysRed[1],
        color: theme.palette.common.white,
    },
    black: {
        color: theme.palette.common.white,
        backgroundColor: theme.custom.accentDark[0.7],
    },
    large: {
        borderRadius: '0',
        padding: theme.spacing(1, 1.5),
    },
    tiny: {
        fontSize: '1rem',
    },
}));

const Label = ({ children, size, look, className }) => {
    const { classes, cx } = useStyles();

    return (
        <Typography
            className={cx(classes.root, classes[size], classes[look], className)}
            component="span"
            variant="body2"
        >
            {children}
        </Typography>
    );
};

Label.propTypes = {
    size: PropTypes.oneOf(['tiny', 'small', 'large']),
    look: PropTypes.oneOf(['red', 'black']),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    className: PropTypes.string,
};

Label.defaultProps = {
    look: 'red',
    size: 'small',
    className: '',
};

export default Label;
