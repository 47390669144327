export default (theme, colors) => ({
    root: {
        width: '2rem',
        height: '2rem',
        fontSize: '2rem',
        position: 'relative',
        marginRight: theme.spacing(1),
        zIndex: 1,
        color: colors.accentLight[0.75],
        // TODO does this have an effect?
        '&:hover': {
            backgroundColor: 'initial',
        },
        '&:focus': {
            color: colors.focused[0.5],
        },
        '&.Mui-checked': {
            // TODO does this have an effect?
            '&:hover': {
                backgroundColor: 'initial',
            },
            color: colors.accentBright[1],
            '&:focus': {
                color: colors.focused[0.5],
            },
        },
        '&.Mui-disabled': {
            color: colors.accentLight[0.25],
        },
    },
});
