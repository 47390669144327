import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@development-nl/components-library';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { usePanelTabStyles, useTooltipStyles } from './styles';

const PanelTab = ({ label, onClick, selected, variant, breadcrumbs, className, buttonLabelClass }) => {
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);
    const { classes, cx } = usePanelTabStyles();
    const { classes: tooltipClasses } = useTooltipStyles();
    const buttonClassName = cx(classes.generalBtnStyle, classes[variant], className, {
        [classes[`${variant}Selected`]]: selected,
    });
    const buttonRef = useRef(null);

    useEffect(() => {
        if (buttonRef?.current?.offsetWidth < buttonRef?.current?.scrollWidth) {
            setIsEllipsisActive(true);
        }
    }, [buttonRef, label, buttonRef?.current?.offsetWidth]);

    const tabButton = (
        <Button
            ref={buttonRef}
            classes={{ label: buttonLabelClass }}
            className={buttonClassName}
            data-cy={`paneltabButton${selected ? 'Selected' : ''}`}
            disableRipple={true}
            variant="text"
            onClick={onClick}
        >
            {label}
            {variant === 'level1' && <div className={classes.bottomLine} />}
        </Button>
    );

    return (
        <>
            {isEllipsisActive ? (
                <Tooltip classes={tooltipClasses} enterDelay={500} placement="bottom" title={label}>
                    {tabButton}
                </Tooltip>
            ) : (
                <>{tabButton}</>
            )}
            {breadcrumbs && (
                <Box className={classes.breadcrumbsContainer} data-cy="breadcrumbs">
                    {breadcrumbs}
                </Box>
            )}
        </>
    );
};

PanelTab.propTypes = {
    breadcrumbs: PropTypes.element,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    variant: PropTypes.oneOf(['level1', 'level2']),
    className: PropTypes.string,
    buttonLabelClass: PropTypes.string,
};

PanelTab.defaultProps = {
    breadcrumbs: null,
    onClick: undefined,
    selected: false,
    variant: 'level1',
    className: '',
    buttonLabelClass: '',
};

export default PanelTab;
