import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Centered } from '@mediabank/uikit-v5';
import { Typography } from '@mui/material';

import LandingPage from '../components/LandingPage';
import Login from '../components/Login';
import Logout from '../components/Logout';
import ProtectedRoute from './ProtectedRoute';

const ConnectedFramework = lazy(() =>
    import('../framework/ConnectedFramework').then(module => ({ default: module.ConnectedFramework }))
);

export const NoMatch = () => (
    <Centered>
        <Typography color="textPrimary" variant="h5">
            404: Page not found
        </Typography>
    </Centered>
);

const AppRoutes = () => (
    <Switch>
        <Route component={Login} path="/login" />
        <Route component={Logout} path="/logout" />

        <Route component={LandingPage} exact={true} path="/" />

        <Route exact={true} path="/dashboard">
            <Redirect to="/dashboard/results" />
        </Route>

        <ProtectedRoute component={props => <ConnectedFramework {...props} />} path={'/dashboard/:dashboardSlug'} />

        <ProtectedRoute component={NoMatch} />
    </Switch>
);
export default AppRoutes;
