import { makeStyles } from 'tss-react/mui';

export const useInputStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.custom.accentDark[0.6],
        borderRadius: theme.spacing(3),
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        padding: theme.spacing(0.25, 0.75, 0.25, 1),
        fontSize: '1.4rem',
        margin: 0,
        '&:hover': {
            borderColor: theme.custom.accentLight[0.2],
        },
        '&::placeholder': {
            color: theme.custom.accentLight[0.3],
        },

        // icon buttons
        '& .iconBtn, .cancelBtn': {
            marginRight: theme.spacing(1.75),
            fill: theme.custom.accentLight[0.3],
            cursor: 'pointer',
        },
        '& .iconBtn.active, .cancelBtn.active': {
            fill: theme.custom.accentMedium[0.8],
        },
        '& .iconBtn.active:hover, .cancelBtn.active:hover': {
            fill: theme.custom.accentMedium[1],
        },
        '& .focused .iconBtn, .focused .cancelBtn': {
            fill: theme.custom.accentLight[0.3],
        },
        '& .iconBtn:hover, & .focused .iconBtn:hover, .cancelBtn:hover, & .focused .cancelBtn:hover': {
            fill: theme.custom.accentLight[0.5],
        },
        '& .cancelBtn path': {
            stroke: theme.palette.buttonSecondary.text,
        },

        // save button
        '& .textBtn': {
            fontSize: '1.4rem',
            lineHeight: 1,
            backgroundColor: theme.palette.buttonSecondary.background,
            borderRadius: theme.spacing(2),
            padding: theme.spacing(0.5, 1),
            color: theme.palette.buttonSecondary.text,
        },
        '& .focused .textBtn': {
            backgroundColor: theme.custom.accentLight[0.3],
        },
        '& .textBtn:hover, & .focused .textBtn:hover': {
            backgroundColor: theme.custom.accentLight[0.5],
        },
        '& .MuiInputAdornment-positionStart svg': {
            stroke: theme.palette.text.primary,
        },

        // focused state
        '&.Mui-focused': {
            borderColor: theme.palette.action.focus,
            '&:hover': {
                borderColor: theme.palette.action.focus,
            },
        },
        '& input': {
            height: '1em',
            padding: theme.spacing(0.875, 0.25),
            color: theme.custom.accentLight[0.8],
        },
    },
}));
