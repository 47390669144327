import { eventSearchService } from '@mediabank/client';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, from, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { getAssetsForEvents } from '../../components/EventSearchResult/utils';
import {
    SEARCH_PROVIDER_COMBINED_EVENT_SEARCH_SUCCESS,
    SEARCH_PROVIDER_EVENT_SEARCH_SUCCESS,
} from '../../framework/constants';
import { submitEventQuery } from '../../providers/ConnectedSearchProvider/actions';
import { selectEventQuery } from '../../providers/ConnectedSearchProvider/selectors';
import { initPanelEventSearch, relatedEventSearchSuccess, resetRelatedPanelEventsEpicAction } from './actions';
import {
    SEARCH_RESULTS_GET_ASSET_BY_EVENT,
    SEARCH_RESULTS_GET_EVENTS_BY_HAPPENING,
    SEARCH_RESULTS_GET_EVENTS_BY_HAPPENING_SUCCESS,
    SEARCH_RESULTS_INIT_PANEL_EVENT_SEARCH,
    SEARCH_RESULTS_UPDATE_EVENTS_PAGE,
    UPDATE_EVENTS_FILTERS,
    UPDATE_PANEL_EVENTS_FILTERS,
} from './constants';
import eventSearchSlice from './eventSearch';

const {
    fetchSuccess,
    fetchSuccessRelatedEvents,
    // clear: clearEventSearchResults,
    updateAssetsByEvents,
    updatePanelFilters,
    requestStart,
    updateFilters,
    requestFinished,
} = eventSearchSlice.actions;

const updateEventsEpic = action$ =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_EVENT_SEARCH_SUCCESS),
        map(({ payload: { result } }) => fetchSuccess({ searchType: result.searchType, data: result.data }))
    );

const updateEventsByHappeningEpic = action$ =>
    action$.pipe(
        ofType(SEARCH_RESULTS_GET_EVENTS_BY_HAPPENING_SUCCESS),
        map(({ payload: { events } }) => fetchSuccessRelatedEvents({ events }))
    );

const updateCombinedEventsEpic = action$ =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_COMBINED_EVENT_SEARCH_SUCCESS),
        map(({ payload: { result } }) =>
            fetchSuccess({
                searchType: result.searchType,
                combinedData: result.data,
            })
        )
    );

const getEventSearchError = (e, id, addToastError) => {
    if (!e.response) {
        const { status } = e.response;

        return concat(
            of(requestFinished()),
            status !== 404
                ? of(
                      addToastError({
                          title: e.message,
                      })
                  )
                : EMPTY
        );
    }

    const error = e.response.data.errors[0];
    const { status, title, detail } = error;
    const is404 = status === 404;

    const notiTitle = is404 ? title : 'Something went wrong';
    const notiSubtitle = is404
        ? detail
        : `An error occured while fetching the assets for this query (happeningId: ${id})`;

    return concat(
        of(requestFinished()),
        of(
            addToastError({
                title: notiTitle,
                subtitle: notiSubtitle,
            })
        ),
        of(resetRelatedPanelEventsEpicAction())
    );
};

const getDefaultRelatedEvents = ({ action, addToastError }) => {
    const { happeningId } = action.payload;

    return from(eventSearchService.getEventsByHappening({ happeningId })).pipe(
        map(relatedEventSearchSuccess),
        catchError(e => getEventSearchError(e, happeningId, addToastError))
    );
};

export const getFilteredRelatedEvents = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_RESULTS_INIT_PANEL_EVENT_SEARCH),
        debounceTime(200),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            from(
                eventSearchService.getEvents({
                    filters: selectEventQuery(state).panelFilters,
                })
            ).pipe(
                map(relatedEventSearchSuccess),
                catchError(error => getEventSearchError(error, action?.payload?.tagsHappeningId, addToastError))
            )
        )
    );

const updateEventsFiltersEpic = action$ =>
    action$.pipe(
        ofType(UPDATE_EVENTS_FILTERS),
        map(({ payload }) => updateFilters({ filters: payload.filters }))
    );

export const watchForPanelEventFilterChangesEpic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_PANEL_EVENTS_FILTERS),
        withLatestFrom(state$),
        switchMap(([action]) => {
            const { filters, tagsHappeningId } = action.payload;

            return concat(
                of(requestStart()),
                of(updatePanelFilters({ filters })),
                of(initPanelEventSearch({ tagsHappeningId }))
            );
        })
    );

export const updateEventsPageEpic = action$ =>
    action$.pipe(
        ofType(SEARCH_RESULTS_UPDATE_EVENTS_PAGE),
        switchMap(({ payload: { eventResultPage, eventResultLimit } }) =>
            of(submitEventQuery({ eventResultPage, eventResultLimit, searchType: 'events' }))
        )
    );

export const getAssetsByEventsEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_RESULTS_GET_ASSET_BY_EVENT),
        withLatestFrom(state$),
        filter(([action, state]) => !state.eventSearch.assetsByEvents[action.payload.eventId]),
        switchMap(([action]) =>
            from(getAssetsForEvents(action.payload)).pipe(
                map(data => {
                    const { assetsWithEventId, eventsWithoutAssetId } = data;

                    return updateAssetsByEvents({ assetsWithEventId, eventsWithoutAssetId });
                }),
                catchError(() =>
                    of(
                        addToastError({
                            title:
                                'Something went wrong while fetching asset for this event. Please refresh your browser and try again.',
                        })
                    )
                )
            )
        )
    );

export const getEventsByHappeningEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_RESULTS_GET_EVENTS_BY_HAPPENING),
        withLatestFrom(state$),
        debounceTime(200),
        switchMap(([action]) =>
            concat(
                of(requestStart()),
                getDefaultRelatedEvents({
                    action,
                    addToastError,
                })
            )
        )
    );

export default combineEpics(
    getAssetsByEventsEpic,
    getEventsByHappeningEpic,
    getFilteredRelatedEvents,
    updateEventsEpic,
    updateEventsByHappeningEpic,
    updateEventsPageEpic,
    updateEventsFiltersEpic,
    updateCombinedEventsEpic,
    watchForPanelEventFilterChangesEpic
);
