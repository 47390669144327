import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useNotificationStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        backgroundColor: 'transparent',
    },
    warning: {
        color: theme.custom?.sysRed[1],
        border: `1px solid ${theme.custom?.sysRed[1]}`,
    },
    success: {
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
}));

const Notification = ({ warning, success, children, ...rest }) => {
    const { classes, cx } = useNotificationStyles();

    return (
        <Box className={cx(classes.root, { [classes.warning]: warning }, { [classes.success]: success })} {...rest}>
            {children}
        </Box>
    );
};

Notification.propTypes = {
    children: PropTypes.node.isRequired,
    warning: PropTypes.bool,
    success: PropTypes.bool,
};

Notification.defaultProps = {
    warning: true,
    success: false,
};

export default Notification;
