import { assetsService, destinationService, listAllNotificationList } from '@mediabank/client';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { assetListMapper } from '../assets/assetSearchResult';

const iconBase = 'https://www.mediabank.me/icons/mboxlabels/';
const altIconBase = 'https://www.mediabank.me/icons/mboxlabelsleft/';

export const quicklinkMBox = {
    id: 'quicklink',
    shortName: 'Quicklink',
    description: 'Makes your asset/assets available from a link for a limited period of time or clicks.',
    imageUrl: `${iconBase}ql.png`,
    hoverImageUrl: null,
    nonExecutedNum: 0,
    items: [],
};

const initialState = {
    isPanelOpen: false,
    groups: [],
    boxes: [],
    recipient: {
        isLoaded: false,
        list: [],
    },
    errorMsg: null,
    openedBoxId: null,
    preSelectedAssets: [],
};

const mBoxSlice = createSlice({
    name: 'mBox',
    initialState,
    reducers: {
        setPanelOpen: (state, { payload: isOpen }) => {
            state.isPanelOpen = isOpen;
        },
        setSelectedItems: (state, { payload: selectedItems }) => {
            state.selectedItems = selectedItems;
        },
        createGroup: (state, { payload: group }) => {
            state.groups.push(group);
        },
        fetchError: (state, action) => {
            state.errorMsg = action.payload;
        },
        updateGroup: (state, { payload: group }) => {
            state.groups = state.groups.map(g => (g.id === group.id ? group : g));
        },
        deleteGroup: (state, { payload: groupId }) => {
            state.groups = state.groups.filter(g => g.id !== groupId);
        },
        addAssets: (state, { payload: { targetMBoxIds, assets } }) => {
            state.boxes = state.boxes.map(mBox => {
                if (targetMBoxIds.includes(mBox.id)) {
                    // eslint-disable-next-line no-param-reassign
                    mBox.items = [
                        ...mBox.items,
                        ...assets.map(asset => ({
                            ...asset,
                            ...(!asset.uniqueId ? { uniqueId: asset.id } : {}),
                        })),
                    ];
                }

                return mBox;
            });
        },
        addPreSelectedAssets: (state, { payload: { assets } }) => {
            state.preSelectedAssets = assets;
        },
        overwriteAssets: (state, { payload: { targetMBoxIds, assets } }) => {
            state.boxes = state.boxes.map(mBox => {
                if (targetMBoxIds.includes(mBox.id)) {
                    // eslint-disable-next-line no-param-reassign
                    mBox.items = [...assets];
                }

                return mBox;
            });
        },
        setMBoxes: (state, { payload }) => {
            state.boxes = payload;
        },
        removeItemsById: (state, { payload: { id, itemIds } }) => {
            state.boxes = state.boxes.map(mBox => {
                if (id === mBox.id) {
                    // eslint-disable-next-line no-param-reassign
                    mBox.items = mBox.items.filter(item => itemIds.indexOf(item.uniqueId) === -1);
                }

                return mBox;
            });
            state.preSelectedAssets = state.preSelectedAssets.filter(asset => !itemIds.includes(asset.uniqueId));
        },
        updateMBoxExecution: (state, { payload: { targetMBoxId, data } }) => {
            state.boxes = state.boxes.map(mBox => {
                if (targetMBoxId === mBox.id) {
                    // eslint-disable-next-line no-param-reassign
                    mBox.execution = data;
                }

                return mBox;
            });
        },
        setRecipient: (state, { payload }) => {
            state.recipient = payload;
        },
        setOpenedBoxId: (state, { payload }) => {
            state.openedBoxId = payload;
        },
        clear: () => initialState,
    },
});

const getMBoxSlice = state => state.mBox;
const getOpenedBoxId = state => state.mBox.openedBoxId;
const getRecipient = createSelector(getMBoxSlice, substate => substate.recipient);
const getMBoxList = createSelector(getMBoxSlice, substate => substate.boxes);

const parseMBoxes = mBoxesData => {
    const mBoxes = mBoxesData.data;

    return mBoxes.map(mBox => {
        const { id, attributes } = mBox;
        const {
            iconFile,
            title,
            description,
            altIconFile,
            viewRenderPrefixFilename,
            viewRenderStitch,
            viewRenderLogoBurnIn,
            forcePublishingForm,
        } = attributes;

        return {
            id,
            shortName: title,
            description,
            imageUrl: iconFile && iconFile !== '-1' ? `${iconBase}${iconFile}` : '',
            hoverImageUrl: altIconFile && altIconFile !== '-1' && `${altIconBase}${altIconFile}`,
            options: {
                viewRenderPrefixFilename,
                viewRenderStitch,
                viewRenderLogoBurnIn,
                forcePublishingForm,
            },
            nonExecutedNum: 0,
            items: [],
        };
    });
};

export const getMboxes = () => async dispatch => {
    try {
        const { data } = (await destinationService.listAll()) || {};
        if (data) {
            // the quick link mbox is a fixed item, doesn't come from the api
            dispatch(mBoxSlice.actions.setMBoxes([quicklinkMBox, ...parseMBoxes(data)]));
        } else {
            throw Error();
        }
    } catch (e) {
        dispatch(mBoxSlice.actions.fetchError(e.message));
    }
};

export const getMboxUsage = async id => {
    const { data } = (await destinationService.usage(id)) || {};

    return data;
};

export const fetchRecipientList = () => async dispatch => {
    const list = await listAllNotificationList();

    if (list) {
        dispatch(
            mBoxSlice.actions.setRecipient({
                isLoaded: true,
                list,
            })
        );
    }
};

export const addPreSelectedAssets = ({ assetIds, addOnlyVideos = false, onWarningCallback }) => async dispatch => {
    try {
        let selectedAssets = [];

        const {
            data: { data },
        } = await assetsService.getAssetsFromIds({ ids: assetIds.join() });

        if (addOnlyVideos) {
            //Filter out non-video assets
            selectedAssets = data.filter(a => a.attributes.assetmeta.MimeType === 'video');

            //Call function if any assets filtered out
            if (onWarningCallback && data.length !== selectedAssets.length) {
                onWarningCallback();
            }
        } else {
            selectedAssets = data;
        }

        if (selectedAssets) {
            dispatch(
                mBoxSlice.actions.addPreSelectedAssets({
                    assets: assetListMapper(selectedAssets),
                })
            );
        }
    } catch (e) {
        console.log(e);
    }
};

export default { ...mBoxSlice, selectors: { getMBoxSlice, getRecipient, getMBoxList, getOpenedBoxId } };
