import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        '& div': {
            color: theme.palette.text.primary,
            padding: theme.shape.borderRadius,
            paddingLeft: theme.spacing(1.5),
            position: 'absolute',
            margin: 0,
        },
    },
    placeholder: {
        fontSize: '1.2rem',
        padding: theme.spacing(0, 1.5),
        color: theme.custom.accentLight[0.6],
    },
}));

const SelectValueContainer = ({ hasValue, isMulti, selectProps: { placeholder, inputValue }, children }) => {
    const { classes } = useStyles();
    const displayPlaceholder = hasValue && isMulti && !inputValue;

    return (
        <div className={classes.root}>
            {displayPlaceholder
                ? [
                      <Typography key={1} className={classes.placeholder}>
                          {placeholder}
                      </Typography>,
                      children[1],
                  ]
                : children}
        </div>
    );
};

SelectValueContainer.propTypes = {
    children: PropTypes.node.isRequired,
    selectProps: PropTypes.object.isRequired,
    hasValue: PropTypes.bool.isRequired,
    isMulti: PropTypes.bool.isRequired,
};

export default SelectValueContainer;
