import { mediabankApi as api, mediabankHeaders, url } from '..';

const create = async ({ attributes, companyId }) => {
    const createRequest = { data: { type: 'users', attributes: attributes } };
    const createResponse = await api.post(`/admin/companies/${companyId}/users`, {
        data: createRequest,
        headers: mediabankHeaders(),
    });
    if (createResponse.status === 201) {
        // server responds with a 201 and a header `location: /api/v1/admin/companies/321/users/123`
        const companyResponse = await getOne({ path: createResponse.headers.location });
        return companyResponse;
    } else {
        return createResponse; // with error
    }
};

const getOne = async ({ userId, companyId, path }) => {
    const reqUrl = userId && companyId ? `/admin/companies/${companyId}/users/${userId}` : url + path;
    const response = await api.get(reqUrl, { headers: mediabankHeaders() });
    return response;
};

const setPassword = async ({ userId, companyId, password }) => {
    const passwordRequest = {
        data: {
            type: 'newpassword',
            attributes: {
                password: password,
                password2: password,
            },
        },
    };
    return await api.post(`/admin/companies/${companyId}/users/${userId}/password`, {
        data: passwordRequest,
        headers: mediabankHeaders(),
    });
};

const activate = async ({ userId, companyId }) =>
    await api.post(`/admin/companies/${companyId}/users/${userId}/activate`, { headers: mediabankHeaders() });

const addToGroups = async ({ userId, companyId, groupIds }) => {
    const request = {
        data: {
            type: 'users',
            attributes: {
                groups: groupIds,
            },
        },
    };
    return await api.patch(`/admin/companies/${companyId}/users/${userId}`, {
        headers: mediabankHeaders(),
        data: request,
    });
};

export default { create, setPassword, activate, getOne, addToGroups };
