import {
    SEARCH_PROVIDER_ASSET_SEARCH_SUCCESS,
    SEARCH_PROVIDER_COMBINED_ASSET_SEARCH_SUCCESS,
    SEARCH_PROVIDER_COMBINED_EVENT_SEARCH_SUCCESS,
    SEARCH_PROVIDER_EVENT_SEARCH_SUCCESS,
    SEARCH_PROVIDER_POPULATE_ASSETS_WITH_EMPTY_DATA,
    SEARCH_PROVIDER_POPULATE_EVENTS_WITH_EMPTY_DATA,
    SEARCH_PROVIDER_REQUEST_ASSET_DEFAULT,
    SEARCH_PROVIDER_RESET_RESULT,
    SEARCH_PROVIDER_SUBMIT_ASSET_QUERY,
    SEARCH_PROVIDER_SUBMIT_COMBINED_ASSET_QUERY,
    SEARCH_PROVIDER_SUBMIT_COMBINED_EVENT_QUERY,
    SEARCH_PROVIDER_SUBMIT_EVENT_QUERY,
} from '../../framework/constants';
import { SEARCHTYPE } from '../../hooks/useCombinedSearchMode/useCombinedSearchMode';
import {
    SEARCH_PROVIDER_INIT_ASSET_SEARCH,
    SEARCH_PROVIDER_INIT_COMBINED_ASSET_SEARCH,
    SEARCH_PROVIDER_INIT_COMBINED_EVENT_SEARCH,
    SEARCH_PROVIDER_INIT_EVENT_SEARCH,
    SEARCH_PROVIDER_TRIGGER_INIT,
} from './constants';

export const initAssetSearch = () => ({
    type: SEARCH_PROVIDER_INIT_ASSET_SEARCH,
});

export const initCombinedAssetSearch = () => ({
    type: SEARCH_PROVIDER_INIT_COMBINED_ASSET_SEARCH,
});

export const initEventSearch = () => ({
    type: SEARCH_PROVIDER_INIT_EVENT_SEARCH,
});

export const initCombinedEventSearch = () => ({
    type: SEARCH_PROVIDER_INIT_COMBINED_EVENT_SEARCH,
});

export const assetSearchSuccess = result => ({
    type: SEARCH_PROVIDER_ASSET_SEARCH_SUCCESS,
    payload: {
        result: {
            searchType: SEARCHTYPE.assets,
            data: result?.data,
            config: {
                params: {
                    page: result.config.params.page,
                },
            },
        },
    },
});

export const combinedAssetSearchSuccess = result => ({
    type: SEARCH_PROVIDER_COMBINED_ASSET_SEARCH_SUCCESS,
    payload: {
        result: {
            searchType: SEARCHTYPE.all,
            data: result?.data,
        },
    },
});

export const eventSearchSuccess = result => ({
    type: SEARCH_PROVIDER_EVENT_SEARCH_SUCCESS,
    payload: {
        result: {
            searchType: 'events',
            data: result?.data,
        },
        requesting: false,
    },
});

export const combinedEventSearchSuccess = result => ({
    type: SEARCH_PROVIDER_COMBINED_EVENT_SEARCH_SUCCESS,
    payload: {
        result: {
            searchType: SEARCHTYPE.all,
            data: result?.data,
        },
        events: result?.data.data.events,
        requesting: false,
    },
});

export const populateAssetsWithEmptyData = () => ({
    type: SEARCH_PROVIDER_POPULATE_ASSETS_WITH_EMPTY_DATA,
    payload: {
        result: {
            data: [],
            combinedData: [],
            config: {
                params: {
                    page: 1,
                },
            },
        },
    },
});

export const populateEventsWithEmptyData = (payload = { isCombinedMode: false }) => ({
    type: SEARCH_PROVIDER_POPULATE_EVENTS_WITH_EMPTY_DATA,
    payload: { isCombinedMode: payload.isCombinedMode },
});

export const submitAssetQuery = payload => ({
    type: SEARCH_PROVIDER_SUBMIT_ASSET_QUERY,
    payload,
});

export const submitCombinedAssetQuery = payload => ({
    type: SEARCH_PROVIDER_SUBMIT_COMBINED_ASSET_QUERY,
    payload,
});

export const submitEventQuery = payload => ({
    type: SEARCH_PROVIDER_SUBMIT_EVENT_QUERY,
    payload,
});

export const submitCombinedEventQuery = payload => ({
    type: SEARCH_PROVIDER_SUBMIT_COMBINED_EVENT_QUERY,
    payload,
});
export const requestDefault = () => ({
    type: SEARCH_PROVIDER_REQUEST_ASSET_DEFAULT,
});

export const triggerInit = () => ({
    type: SEARCH_PROVIDER_TRIGGER_INIT,
});

export const resetSearchResultEpicAction = () => ({
    type: SEARCH_PROVIDER_RESET_RESULT,
});
