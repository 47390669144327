import React from 'react';
import { ReactComponent as CloseIcon } from '@mediabank/assets/icons/prefilter.svg';
import { rgba2rgb } from '@mediabank/theme-v5';
import { Chip, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import EllipsisText from './EllipsisText';

const inputStyles = makeStyles()(theme => ({
    root: {
        minWidth: 130,
        height: theme.spacing(3),
        minHeight: theme.spacing(3),
        backgroundColor: rgba2rgb(theme.custom.productBase[1], theme.custom.accentDark[1], 0.1),
        borderRadius: theme.shape.borderRadius,
        boxShadow: 'none',
        cursor: 'pointer',
        '&.MuiOutlinedInput-root': {
            '& fieldset': {
                border: `1px solid ${theme.custom.accentLight[0.2]}`,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.custom.accentLight[0.4]}`,
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${theme.custom.focused[0.5]}`,
            },
        },
    },
    disabled: {
        color: theme.custom.accentLight[0.4],
        '&:hover': {
            backgroundColor: 'initial',
        },
    },
    notchedOutline: {},
    input: {
        display: 'flex',
        padding: 0,
        height: '100%',
    },
}));

const chipStyles = makeStyles()(theme => ({
    root: {
        margin: theme.spacing(0, 1, 1, 0),
        fontSize: '1.2rem',
        height: 'initial',
        padding: theme.spacing(0.375, 0.125, 0.375, 0.875),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        border: '1px solid transparent',
        backgroundColor: theme.custom.accentLight[0.4],
        '&:focus': {
            borderColor: theme.custom.focused[0.7],
        },
        '&:hover': {
            backgroundColor: theme.custom.accentLight[0.25],
        },
        '&:active': {
            backgroundColor: theme.custom.accentDark[0.7],
        },
        '&:disabled': {
            color: theme.palette.common.white,
            opacity: 0.15,
        },
    },
    label: {
        padding: 0,
    },
}));

const cancelStyles = makeStyles()(theme => ({
    chipCancel: {
        margin: 0,
        marginLeft: theme.spacing(2),
        height: '1.4rem',
        width: '1.4rem',
        color: theme.custom.accentLight[1],
        background: theme.custom.accentDark[0.2],
        borderRadius: '50%',
    },
}));

const inputComponent = React.forwardRef(function inputComponent(props, ref) {
    return <div ref={ref} {...props} />;
});

const SelectControl = ({
    error,
    children,
    innerProps,
    innerRef,
    isMulti,
    getValue,
    selectOption,
    selectProps: { name },
}) => {
    const { classes: inputClasses } = inputStyles();
    const { classes: chipClasses } = chipStyles();
    const { classes: cancelClasses } = cancelStyles();

    const values = getValue();

    return (
        <>
            {isMulti && (
                <div>
                    {values.map(val => (
                        <Chip
                            key={val.value}
                            classes={{ root: chipClasses.root }}
                            deleteIcon={<CloseIcon className={cancelClasses.chipCancel} />}
                            label={<EllipsisText>{val.label}</EllipsisText>}
                            title={val.label}
                            variant="outlined"
                            onDelete={() => {
                                selectOption(val);
                            }}
                        />
                    ))}
                </div>
            )}

            <TextField
                data-cy={`advancedSearch-select-${name}`}
                error={error}
                fullWidth={true}
                InputProps={{
                    inputComponent,
                    classes: inputClasses,
                    inputProps: {
                        className: inputClasses.input,
                        ref: innerRef,
                        children,
                        ...innerProps,
                    },
                }}
                margin="normal"
                style={{
                    //maxWidth: props.maxInputWidth || undefined,
                    margin: 0,
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                    },
                    '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                            border: 'none',
                        },
                    },
                }}
                variant="outlined"
            />
        </>
    );
};

SelectControl.propTypes = {
    error: PropTypes.bool,
    children: PropTypes.node.isRequired,
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
    isMulti: PropTypes.bool.isRequired,
    getValue: PropTypes.func.isRequired,
    selectOption: PropTypes.func.isRequired,
};

SelectControl.defaultProps = {
    error: undefined,
};

export default SelectControl;
