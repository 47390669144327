import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { Box, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, isValid } from 'date-fns';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { isError }) => ({
    root: {
        top: isError ? theme.spacing(1) : 0,
    },
    dateInput: {
        '& svg': {
            cursor: 'pointer',

            '& path': {
                fill: theme.palette.icon.light,
            },
        },
    },
    errorMessage: {
        paddingTop: theme.spacing(0.8),
        paddingLeft: theme.spacing(1.5),
        color: 'red',
        fontSize: '1rem',
    },
}));

const DatePickerWrapper = ({
    input: { value, onChange }, // Injected by wrapper Field from react-final-from
    label,
    dateFormat,
    variant,
    onChange: userOnChange,
}) => {
    const [isError, setIsError] = useState(false);
    let tempValue = value;
    const mask = dateFormat.replace(/[a-zA-Z]/gi, '_');

    // temporary fix, for preventing an error on the dateInput
    if (value === 'DYNAMIC_DATE:date') {
        tempValue = '';
    }

    const onError = error => {
        setIsError(error?.length > 0);
    };

    const onDateChange = (date, keyboardInputValue) => {
        let dateString;

        if (isValid(date)) {
            dateString = format(date, dateFormat);
        } else {
            dateString = !!keyboardInputValue
                ? `${keyboardInputValue}${mask.substring(keyboardInputValue.length)}`
                : null;
        }

        onChange(dateString, date);
        userOnChange && userOnChange(dateString, date);
    };

    const { classes } = useStyles({ isError });

    const invalidDateMessage = `${tempValue} is not a valid date`;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                {...{ classes }}
                disableToolbar={false}
                inputFormat={dateFormat}
                inputVariant={variant}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                label={label}
                margin="none"
                mask={mask}
                renderInput={({ inputProps, ...restParams }) => (
                    <>
                        <TextField
                            {...restParams}
                            autoComplete="off"
                            className={classes.dateInput}
                            error={isError}
                            inputProps={{
                                ...inputProps,
                                placeholder: '',
                            }}
                        />
                        {isError && <Box className={classes.errorMessage}>{invalidDateMessage}</Box>}
                    </>
                )}
                value={tempValue || null}
                variant="inline"
                onChange={(date, keyboardInputValue) => onDateChange(date, keyboardInputValue)}
                onError={onError}
            />
        </LocalizationProvider>
    );
};

DatePickerWrapper.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    variant: PropTypes.string,
    dateFormat: PropTypes.string,
    input: PropTypes.object,
    onChange: PropTypes.func,
};

DatePickerWrapper.defaultProps = {
    label: '',
    name: '',
    variant: 'outlined',
    dateFormat: 'yyyy-MM-dd',
    input: {},
    onChange: null,
};

const FormDatePickerField = ({ name, ...fieldProps }) => (
    <Field name={name} render={fieldRenderProps => <DatePickerWrapper {...fieldRenderProps} />} {...fieldProps} />
);

FormDatePickerField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    variant: PropTypes.string,
    dateFormat: PropTypes.string,
};

FormDatePickerField.defaultProps = {
    label: '',
    variant: 'outlined',
    dateFormat: 'yyyy-MM-dd',
};

// exported for testing purposes
export { DatePickerWrapper };

export default FormDatePickerField;
