import React from 'react';
import { Box, Skeleton, Typography } from '@development-nl/components-library';
import { format as formatDate } from 'date-fns';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import {
    dateClasses,
    DateTypography,
    EventSubTitleTypography,
    subTitleClasses,
    SubTitleTypography,
    titleClasses,
    TitleTypography,
    useDefaultStyles,
    useToolsStyle,
} from '../VideoTile/metaDataStyles';

export const useStyles = makeStyles()(theme => ({
    eventInfoGrid: {
        display: 'grid',
    },
    title: {
        marginBottom: theme.spacing(1.4),
    },
}));

const EventDataInfoSkeleton = () => (
    <Box data-test="eventTile-eventInfo" px={1} py={0.5}>
        <Skeleton height={6} />
        <Skeleton height={6} width="80%" />
        <Skeleton height={6} />
        <Skeleton height={6} width="80%" />
    </Box>
);

const EventDataInfo = ({ eventData, thumbnailConfig }) => {
    const { match_name, event_description, domain_league_name, match_date } = eventData;

    const placeholder = 'NO_DATA';
    const title = eventData[thumbnailConfig[2]?.key] || match_name || placeholder;
    const subTitle = eventData[thumbnailConfig[3]?.key] || event_description || placeholder;
    const leagueName = eventData[thumbnailConfig[4]?.key] || domain_league_name || placeholder;

    const isPlaceholder = text => (text === placeholder).toString();

    const getDate = () => {
        if (!match_date) {
            return '';
        }
        const split = match_date.split(' ');
        const date = split[0];
        const time = split[1];

        const timeSplit = time.split(':');

        const hours = parseInt(timeSplit[0], 10);
        const minutes = parseInt(timeSplit[1], 10);
        const seconds = parseInt(timeSplit[2], 10);

        const newDate = new Date(date);
        newDate.setHours(hours, minutes, seconds);

        return formatDate(newDate, 'dd-MM-yyyy kk:mm:ss');
    };

    const date = getDate() || placeholder;

    const { classes, cx } = useToolsStyle();
    const { classes: eventInfoClasses } = useStyles();

    return (
        <Box className={classes.root} px={1} py={0.5}>
            <TitleTypography
                className={cx(titleClasses.root, eventInfoClasses.title)}
                component={'div'}
                data-cy="eventTileTitle"
                display="block"
                isplaceholder={isPlaceholder(title)}
                style={{ lineHeight: 1.5 }}
                variant="body2"
            >
                {title}
                <Box>&nbsp;</Box>
            </TitleTypography>
            <Box className={eventInfoClasses.eventInfoGrid}>
                <EventSubTitleTypography
                    className={subTitleClasses.root}
                    display="block"
                    isplaceholder={isPlaceholder(subTitle)}
                    noWrap={true}
                    variant="caption"
                >
                    {subTitle}
                </EventSubTitleTypography>
                <SubTitleTypography
                    className={subTitleClasses.root}
                    display="block"
                    isplaceholder={isPlaceholder(leagueName)}
                    noWrap={true}
                    variant="caption"
                >
                    {leagueName}
                </SubTitleTypography>
                <DateTypography
                    className={dateClasses.root}
                    display="block"
                    isplaceholder={isPlaceholder(date)}
                    noWrap={true}
                    variant="caption"
                >
                    {date}
                </DateTypography>
            </Box>
        </Box>
    );
};

EventDataInfo.propTypes = {
    eventData: PropTypes.shape({
        match_name: PropTypes.string,
        event_description: PropTypes.string,
        domain_league_name: PropTypes.string,
        match_date: PropTypes.string,
    }).isRequired,
    thumbnailConfig: PropTypes.array.isRequired,
};

EventDataInfo.defaultProps = {};

const EventDataInfoSimple = ({ eventData }) => {
    const { classes } = useDefaultStyles();
    const { asset_title } = eventData;

    return (
        <Box className={classes.root} px={1} py={0.5}>
            <Typography className={classes.titleRow} variant="body2">
                {truncate(asset_title, { length: 24 })}
            </Typography>
        </Box>
    );
};

EventDataInfoSimple.propTypes = {
    eventData: PropTypes.shape({
        asset_title: PropTypes.string,
    }).isRequired,
};

export default {
    Info: EventDataInfo,
    Skeleton: EventDataInfoSkeleton,
    Simple: EventDataInfoSimple,
};
