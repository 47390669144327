import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationLoading } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';

import userSlice, { validateToken } from '../../store/user';

const AuthCookieProvider = ({ children }) => {
    const authCookie = document.cookie.split('; ').find(row => row.startsWith('auth='));
    const tokenCookie = authCookie ? authCookie.split('=')[1] : '';
    const dispatch = useDispatch();

    const { loading, authenticated, error } = useSelector(userSlice.selectors.getUser);

    if (authenticated) {
        // Logged in
        return children;
    } else if (tokenCookie) {
        if (!loading && !error) {
            dispatch(validateToken({ token: tokenCookie }));
        } else if (error) {
            return children;
        }

        return <ApplicationLoading />;
    } else {
        // Render non-protected routes (and login)
        return children;
    }
};

AuthCookieProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthCookieProvider;
