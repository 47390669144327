import { createSelector } from 'reselect';

import { defaultEmptyFilters } from '../../store/advancedSearch/utils';

const selectAdvancedSearch = ({ advancedSearch }) => advancedSearch;

export const selectIsLoading = ({ advancedSearch }) => advancedSearch.metaKeys.loading;
export const selectMatchType = ({ advancedSearch }) => advancedSearch.matchType;
export const selectAdvancedFilters = ({ advancedSearch }) => advancedSearch.filters;
export const selectCreatedEnabled = ({ advancedSearch }) =>
    advancedSearch.createdFilter.enabled || advancedSearch.createdPeriod.enabled;
export const selectCreatedPeriod = ({ advancedSearch }) => advancedSearch.createdPeriod;
export const selectCreatedPeriodInvalid = ({ advancedSearch }) => advancedSearch.createdPeriod.invalid;
export const selectCreatedFilter = ({ advancedSearch }) => advancedSearch.createdFilter;
export const selectShowSavePanel = ({ advancedSearch }) => advancedSearch.showSavePanel;
export const selectName = ({ advancedSearch }) => advancedSearch.name;
export const selectPermissionType = ({ advancedSearch }) => advancedSearch.permissionType;
export const selectAllowedGroups = ({ advancedSearch }) => advancedSearch.allowedGroups;
export const selectIsQuickFilter = ({ advancedSearch }) => advancedSearch.quickfilter;
export const selectIsSavedSearch = ({ advancedSearch }) => advancedSearch.saved;
export const selectIsEditable = ({ advancedSearch }) => advancedSearch.editable;
export const selectQueryId = ({ advancedSearch }) => advancedSearch.id;
export const selectFilterHasOnlyOneItem = ({ advancedSearch }) => advancedSearch.filters.length === 1;
export const selectMetaKeysIsLoading = ({ advancedSearch }) => advancedSearch.metaKeys.loading;
export const selectMetaKeysHasAll = ({ advancedSearch }) => !!advancedSearch.metaKeys.hasAll;
export const selectSmartSearchTerm = ({ advancedSearch }) => advancedSearch.smartSearchTerm;
export const selectSmartSearches = ({ advancedSearch }) => advancedSearch.smartSearches;
export const selectHasSmartSearches = ({ advancedSearch }) => advancedSearch.smartSearches.length > 0;
export const selectSmartSearchIsLoading = ({ advancedSearch }) => advancedSearch.loading;
export const selectDefaultSearchInProgress = ({ advancedSearch }) => advancedSearch.defaultInProgress;
export const selectSmartSearchResultInitial = ({ advancedSearch }) => advancedSearch.smartSearchResultInitial;

export const selectQueryData = createSelector(selectAdvancedSearch, state => ({
    id: state.id,
    keyword: state.keyword,
    matchType: state.matchType,
    filters: state.filters.filter(
        f => ((f.verb === 'isdefined' || f.verb === 'isnotdefined') && f.key) || (f.key && f.value)
    ),
    createdPeriod: state.createdPeriod,
    createdFilter: state.createdFilter,
    page: 1,
}));

export const selectAdvancedFiltersWithDefault = createSelector(selectAdvancedFilters, filters =>
    !!filters.length ? filters : defaultEmptyFilters
);

export const selectAdvancedFilterIds = createSelector(selectAdvancedFiltersWithDefault, filters =>
    filters.map(filter => filter.id)
);

export const selectAdvancedFilterKeys = createSelector(selectAdvancedFiltersWithDefault, filters =>
    filters.map(filter => filter.key)
);

export const makeSelectAdvancedFilter = id =>
    createSelector(selectAdvancedFiltersWithDefault, filters => filters.find(filter => filter.id === id));

export const selectLastFilterItemId = createSelector(
    selectAdvancedFilterIds,
    filterIds => filterIds.length && filterIds.slice(-1)[0]
);

export const makeSelectIsLastItem = id => createSelector(selectLastFilterItemId, lastId => lastId === id);

export const makeSelectMetaFilters = id =>
    createSelector([selectMatchType, selectAdvancedFilters], (matchType, filters) =>
        matchType === 'all' ? filters.filter(filter => filter.value && filter.id !== id) : []
    );
export const selectMetaKeys = createSelector(selectAdvancedSearch, substate => substate.metaKeys.data || []);
export const selectMetaFilterOptions = createSelector(
    [selectAdvancedFilterKeys, selectMetaKeys],
    (filterKeys, metaKeys) =>
        metaKeys.filter(({ id }) => !filterKeys.includes(id)).map(({ id }) => ({ value: id, label: id }))
);

export const selectHasInvalidRule = createSelector(selectAdvancedFilters, filters => {
    filters.some(f => {
        if (f.verb === 'isdefined' || f.verb === 'isnotdefined') {
            return false;
        } else {
            return f.key && !f.value;
        }
    });
});

export const selectHasInvalidCreatedPeriod = createSelector(
    selectCreatedPeriod,
    createdPeriod => createdPeriod && createdPeriod.enabled && !createdPeriod.value
);

export const selectHasInvalidCreatedFilter = createSelector(selectCreatedFilter, createdFilter =>
    createdFilter && createdFilter.enabled
        ? createdFilter.type === 'within'
            ? createdFilter.value[0] === null || createdFilter.value[1] === null
            : !createdFilter.value
        : false
);

export const selectUpdatingSearchIds = state => state.advancedSearch.updating;

export const selectFilteredSmartSearches = createSelector(
    [selectSmartSearches, selectSmartSearchTerm],
    (smartSearches, searchString) =>
        smartSearches
            .filter(e => !searchString || !!~e.lowercaseName.indexOf(searchString.toLowerCase()))
            .reduce(
                (acc, e) => {
                    const key = e.permissionType && acc[e.permissionType] ? e.permissionType : e.saved ? 'saved' : '';
                    if (!key) {
                        return acc;
                    }

                    return { ...acc, [key]: [...acc[key], e], saved: e.saved ? [...acc.saved, e] : acc.saved };
                },
                { private: [], group: [], public: [], saved: [] }
            )
);

export const selectActiveSearch = createSelector(selectSmartSearches, substate =>
    substate.find(search => search.active)
);

export const selectActiveSearchId = createSelector(selectActiveSearch, activeSearch => activeSearch && activeSearch.id);

export const selectIsActive = createSelector(
    [selectActiveSearchId, selectQueryId],
    (searchId, queryId) => parseInt(searchId, 10) === parseInt(queryId, 10)
);

export const selectQueryDataSmartSearchInitial = createSelector(
    selectSmartSearchResultInitial,
    smartSearchResultInitial =>
        smartSearchResultInitial
            ? {
                  id: parseInt(smartSearchResultInitial.id),
                  keyword: smartSearchResultInitial.keyword,
                  matchType: smartSearchResultInitial.matchType,
                  filters: smartSearchResultInitial?.filters.filter(f => f.key && f.value),
                  createdPeriod: smartSearchResultInitial.createdPeriod,
                  createdFilter: smartSearchResultInitial.createdFilter,
                  page: 1,
              }
            : null
);

export const selectSavedSearches = createSelector(selectSmartSearches, substate =>
    substate.filter(search => search.saved)
);
