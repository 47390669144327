import React, { useEffect, useState } from 'react';
import { ReactComponent as LiveIcon } from '@mediabank/assets/icons/liveIcon.svg';
import { ReactComponent as ScheduledIcon } from '@mediabank/assets/icons/scheduledIcon.svg';
import { ReactComponent as VodIcon } from '@mediabank/assets/icons/vodIcon.svg';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { getFallbackThumbnail } from './getFallbackThumbnail';

const useStyles = makeStyles()((theme, { liveStreamMode, floatRight, imageHeight }) => ({
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: 0,
        backgroundColor: liveStreamMode ? theme.palette.background.dateThumbnail : 'transparent',
        // height/width for a standard 16:9 aspect ratio
        paddingTop: `calc(60% + ${imageHeight})`,
    },
    img: {
        top: 0,
        right: 0,
        bottom: 0,
        left: floatRight ? 'none' : 0,
        position: 'absolute',
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto',
        height: floatRight ? '100%' : 'auto',
    },
    iconThumbnail: {
        position: 'relative',
        display: 'block',
        padding: theme.spacing(1),
        marginBottom: '55%',
    },
}));

const AssetThumbnail = ({
    liveStreamMode,
    imgSrc,
    containerProps,
    title,
    onError,
    onLoad,
    mimeType,
    mimeTypeFull,
    floatRight,
    imageHeight,
}) => {
    const { classes } = useStyles({ liveStreamMode, floatRight, imageHeight });

    const [src, setSrc] = useState(imgSrc);

    useEffect(() => setSrc(imgSrc), [imgSrc]);

    const onImageError = () => {
        setSrc(getFallbackThumbnail(mimeType, mimeTypeFull));
        if (onError) {
            onError();
        }
    };

    const Icon = () => {
        switch (liveStreamMode) {
            case 'live':
                return <LiveIcon className={classes.iconThumbnail} />;
            case 'scheduled':
                return <ScheduledIcon className={classes.iconThumbnail} />;
            case 'vod':
                return <VodIcon className={classes.iconThumbnail} />;
            default:
                return <VodIcon className={classes.iconThumbnail} />;
        }
    };

    return (
        <Box className={classes.imageWrapper} {...containerProps}>
            {!liveStreamMode ? (
                <img
                    alt={title}
                    className={classes.img}
                    src={src}
                    title={title}
                    onError={onImageError}
                    onLoad={onLoad}
                />
            ) : (
                <Icon />
            )}
        </Box>
    );
};

AssetThumbnail.propTypes = {
    liveStreamMode: PropTypes.string,
    containerProps: PropTypes.object,
    imgSrc: PropTypes.string.isRequired,
    onError: PropTypes.func,
    onLoad: PropTypes.func,
    title: PropTypes.string.isRequired,
    mimeType: PropTypes.string,
    mimeTypeFull: PropTypes.string,
    floatRight: PropTypes.bool,
    imageHeight: PropTypes.string,
};

AssetThumbnail.defaultProps = {
    liveStreamMode: null,
    containerProps: {},
    onError: () => null,
    onLoad: () => null,
    mimeType: null,
    mimeTypeFull: null,
    floatRight: false,
    imageHeight: '0px',
};

export { AssetThumbnail };
