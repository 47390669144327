import { mediabankApi as api, mediabankHeaders } from '..';

export const send = async ({ attributes }) => {
    const response = await api.post('/quicklinks', {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'quicklinks',
                attributes,
            },
        },
    });
    return response;
};
