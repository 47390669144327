import React, { createContext } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { NotificationContent } from './NotificationContent';

const NotificationsContext = createContext({});

const VARIANTS = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
};

const Notifications = ({ children = null }) => {
    const { enqueueSnackbar } = useSnackbar();

    const addToast = ({ caption, cy, persist, subtitle, title, variant }) => {
        // for now, we only disallow duplicates when using the `persist` flag
        const preventDuplicate = persist;

        enqueueSnackbar(title, {
            content: notificationKey => (
                <Box data-cy={cy} data-testid={cy}>
                    <NotificationContent
                        caption={caption}
                        notificationKey={notificationKey}
                        subtitle={subtitle}
                        title={title}
                        variant={variant}
                    />
                </Box>
            ),
            persist,
            preventDuplicate,
        });
    };

    const value = {
        addToastError: optionsObj => addToast({ ...optionsObj, variant: VARIANTS.error }),
        addToastInfo: optionsObj => addToast({ ...optionsObj, variant: VARIANTS.info }),
        addToastSuccess: optionsObj => addToast({ ...optionsObj, variant: VARIANTS.success }),
        addToastWarning: optionsObj => addToast({ ...optionsObj, variant: VARIANTS.warning }),
    };

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

Notifications.propTypes = {
    children: PropTypes.node.isRequired,
};

export { Notifications, NotificationsContext };
