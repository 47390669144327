import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

export const useScrollableContainerStyles = makeStyles()(
    (_, { scrollableX, isContainerFitContent, calc, scrollableY }) => ({
        container: {
            height: '100%',
            overflowY: scrollableY ? 'auto' : 'hidden',
            overflowX: scrollableX ? 'auto' : 'hidden',
            width: isContainerFitContent ? 'fit-content' : `calc(100% - ${calc}px)`,
        },
    })
);

const ScrollableContainer = React.forwardRef(
    ({ scrollBarPos, children, scrollableX, scrollableY, isContainerFitContent, calc, ...props }, ref) => {
        const { classes } = useScrollableContainerStyles({ scrollableX, scrollableY, calc, isContainerFitContent });

        return (
            <Box ref={ref} className={classes.container} mr={-scrollBarPos} pr={scrollBarPos} {...props}>
                {children}
            </Box>
        );
    }
);

ScrollableContainer.propTypes = {
    children: PropTypes.node.isRequired,
    scrollBarPos: PropTypes.number,
    scrollableX: PropTypes.bool,
    scrollableY: PropTypes.bool,
    isContainerFitContent: PropTypes.bool,
    calc: PropTypes.number,
};

ScrollableContainer.defaultProps = {
    scrollBarPos: 2,
    scrollableX: false,
    scrollableY: true,
    isContainerFitContent: false,
    calc: 0,
};

export default ScrollableContainer;
