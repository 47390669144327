import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const BreadcrumbContext = createContext();

export function useBreadcrumb() {
    return useContext(BreadcrumbContext);
}

export const BreadcrumbConsumer = BreadcrumbContext.Consumer;

const BreadcrumbProvider = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const isVisible = !!breadcrumbs.length;

    const value = { breadcrumbs, isVisible, setBreadcrumbs };

    return <BreadcrumbContext.Provider value={value}>{children}</BreadcrumbContext.Provider>;
};

BreadcrumbProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default BreadcrumbProvider;
