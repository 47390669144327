import { mediabankApi as api, mediabankHeaders } from '../../mediabank';
import { createDynamicAggregateParams, createDynamicFilterParams } from '../eventSearch/utils';
import getRelatedAssets from './getRelatedAssets';

export const search = async ({
    createdFilter = {},
    createdPeriod = {},
    dynamicAssetFilters = [],
    keyword = null,
    filters = [],
    limit = null,
    searchType = null,
    combinedLimit = null,
    matchType = null,
    orderBy = null,
    page = null,
    resultCriteria = [],
    resultKey = null,
    smartSearchId = null,
    sortOrder = null,
    combinedSortOrder = null,
    combinedOrderBy = null,
}) => {
    const createdPeriodParams = createdPeriod &&
        createdPeriod.enabled && {
            smartsearchperiodenabled: createdPeriod.enabled,
            smartsearchperiodtype: createdPeriod.type,
            smartsearchperiod: createdPeriod.value,
        };
    const createdFilterParams = createdFilter &&
        createdFilter.enabled && {
            smartsearchassetcreatedfilterenabled: createdFilter.enabled,
            smartsearchassetcreatedfiltertype: createdFilter.type,
            smartsearchassetcreatedfiltervalue: createdFilter.value,
        };
    const filterParams = filters.reduce((acc, filter, index) => {
        acc[`smartsearchfilters[${index}][key]`] = filter.key;
        acc[`smartsearchfilters[${index}][verb]`] = filter.verb;
        acc[`smartsearchfilters[${index}][value]`] = filter.value;
        return acc;
    }, {});

    const dynamicFilters = createDynamicFilterParams(dynamicAssetFilters, 'smartsearchfilters', 'assets');

    const resultCriteriaParams = resultCriteria.reduce((acc, criterias, index) => {
        Object.keys(criterias).forEach(key => {
            acc[`resultcriteria[${index}][${key}]`] = criterias[key];
        });

        return acc;
    }, {});

    const isCombinedSearch = searchType === 'all';

    const response = await api.get('/assets', {
        headers: mediabankHeaders(),
        urlParams: {
            limit: isCombinedSearch ? combinedLimit : limit,
            orderby: isCombinedSearch ? combinedOrderBy : orderBy,
            page,
            resultkey: resultKey,
            smartsearchid: smartSearchId,
            smartsearchtext: keyword,
            sortorder: isCombinedSearch ? combinedSortOrder : sortOrder,
            smartsearchmatchtype: matchType,
            ...createdFilterParams,
            ...createdPeriodParams,
            ...(!!dynamicAssetFilters.length ? dynamicFilters : filterParams),
            ...resultCriteriaParams,
        },
    });

    return response;
};

const loadNext = async nextUrl => {
    // Need to remove api part from next url:s since it is added again by httpClient
    nextUrl = nextUrl.replace('/api/v1', '');
    const response = await api.get(nextUrl, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getAsset = async ({ id }) => {
    const response = await api.get(`/assets/${id}`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getAssetsFromIds = async ({ ids }) => {
    //Assets endpoint has limit in default as 12. To return more than 12 assets, we need to set limit
    const limit = ids.split(',').length;
    const response = await api.get(`/assets?id=${ids}&limit=${limit}`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getMetaKeys = async (option = {}) => {
    const { getAll } = option;
    const response = await api.get(`/assets/metakeys`, {
        headers: mediabankHeaders(),
        urlParams: {
            all: getAll,
        },
    });
    return response;
};

const getMetaValues = async (option = {}) => {
    const { metaKeyId, metaFilters } = option;
    const response = await api.get(`/assets/metakeys/${metaKeyId}`, {
        headers: mediabankHeaders(),
        urlParams: {
            ...(metaFilters || []).reduce((acc, filter, index) => {
                const { key, value, verb } = filter;
                acc[`filters[${index}][key]`] = key;
                acc[`filters[${index}][value]`] = value;
                acc[`filters[${index}][verb]`] = verb;
                return acc;
            }, {}),
        },
    });
    return response;
};
const getAllMetaValuesById = async (option = {}) => {
    const { metaKeyId, filters = [] } = option;
    const filterParams = createDynamicFilterParams(filters, 'smartsearchfilters', 'assets');

    const response = await api.get(`/assets`, {
        headers: mediabankHeaders(),
        urlParams: {
            limit: 1,
            'aggregate[0]': metaKeyId,
            ...filterParams,
        },
    });
    return response;
};

const getMetaAggregates = async (option = {}) => {
    const { metaKeys = [], filters = [] } = option;

    const aggregateParams = createDynamicAggregateParams(metaKeys);
    const filterParams = createDynamicFilterParams(filters, 'smartsearchfilters', 'assets');

    const response = await api.get(`/assets`, {
        headers: mediabankHeaders(),
        urlParams: {
            limit: 1,
            ...aggregateParams,
            ...filterParams,
        },
    });
    return response;
};

const create = async ({ title, metadata = {} }) => {
    const response = await api.post('/assets', {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'assets', attributes: { title: title, metadata: metadata } },
        },
    });
    return response;
};

const pokeAsset = async ({ id, method = '' }) => {
    const response = await api.post(`/assets/${id}/poke/${method}`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const deleteAsset = async ({ assetId }) => {
    const response = await api.delete(`/assets/${assetId}`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getPosterSeek = async ({ assetId, posterIndex }) => {
    const response = await api.get(`/assets/${assetId}/posters/${posterIndex}`, {
        headers: mediabankHeaders(),
    });
    return response;
};

export const generateUrl = async ({ progressiveUrl }) => {
    const urlParamChar = progressiveUrl.match(/\?/g) ? '&' : '?';

    const {
        data: {
            data: {
                links: { mediafile: response },
            },
        },
    } = await api.get(`${progressiveUrl.replace('/api/v1/', '')}${urlParamChar}urlrequest=true`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const bulkAssetsProperties = async ({ attributes }) => {
    const response = await api.post(`/bulk/assets/properties`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'bulk/assets/properties', attributes },
        },
    });
    return response;
};

const getPropertiesSections = async ({ assetId }) => {
    const response = await api.get(`/assets/${assetId}/properties`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getPropertiesForm = async assetId => {
    const response = await api.get(`/assets/${assetId}/forms/properties/meta_data`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getPropertyDetails = async link => {
    const {
        data: {
            data: { attributes },
        },
    } = await api.get(link, {
        headers: mediabankHeaders(),
    });

    return attributes;
};

const getPublishForms = async assetId => {
    const response = await api.get(`assets/${assetId}/forms/publishing`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getVideoParts = async ({ assetId }) => {
    const response = await api.get(`/assets/${assetId}/videoparts`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getHappeningEvents = async ({ happeningsId }) => {
    const response = await api.get(`/happenings/${happeningsId}/events`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const updateVideoParts = async ({ id, data }) => {
    const response = await api.put(`/assets/${id}/videoparts`, {
        data,
        headers: mediabankHeaders(),
    });
    return response;
};

const updateAudio = async ({ assetId, mediainfo = [] }) => {
    const response = await api.patch(`/assets/${assetId}/audio`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'asset/audio', attributes: { assetId: assetId, tracks: mediainfo } },
        },
    });
    return response;
};

const updateAsset = async ({ assetId, assettitle, assetmeta = {}, updateMirrors = false }) => {
    const response = await api.patch(`/assets/${assetId}?updateMirrors=${updateMirrors}`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'asset', attributes: { assettitle, assetmeta } },
        },
    });
    return response;
};

const getAttachments = async ({ assetId }) => {
    const response = await api.get(`/assets/${assetId}/attachments`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const createAttachment = async ({ assetId, attachment }) => {
    const response = await api.post(`/assets/${assetId}/attachments`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'attachmentUploadJobs', attributes: attachment },
        },
    });
    return response;
};

const bulkUpdateAssetMediaInfo = async ({ assetIds, skipOnRequirement = false }) => {
    const response = await api.post(`/bulk/assets/mediainfo`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'bulk/assets', attributes: { assets: assetIds, lock: 0, skipOnRequirement } },
        },
    });
    return response;
};

const bulkGenerateScrubbingPosters = async ({ assetIds, skipOnRequirement = false }) => {
    const response = await api.post(`/bulk/assets/posters`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'bulk/assets', attributes: { assets: assetIds, skipOnRequirement } },
        },
    });
    return response;
};

const bulkUpdateProxyFiles = async ({ assetIds }) => {
    const response = await api.post(`/bulk/assets/updateProxyFiles`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'updateProxyFiles', attributes: { assetIds } },
        },
    });
    return response;
};

const bulkDeleteAsset = async ({ assetIds, type }) => {
    const response = await api.post(`/bulk/assets/delete`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'bulk/assets', attributes: { assets: assetIds, skipOnRequirement: true, type } },
        },
    });
    return response;
};

const bulkRestoreAsset = async ({ assetIds, type }) => {
    const response = await api.post(`/bulk/assets/restoreFromBackup`, {
        headers: mediabankHeaders(),
        data: {
            data: { type: 'restorefrombackup', attributes: { assetIds, options: { restoreType: type } } },
        },
    });
    return response;
};

const getDataFeeds = async ({ id }) => {
    const response = await api.get(`/assets/${id}/datafeeds`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getDataFeedsDownload = async ({ assetId, mimeType, fileName }) => {
    const response = await api.get(`/assets/${assetId}/datafeeds/download/${mimeType}/${fileName}`, {
        headers: mediabankHeaders(),
        responseType: 'blob',
    });
    return response;
};

/*
 Todo: Add get all, patch, poke and smartsearchqueries
*/

export default {
    search,
    getAsset,
    getAssetsFromIds,
    getAttachments,
    getHappeningEvents,
    getMetaAggregates,
    getMetaKeys,
    getMetaValues,
    getPropertiesForm,
    getPropertiesSections,
    getPropertyDetails,
    getRelatedAssets,
    getVideoParts,
    pokeAsset,
    create,
    createAttachment,
    deleteAsset,
    loadNext,
    updateAsset,
    updateAudio,
    updateVideoParts,
    getPublishForms,
    getPosterSeek,
    bulkRestoreAsset,
    bulkAssetsProperties,
    bulkUpdateAssetMediaInfo,
    bulkGenerateScrubbingPosters,
    bulkUpdateProxyFiles,
    bulkDeleteAsset,
    getDataFeeds,
    getDataFeedsDownload,
    getAllMetaValuesById,
};
