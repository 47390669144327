import React from 'react';
import { Box, Input } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        boxShadow: `0px 0 1px 0 ${theme.custom.accentLight[1]}`,
        paddingLeft: theme.spacing(1),
        width: '225px !important',
    },
}));

const FormDateSelectField = ({ dateRange, onChange, onClick }) => {
    const { classes } = useStyles();

    return (
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Input
                name="date"
                {...{ classes }}
                disableUnderline={true}
                id="input-with-icon-adornment"
                readOnly={false}
                value={dateRange}
                onChange={onChange}
                onClick={onClick}
            />
        </Box>
    );
};
FormDateSelectField.propTypes = {
    dateRange: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
};

FormDateSelectField.defaultProps = {
    dateRange: '',
    onChange: null,
    onClick: null,
};

export default FormDateSelectField;
