import { tagListsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    requesting: false,
    data: [],
};

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        updateDetails: (state, action) => {
            const { payload } = action;
            state.data = payload.events;
        },
    },
});

const { requestStart, requestFinished, updateDetails } = eventsSlice.actions;

const fetchEvents = id => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await tagListsService.getEvents(id);

        dispatch(requestFinished());
        if (response?.status === 200) {
            const events = response?.data?.data || [];
            dispatch(updateDetails({ events }));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const isRequesting = state => state.events.requesting;
const events = state => state.events?.data || [];

export default {
    ...eventsSlice,
    fetchEvents,
    selectors: { isRequesting, events },
};
