import React, { useEffect, useState } from 'react';
import { useMouseHovered } from 'react-use';
import { Box } from '@development-nl/components-library';
import PropTypes from 'prop-types';

import { OverlayBox } from '../Thumbnail';

const ScrubOverlay = ({ isScrubing, onScrub, onStop, duration }) => {
    const ref = React.useRef(null);
    const { elX, elW } = useMouseHovered(ref, { bound: true, whenHovered: false });
    const [lastScrubPoint, setLastScrubPoint] = useState(0);
    const currentProgress = elX / elW;

    const stopScrub = event => {
        setLastScrubPoint(elX);
        onStop(event);
    };

    useEffect(() => {
        isScrubing && onScrub(currentProgress);
    }, [isScrubing, onScrub, currentProgress]);

    return (
        <OverlayBox ref={ref} data-test="videoTile-scrubOverlay" onClick={stopScrub}>
            <Box
                border={1}
                borderColor="primary.main"
                height={1}
                sx={
                    isScrubing && duration
                        ? { transform: `translateX(${elX}px)` }
                        : { transform: `translateX(${lastScrubPoint}px)` }
                }
                width="1px"
            />
        </OverlayBox>
    );
};
ScrubOverlay.defaultProps = {
    duration: null,
};

ScrubOverlay.propTypes = {
    duration: PropTypes.number,
    isScrubing: PropTypes.bool.isRequired,
    onScrub: PropTypes.func.isRequired,
    onStop: PropTypes.func.isRequired,
};

export default ScrubOverlay;
