import { mediabankApi as api, mediabankHeaders } from '..';

export const listAll = async () => {
    const {
        data: { data },
    } = await api.get(`/notificationlists`, {
        headers: mediabankHeaders(),
    });

    return data;
};
