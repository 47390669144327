import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { useCircleLoadingStyles } from './styles';

const CircleLoading = ({ size, thickness, ...props }) => {
    const { classes } = useCircleLoadingStyles();
    const configs = { size, thickness };

    return (
        <Box {...props} alignItems="center" display="flex" justifyContent="center" position="relative">
            <CircularProgress className={classes.top} value={100} variant="determinate" {...configs} />
            <CircularProgress className={classes.bottom} disableShrink={true} variant="indeterminate" {...configs} />
        </Box>
    );
};

CircleLoading.propTypes = {
    size: PropTypes.number,
    thickness: PropTypes.number,
};

CircleLoading.defaultProps = {
    height: 100,
    size: 36,
    thickness: 2,
};

export default CircleLoading;
