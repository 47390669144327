import { createSlice } from '@reduxjs/toolkit';

import { displayTypes } from '../../components/SearchResult/constants';
import { SEARCHTYPE } from '../../hooks/useCombinedSearchMode/useCombinedSearchMode';

const initialState = {
    combinedDisplayType: displayTypes.thumbnail,
    assetDisplayType: displayTypes.thumbnail,
    eventDisplayType: displayTypes.thumbnail,
};

const displayTypeSlice = createSlice({
    name: 'displayType',
    initialState,
    reducers: {
        changeDisplayType: (state, action) => {
            const { tab, newDisplayType } = action.payload;
            if (tab === SEARCHTYPE.all) {
                state.combinedDisplayType = newDisplayType;
            } else if (tab === SEARCHTYPE.events) {
                state.eventDisplayType = newDisplayType;
            } else {
                state.assetDisplayType = newDisplayType;
            }
        },
    },
});

const getCombinedDisplayType = state => state.displayType.combinedDisplayType;
const getEventDisplayType = state => state.displayType.eventDisplayType;
const getAssetDisplayType = state => state.displayType.assetDisplayType;
const getDisplayType = state => state.displayType;

export default {
    ...displayTypeSlice,
    selectors: {
        getDisplayType,
        getAssetDisplayType,
        getCombinedDisplayType,
        getEventDisplayType,
    },
};
