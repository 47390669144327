import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    date: `${new Date(Date.now())}`,
    isDateUpdateRequested: false,
    calendarAssetData: {},
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setCalendarCurrentDate: (state, action) => {
            state.date = action.payload.value;
        },
        setRequestedDateUpdate: (state, action) => {
            state.isDateUpdateRequested = action.payload.value;
        },
        setCalendarAssetData: (state, action) => {
            state.calendarAssetData = action.payload;
        },
        resetCalendar: state => {
            state.calendarAssetData = {};
        },
        clear: () => initialState,
    },
});

export default calendarSlice;
