import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {
        height: '100%',
    },
}));

/**
 * Utility component for centering anything.
 */
const Centered = ({ children }) => {
    const { classes } = useStyles();

    return (
        <Grid
            alignContent="center"
            alignItems="center"
            className={classes.root}
            container={true}
            justifyContent="center"
        >
            <Grid item={true}>{children}</Grid>
        </Grid>
    );
};

Centered.propTypes = {
    children: PropTypes.element.isRequired,
};

export default Centered;
