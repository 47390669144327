import React, { lazy, Suspense } from 'react';
import { destinationService, quickLinkSend as send } from '@mediabank/client';
import { PanelLoading } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';

import { quicklinkMBox } from '../../../store/mBox';
import MboxModalContext from '../Modal/modalContext';

const { id: quicklinkMBoxId } = quicklinkMBox;

const MODAL_TYPE = {
    QUICK_LINK: 'quickLink',
    GENERIC: 'generic',
};

const MODALS = {
    [MODAL_TYPE.QUICK_LINK]: {
        executeMethod: send,
        requiredFields: ['assetIds', 'recipients', 'subject', 'comment'],
        Component: lazy(() => import('../QuickLink')),
    },
    [MODAL_TYPE.GENERIC]: {
        executeMethod: destinationService.execute,
        requiredFields: ['assets'],
        Component: lazy(() => import('../GenericDetailModal')),
    },
};

const MBoxDetailModal = ({ onExecute, ...props }) => {
    const { mBox } = props;
    const mBoxTypeId = mBox.id === quicklinkMBoxId ? MODAL_TYPE.QUICK_LINK : MODAL_TYPE.GENERIC;
    const { executeMethod, requiredFields, Component } = MODALS[mBoxTypeId];
    const execute = submitState => {
        const isValid = requiredFields.reduce(
            (acc, field) =>
                acc && (typeof submitState[field] === 'string' ? !!submitState[field] : submitState[field].length > 0),
            true
        );

        isValid && onExecute(submitState, executeMethod);
    };

    return (
        <Suspense key={mBoxTypeId} fallback={<PanelLoading />}>
            <MboxModalContext.Provider value={{ ...props, onExecute: execute }}>
                <Component />
            </MboxModalContext.Provider>
        </Suspense>
    );
};

MBoxDetailModal.propTypes = {
    mBox: PropTypes.object.isRequired,
    onExecute: PropTypes.func.isRequired,
};

export default MBoxDetailModal;
