import { makeStyles } from 'tss-react/mui';

export const useInputFieldStyles = makeStyles()((theme, { disabled }) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        opacity: disabled ? 0.3 : undefined,
    },
    requireIndicator: {
        marginLeft: theme.spacing(0.5),
        color: theme.custom.sysRed[1],
        alignSelf: 'end',
        lineHeight: '1rem',
        width: '1rem',
    },
    helperTextRow: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    helperText: {
        marginLeft: theme.spacing(1),
        color: theme.custom.accentLight[0.8],
    },
}));

export const useInputStyles = makeStyles()((theme, { isFilled }) => ({
    root: {
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
        backgroundColor: isFilled ? theme.custom.productBase[1] : 'initial',
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.custom.accentLight[0.2]}`,
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.custom.accentLight[0.4],
        },
    },
    input: {
        padding: theme.spacing(0.5, 1),
    },
    focused: {
        borderColor: `${theme.custom.focused[0.5]} !important`,
        backgroundColor: theme.palette.background.default,
    },
    disabled: {
        color: theme.custom.accentDark[0.3],
        backgroundColor: theme.palette.background.default,
    },
    error: {
        borderColor: theme.custom.sysRed[1],
    },
}));
