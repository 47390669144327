import { mediabankApi as api, mediabankHeaders } from '..';

const toCalendarItem = item => ({
    id: item.id,
    name: item.attributes.label,
    fullName: item.attributes.hover,
    colorCode: item.attributes.color,
});

const getAll = async () => {
    const response = await api.get(`/calendars`, {
        headers: mediabankHeaders(),
    });
    const {
        data: { data },
    } = response;

    return data.map(toCalendarItem);
};

const getByName = async labelName => {
    const response = await api.get(`/calendars/${labelName}`, {
        headers: mediabankHeaders(),
    });

    const { data } = response;

    return toCalendarItem(data);
};

const getMonthDateByLabel = async (year, month, labelName) => {
    try {
        const response = await api.get(`/calendars/${year}/${month}/${labelName}`, {
            headers: mediabankHeaders(),
        });
        const {
            data: { included },
        } = response;

        return included.map(item => {
            const utcDate = new Date(Number(`${item.attributes.epoch}000`));

            return { id: item.id, groupId: labelName, title: item.attributes.label, start: utcDate, end: utcDate };
        });
    } catch {
        return [];
    }
};

const getMonthDate = async (fullMonth, labels) => {
    const [year, month] = fullMonth.split('-');

    const results = await Promise.all(labels.map(labelName => getMonthDateByLabel(year, month, labelName)));

    return results.reduce((acc, result) => [...acc, ...result], []);
};

export { getAll, getByName, getMonthDate };
