import React from 'react';
import { useCountLines } from '@mediabank/uikit-v5';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Alert from '../Alert';

const useStyles = makeStyles()(theme => {
    const border = color => `1.5px solid ${color}`;

    return {
        root: {
            padding: theme.spacing(1),
            borderRadius: theme.spacing(0.5),
        },
        light: {
            color: theme.palette.common.black,
        },
        dark: {
            color: theme.palette.common.white,
        },
        alert: {
            border: 'none',
            padding: theme.spacing(0),
            alignItems: 'center',
        },
        info: { border: border(theme.palette.info.main) },
        error: { border: border(theme.palette.error.main) },
        success: { border: border(theme.palette.success.main) },
        warning: { border: border(theme.palette.warning.main) },
    };
});

const InlineNotification = ({ message = '', theme = 'dark', title = '', type = '' }) => {
    const { classes, cx } = useStyles();
    const notificationTitle = title || type;
    const pref = notificationTitle.charAt(0).toUpperCase() + notificationTitle.slice(1);

    const [linesRef, lines] = useCountLines({ fontSize: 12 });

    return (
        <Grid
            className={cx(classes.root, {
                [classes.info]: type === 'info',
                [classes.error]: type === 'error',
                [classes.success]: type === 'success',
                [classes.warning]: type === 'warning',
            })}
            item={true}
            xs={true}
        >
            <Alert
                className={cx(classes.alert, { [classes.dark]: theme === 'dark', [classes.light]: theme === 'light' })}
                severity={type}
            >
                <span ref={linesRef} style={{ lineHeight: 1 }}>
                    {lines === 1 ? (
                        <Box fontSize={14} pb={0.4}>
                            <b>{`${pref}: `}</b> {message}
                        </Box>
                    ) : (
                        <>
                            <Box fontSize={14} pb={0.4}>
                                <b>{`${pref}: `}</b>
                            </Box>
                            {message}
                        </>
                    )}
                </span>
            </Alert>
        </Grid>
    );
};

InlineNotification.propTypes = {
    message: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['dark', 'light']).isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['info', 'error', 'success', 'warning']).isRequired,
};

// USAGE: <InlineNotification message="This a notification for multi line" theme="dark" title="ERROR:" type="error" />

export { InlineNotification };
