import React, { createContext, useCallback, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { DATE_RANGE_FIELD_LABEL } from './constants';
import {
    useDateRangePickerStyles,
    useDynamicFilterListStyles,
    useDynamicFiltersStyles,
    useDynamicFilterStyles,
} from './styles';
import { classMaker, sortByProperty } from './utils';

export const DynamicFiltersContext = createContext(null);

const defaultKeyName = 'selection';
export const defaultDateRange = [
    {
        // we need this initial format to not have all the days highlighted by default even there is no dates selected.
        // It is a workaround as it is not resolved in the package yet, but there is on open issue for this.
        startDate: null,
        endDate: new Date(''),
        key: defaultKeyName,
    },
];

export const DynamicFiltersProvider = ({
    children,
    customTheme,
    eventFilters,
    initValues,
    onChange,
    onClear,
    onError,
    onFieldActive,
    onGetMetaKeys,
    onGetMetaValuesById,
    onGetMetaAggregates,
}) => {
    const [dateRange, setDateRange] = useState(defaultDateRange);
    const [metaKeys, setMetaKeys] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filtersChangedByUser, setFiltersChangedByUser] = useState(false);
    const [dateRangeFieldLabel, setDateRangeFieldLabel] = useState(DATE_RANGE_FIELD_LABEL);

    const selectedMetaKeys =
        sortByProperty(
            metaKeys?.filter(metaKeyItem => metaKeyItem.checked),
            'order'
        ) || [];

    const resetDateRange = () => {
        setDateRangeFieldLabel(DATE_RANGE_FIELD_LABEL);
        setDateRange(defaultDateRange);
    };

    const getMetaKeys = async callback => {
        onGetMetaKeys && (await onGetMetaKeys(callback));
    };

    const getMetaValuesById = async ({ metaKeyId, filters }, callback) => {
        onGetMetaValuesById && (await onGetMetaValuesById({ metaKeyId, filters }, callback));
    };

    const getMetaAggregates = async ({ metaKeys, filters }, callback) => {
        onGetMetaAggregates && (await onGetMetaAggregates({ metaKeys, filters }, callback));
    };

    const onClearFilters = () => {
        resetDateRange();
        onClear && onClear();
    };

    const onFilterError = message => {
        onError && onError(message);
    };

    const onAnyFieldActive = isActive => {
        onFieldActive && onFieldActive(isActive);
    };

    const onSetDateRange = useCallback(newDateRange => {
        const startDate = format(newDateRange[0].startDate, 'dd/MM/yy');
        const endDate = format(newDateRange[0].endDate, 'dd/MM/yy');

        setDateRangeFieldLabel(`${startDate} - ${endDate}`);
        setDateRange([
            {
                ...newDateRange[0],
                key: defaultKeyName,
            },
        ]);
    }, []);

    return (
        <DynamicFiltersContext.Provider
            value={{
                isLoading,
                setIsLoading,
                metaKeys,
                initValues,
                setMetaKeys,
                eventFilters,
                onChange,
                dateRange,
                setDateRange: onSetDateRange,
                dateRangeFieldLabel,
                getMetaKeys,
                getMetaValuesById,
                getMetaAggregates,
                selectedMetaKeys,
                onClearFilters,
                onError: onFilterError,
                onAnyFieldActive,
                setDateRangeFieldLabel,
                resetDateRange,
                filtersChangedByUser,
                setFiltersChangedByUser,
                classes: {
                    useDynamicFiltersStyles: classMaker(useDynamicFiltersStyles, customTheme),
                    useDynamicFilterStyles: classMaker(useDynamicFilterStyles, customTheme),
                    useDateRangePickerStyles: classMaker(useDateRangePickerStyles, customTheme),
                    useDynamicFilterListStyles: classMaker(useDynamicFilterListStyles, customTheme),
                },
            }}
        >
            {children}
        </DynamicFiltersContext.Provider>
    );
};

DynamicFiltersProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    customTheme: PropTypes.object,
    initValues: PropTypes.array,
    onChange: PropTypes.func,
    eventFilters: PropTypes.array.isRequired,
    onClear: PropTypes.func,
    onError: PropTypes.func,
    onFieldActive: PropTypes.func,
    onGetMetaKeys: PropTypes.func,
    onGetMetaValuesById: PropTypes.func,
    onGetMetaAggregates: PropTypes.func,
};

DynamicFiltersProvider.defaultProps = {
    children: null,
    customTheme: null,
    initValues: [],
    onChange: () => null,
    eventFilters: [],
    onClear: null,
    onError: null,
    onFieldActive: null,
    onGetMetaKeys: () => null,
    onGetMetaValuesById: () => null,
    onGetMetaAggregates: () => null,
};

export default DynamicFiltersContext;
