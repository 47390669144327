import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import MBoxesDndArea from '../components/MBoxes/MBoxesDndArea';
import userSlice from './../store/user';
import BreadcrumbProvider from './BreadcrumbProvider';
import ConfigProvider from './ConfigProvider';
import { ConnectedSearchProvider } from './ConnectedSearchProvider';
import { EventTagsProvider } from './EventTagsProvider/EventTagsProvider';
import InitalStateProvider from './InitalStateProvider/InitalStateProvider';
import ThemeProvider from './ThemeProvider';
import ToastNotificationProvider from './ToastNotificationProvider';
import UIParamsToStoreProvider from './UIParamsToStoreProvider';

const Providers = ({ children }) => {
    const { authenticated } = useSelector(userSlice.selectors.getUser);
    if (authenticated) {
        return (
            <>
                <UIParamsToStoreProvider />
                <EventTagsProvider>
                    <BreadcrumbProvider>
                        <InitalStateProvider />
                        <ConfigProvider />
                        <ThemeProvider />
                        <ToastNotificationProvider />
                        <ConnectedSearchProvider />
                        <MBoxesDndArea panelShiftX={50}>{children}</MBoxesDndArea>
                    </BreadcrumbProvider>
                </EventTagsProvider>
            </>
        );
    } else {
        return <>{children}</>;
    }
};

Providers.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Providers;
