import { assetsService } from '@mediabank/client';
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    hasAll: false,
    loading: false,
    error: null,
    data: null,
};

const assetMetaKeysSlice = createSlice({
    name: 'assetMetaKeys',
    initialState,
    reducers: {
        fetchStart: state => {
            state.loading = true;
        },
        fetchSuccess: (state, action) => {
            const assets = action.payload.data;
            const getAll = action.payload.getAll;

            try {
                state.hasAll = getAll;
                state.data = assets.data;
                state.loading = false;
                state.error = null;
            } catch {
                fetchError();
            }
        },
        fetchError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        clear: () => initialState,
    },
});

const { fetchStart, fetchSuccess, fetchError } = assetMetaKeysSlice.actions;

const selectAssetMetaKeys = ({ assetMetaKeys }) => assetMetaKeys;

export const selectMetaKeys = createSelector(selectAssetMetaKeys, substate => substate.data || []);

export const selectMetaKeysOptions = createSelector([selectMetaKeys], metaKeys =>
    metaKeys.map(({ id }) => ({ value: id, label: id }))
);

export const selectMetaKeysLoading = createSelector(selectAssetMetaKeys, subState => subState.loading);

export default { ...assetMetaKeysSlice, selectors: { selectMetaKeys, selectMetaKeysOptions, selectMetaKeysLoading } };

export const getMetaKeys = ({ onSuccess, onFail, getAll }) => async dispatch => {
    try {
        dispatch(fetchStart());
        const { data, status } = await assetsService.getMetaKeys({ getAll });
        if (data) {
            dispatch(fetchSuccess({ data, getAll }));
            onSuccess && onSuccess({ data });
        } else {
            throw Error(status);
        }
    } catch (err) {
        dispatch(fetchError(err.message));
        onFail && onFail();
    }
};
