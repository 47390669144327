import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    processing: false,
    values: {},
};

const demoSlice = createSlice({
    name: '@@demo',
    initialState,
    reducers: {
        processStart: state => {
            state.processing = true;
        },
        processComplete: (state, { payload: { id, result } }) => ({
            processing: false,
            values: {
                ...state.values,
                [id]: result,
            },
        }),
        clear: () => initialState,
    },
});

export default demoSlice;
