import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const getFolders = async () => {
    const response = await api.get('/projects', {
        headers: mediabankHeaders(),
    });

    return response;
};

const getFolderDetails = async id => {
    const response = await api.get(`/projects/${id}`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const createFolder = async ({ attributes }) => {
    const response = await api.post('/projects', {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'projects',
                attributes,
            },
        },
    });

    return response;
};

const updateFolder = async (id, data) => {
    const response = await api.patch(`/projects/${id}`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'projects',
                attributes: data.attributes,
            },
        },
    });

    return response;
};

const deleteFolder = async id => {
    const response = await api.delete(`/projects/${id}`, {
        headers: mediabankHeaders(),
    });

    return response;
};

const removeAssetFromFolder = async (folderId, assetId) => {
    const response = await api.delete(`/projects/${folderId}/${assetId}`, {
        headers: mediabankHeaders(),
    });

    return response;
};

export default {
    getFolders,
    getFolderDetails,
    createFolder,
    updateFolder,
    deleteFolder,
    removeAssetFromFolder,
};
