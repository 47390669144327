import { useContext } from 'react';
import { ReactComponent as ClearIconDisabled } from '@mediabank/assets/icons/clear-search-text-disabled.svg';
import { ReactComponent as SearchIcon } from '@mediabank/assets/icons/search_list.svg';
import { Box, Typography } from '@mui/material';

import SearchableDropDownContext from './SearchableDropDownContext';

const DropDownSearch = () => {
    const {
        setFilterPhrase,
        onClearSelection,
        filterPhrase,
        listData,
        selectedItemsNumber,
        classes: { useSearchableDropDownContentStyle },
    } = useContext(SearchableDropDownContext);

    const { classes: searchableDropDownContentStyle } = useSearchableDropDownContentStyle();

    const onChangePhrase = e => {
        setFilterPhrase(e.target.value);
    };

    const filteredListNumber = listData?.filter(dataItem =>
        dataItem.value.toLowerCase().includes(filterPhrase.toLowerCase())
    ).length;

    return (
        <Box className={searchableDropDownContentStyle.searchWrapper}>
            <Box className={searchableDropDownContentStyle.searchBox}>
                <SearchIcon className={searchableDropDownContentStyle.searchIcon} />
                <input
                    className={searchableDropDownContentStyle.searchInput}
                    name="search-list-item"
                    placeholder="Search"
                    type="text"
                    value={filterPhrase}
                    onChange={onChangePhrase}
                />

                {filteredListNumber !== listData.length && (
                    <ClearIconDisabled
                        className={searchableDropDownContentStyle.clearSearchIcon}
                        onClick={() => setFilterPhrase('')}
                    />
                )}
            </Box>
            {onClearSelection && parseInt(selectedItemsNumber) > 0 && (
                <Box className={searchableDropDownContentStyle.clearWrapper}>
                    <Typography className={searchableDropDownContentStyle.clearText} onClick={onClearSelection}>
                        Clear selection
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default DropDownSearch;
