import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    defaultPre: 20,
    defaultPost: 20,
    values: [],
};

const markConfigDialogSlice = createSlice({
    name: '@markConfigModal',
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setPre: (state, action) => {
            state.pre = action.payload;
        },
        setPost: (state, action) => {
            state.post = action.payload;
        },
        resetValues: state => {
            state.pre = initialState.pre;
            state.post = initialState.post;
        },
        setValues: (state, action) => {
            state.values = action.payload;
        },
    },
});

const getIsOpen = ({ markConfigDialog }) => markConfigDialog?.isOpen;
const getPre = ({ markConfigDialog }) => markConfigDialog?.defaultPre;
const getPost = ({ markConfigDialog }) => markConfigDialog?.defaultPost;
const getValues = ({ markConfigDialog }) => markConfigDialog?.values;

export default {
    ...markConfigDialogSlice,
    selectors: { getIsOpen, getPre, getPost, getValues },
};
