import { capitalize } from '@mediabank/utils';

import uiSlice, { getEntryFromParamName } from './ui';

export const setUi = ({ dashboardSlug, params = {}, replace = false }) => (dispatch, getState) => {
    const state = getState();

    //Clean existing params by setting initial values
    if (replace) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.forEach((_value, key) => {
            const entry = getEntryFromParamName(key);

            if (entry) {
                const getValue = entry.getValue || (val => val);
                dispatch(uiSlice.actions[`set${capitalize(key)}`](getValue(entry.initalValue, state)));
            }
        });
    }

    Object.keys(params).forEach(key => {
        const entry = getEntryFromParamName(key);

        if (entry) {
            const getValue = entry.getValue || (val => val);
            dispatch(uiSlice.actions[`set${capitalize(key)}`](getValue(params[key], state)));
        }
    });

    if (dashboardSlug) {
        const slugEntry = getEntryFromParamName('dashboardSlug');

        if (slugEntry) {
            const getValue = slugEntry.getValue || (val => val);
            dispatch(uiSlice.actions['setDashboardSlug'](getValue(dashboardSlug)));
        }
    }
};
