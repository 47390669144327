import React from 'react';
import { Field } from 'react-final-form';
import { default as MuiAutocomplete } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const AutocompleteWrapper = ({
    input: { value, onChange, multiple }, // Injected by wrapper Field from react-final-from
    meta,
    name,
    disabled,
    options,
    placeholder,
    label,
    isClearDisabled,
    helperText,
    isLoading,
    freeSolo,
    renderOption,
    variant,
    onBlur,
    onChange: userOnChange,
    onFocus,
    onInputChange,
    onScroll,
}) => {
    const selectOptions = options || [];
    const selectedOptions = [];
    const showError = meta.error;
    const submitError = meta.submitError;

    if (Array.isArray(value)) {
        value.forEach(v => {
            const option = selectOptions.find(option => option.value === v) || { value: v, label: v };
            if (!option && freeSolo) {
                selectOptions.push(option);
            }

            selectedOptions.push(option);
        });
    } else if (value) {
        const option = selectOptions.find(option => option.value === value);
        if (!option && freeSolo) {
            const newOption = { value: value, label: value };
            selectOptions.push(newOption);
            selectedOptions.push(newOption);
        } else {
            selectedOptions.push(option);
        }
    }

    return (
        <MuiAutocomplete
            disableClearable={isClearDisabled}
            disabled={disabled}
            freeSolo={freeSolo}
            getOptionLabel={option => (typeof option === 'object' && option !== null ? option.label : option)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            ListboxProps={{
                style: { maxHeight: '15vh' },
                onScroll: event => {
                    onScroll && onScroll(event.currentTarget);
                },
            }}
            loading={isLoading}
            multiple={multiple}
            options={selectOptions}
            renderInput={params => (
                <TextField
                    error={showError || submitError}
                    helperText={meta.error || meta.submitError || helperText}
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    variant={variant}
                    {...params}
                />
            )}
            renderOption={renderOption}
            size="small"
            value={multiple ? selectedOptions : selectedOptions[0] || null}
            onBlur={onBlur}
            onChange={(_, newValue, event) => {
                // freeSolo: true, multiple: true, new tag is added, newValue is an array of strings ['A new value']
                // freeSolo: true|false, multiple: true, an existing value is selected, newValue is array of objects [{ value: 'Option1', label: 'Option1' }]
                // freeSolo: true, multiple: true, All values are removed in multiselect, newValue is empty array []
                // freeSolo: true|false, multiple: false, an existing value is selected, newValue is an object { value: 'Existing option', label: 'Existing option' }
                // freeSolo: true, multiple: false, text is deleted in input, newValue is then null
                // freeSolo: true, multiple: false, new tag is added, newValue is a string
                const value = multiple
                    ? newValue.map(option => (option.value ? option.value : option))
                    : typeof newValue === 'object' && newValue !== null
                    ? newValue.value
                    : newValue;

                onChange(value, newValue, event);
                userOnChange && userOnChange(value, newValue, event);
            }}
            onFocus={onFocus}
            onInputChange={onInputChange}
        />
    );
};

AutocompleteWrapper.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    name: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    textFieldProps: PropTypes.object,
    placeholder: PropTypes.string,
    freeSolo: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    isClearDisabled: PropTypes.bool,
    helperText: PropTypes.string,
    renderOption: PropTypes.func,
    variant: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInputChange: PropTypes.func,
    onScroll: PropTypes.func,
};

AutocompleteWrapper.defaultProps = {
    input: {},
    name: '',
    meta: {},
    options: [],
    required: false,
    disabled: false,
    multiple: false,
    textFieldProps: {},
    placeholder: '',
    freeSolo: false,
    label: '',
    isClearDisabled: false,
    helperText: '',
    variant: 'outlined',
    isLoading: false,
    renderOption: null,
    onBlur: null,
    onChange: null,
    onFocus: null,
    onInputChange: null,
    onScroll: null,
};

const FormSelectField = ({ name, ...fieldProps }) => (
    <Field
        name={name}
        render={fieldRenderProps => <AutocompleteWrapper name={name} {...fieldRenderProps} />}
        {...fieldProps}
    />
);

FormSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};

FormSelectField.defaultProps = {
    options: [],
    label: '',
    multiple: false,
    size: 'small',
};

export { AutocompleteWrapper };

export default FormSelectField;
