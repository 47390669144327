import { assetsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
    events: [],
};

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        startCall: state => {
            state.loading = true;
        },
        catchError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        eventsFetched: (state, action) => {
            state.loading = false;
            state.error = null;
            state.events = action.payload;
        },
        clear: () => initialState,
    },
});

const { startCall, catchError, eventsFetched } = tagsSlice.actions;

export const fetchEvents = ({ happeningsId }) => async dispatch => {
    dispatch(startCall());
    try {
        const response = await assetsService.getHappeningEvents({ happeningsId });
        const data = response?.data?.data;
        if (data) {
            const events = (data || []).map(event => event.attributes);
            dispatch(eventsFetched(events));
        }
    } catch (e) {
        dispatch(catchError(e.message));
    }
};

const selectEvents = ({ tags }) => tags.events;

export default { ...tagsSlice, fetchEvents, selectors: { selectEvents } };
