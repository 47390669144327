import React from 'react';
import { ReactComponent as CloseIcon } from '@mediabank/assets/icons/prefilter.svg';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        fontSize: 14,
        paddingRight: theme.spacing(0.75),
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
}));

const SelectClearIndicator = ({ innerProps: { ref, ...restInnerProps } }) => {
    const { classes } = useStyles();

    return (
        <div className={classes.root} {...restInnerProps} ref={ref}>
            <CloseIcon fontSize="inherit" />
        </div>
    );
};

SelectClearIndicator.propTypes = {
    children: PropTypes.object,
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};
SelectClearIndicator.defaultProps = {
    children: null,
};

export default SelectClearIndicator;
