import React from 'react';
import { Typography } from '@development-nl/components-library';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        width: '24px',
        height: '24px',
        borderRadius: '4px',
        backgroundColor: theme.custom.mBoxInfo[1],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const NumIndicator = ({ number, className }) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.root, className)}>
            <Typography className="mbox-number-indicator">{number}</Typography>
        </div>
    );
};

NumIndicator.propTypes = {
    number: PropTypes.number.isRequired,
    className: PropTypes.string,
};

NumIndicator.defaultProps = {
    className: '',
};

export default NumIndicator;
