import { clipListsService, configService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

import clipListsSlice from '../../store/clipLists/clipList';

const initialState = {
    isOpen: false,
    requesting: false,
    isConfirmationOpen: false,
    idToDelete: null,
};

const clipListEditSlice = createSlice({
    name: 'clipListEdit',
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        setConfirmationIsOpen: (state, action) => {
            state.isConfirmationOpen = true;
            state.idToDelete = action.payload;
        },
        setConfirmationClose: state => {
            state.isConfirmationOpen = false;
            state.idToDelete = null;
        },
    },
});

const { requestStart, requestFinished, setConfirmationClose } = clipListEditSlice.actions;

const createClipList = ({ data }) => async dispatch => {
    dispatch(requestStart());

    const response = await clipListsService.createClipList({ data });
    const { status } = response;

    dispatch(requestFinished());
    if (status === 201) {
        dispatch(clipListsSlice.fetchClipLists());
    }
};

const modifyClipList = ({ id, newData }) => async dispatch => {
    dispatch(requestStart());

    const response = await clipListsService.modifyClipList(id, newData);
    const { status } = response;

    dispatch(requestFinished());
    if (status === 200) {
        dispatch(clipListsSlice.fetchClipLists());
    }
};

const deleteClipList = id => async dispatch => {
    dispatch(requestStart());

    const response = await clipListsService.deleteClipList(id);
    const { status } = response;

    dispatch(requestFinished());
    dispatch(setConfirmationClose());
    if (status === 204) {
        dispatch(clipListsSlice.fetchClipLists());
    }
};

const toggleClipListVisibility = ({ id, value }) => async dispatch => {
    dispatch(requestStart());

    const response = await configService.toggleTabState('ClipList', id, value);
    const { status } = response;

    dispatch(requestFinished());
    if (status === 204) {
        dispatch(clipListsSlice.actions.toggleVisibility(id));
    }
};

const selectors = {
    getIsOpen: state => state.clipListEdit.isOpen,
    getConfirmationIsOpen: state => state.clipListEdit.isConfirmationOpen,
    getIdToDelete: state => state.clipListEdit.idToDelete,
};

export default {
    ...clipListEditSlice,
    createClipList,
    deleteClipList,
    modifyClipList,
    toggleClipListVisibility,
    selectors,
};
