import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, Skeleton, Typography } from '@development-nl/components-library';
import { ReactComponent as ImageIcon } from '@mediabank/assets/icons/image-asset.svg';
import { ReactComponent as LinkIcon } from '@mediabank/assets/icons/link-icon.svg';
import { ReactComponent as LockIcon } from '@mediabank/assets/icons/lock.svg';
import { ReactComponent as MirroredIcon } from '@mediabank/assets/icons/mirrored.svg';
import { ReactComponent as MusicIcon } from '@mediabank/assets/icons/music-asset.svg';
import { Label, Tooltip, useCountLines } from '@mediabank/uikit-v5';
import { formatTime, isEmpty } from '@mediabank/utils';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { EventTagsContext } from '../../providers/EventTagsProvider/EventTagsProvider';
import configSlice from '../../store/config';
import ChevronToggleButton from '../ChevronToggleButton';
import {
    dateClasses,
    DateTypography,
    subTitleClasses,
    SubTitleTypography,
    titleClasses,
    TitleTypography,
    useDefaultStyles,
    useToolsStyle,
} from './metaDataStyles';
import { AssetWarningsTooltip } from './MetaDataTools';
import { OverlayBox } from './Thumbnail';

const useIconWrapperStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        height: theme.spacing(3.75),
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& svg:not(first-child)': {
            marginLeft: 3,
        },

        '& svg': {
            height: 18,
            fill: theme.custom.accentLight[0.6],
        },
    },
}));

const useMetaDataInfoOverlayStyles = makeStyles()(() => ({
    root: {
        backgroundColor: 'rgba(21,24,25, 0.95)',
        overflow: 'auto',
    },
}));

const MetaDataTools = ({
    isAudio,
    isImageAsset,
    isLive,
    isLocked,
    isMirrored,
    uploadCompanyName,
    assetmeta,
    assetWarnings,
    durationInSec,
}) => {
    const { classes } = useToolsStyle();

    const { classes: iconWrapperStyles } = useIconWrapperStyles();

    const onIconClick = () => alert('Coming soon...');

    return (
        <Box className={classes.root}>
            <Box alignItems="center" display="flex">
                <Box pl={1}>{formatTime(durationInSec)}</Box>
                <Box alignItems="center" display="flex" fontSize={13.5}>
                    <Box minWidth={85}>{isLive && <Label size="large">LIVE</Label>}</Box>
                    {isAudio && <MusicIcon onClick={onIconClick} />}
                    {isImageAsset && <ImageIcon onClick={onIconClick} />}
                </Box>
                <Box className={iconWrapperStyles.root} pr={1}>
                    <AssetWarningsTooltip assetmeta={assetmeta} assetWarnings={assetWarnings} />

                    {isLocked && <LockIcon />}

                    {/* Hidden for now. Jira: MNT-3/MNT-36 */}
                    {false && <LinkIcon onClick={onIconClick} />}
                    {isMirrored && (
                        <Tooltip placement="top" title={`Asset shared from ${uploadCompanyName}.`}>
                            <span>
                                <MirroredIcon onClick={onIconClick} />
                            </span>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Box>
                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.25)' }} />
            </Box>
        </Box>
    );
};

MetaDataTools.defaultProps = {
    assetmeta: {},
    assetWarnings: [],
    uploadCompanyName: '',
};

MetaDataTools.propTypes = {
    isAudio: PropTypes.bool.isRequired,
    isImageAsset: PropTypes.bool.isRequired,
    isLive: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
    isMirrored: PropTypes.bool.isRequired,
    uploadCompanyName: PropTypes.string,
    assetmeta: PropTypes.object.isRequired,
    assetWarnings: PropTypes.array.isRequired,
    durationInSec: PropTypes.number.isRequired,
};

const MetaDataInfoSkeleton = () => (
    <Box data-test="videoTile-assetMetadataInfo" px={1} py={0.5}>
        <Skeleton height={6} />
        <Skeleton height={6} width="80%" />
        <Skeleton height={6} />
        <Skeleton height={6} width="80%" />
    </Box>
);

const {
    selectors: { getConfig },
} = configSlice;

const MetaDataInfo = ({ metaData, tagsHappeningId, thumbnailImgSrc, isSelected, onChevronClick }) => {
    const { classes } = useDefaultStyles();
    const { onHandleOnChevronButtonClick, isEventTagsPanelOpen } = useContext(EventTagsContext);
    const { EventSearchFilters } = useSelector(getConfig) || {};

    const [linesRef] = useCountLines({ fontSize: 16 });

    const isPanelOpenForCurrentAsset = isSelected && isEventTagsPanelOpen && tagsHappeningId;
    const hasChevronToggleButton = Boolean(EventSearchFilters) && tagsHappeningId;

    const handleOnChevronButtonClick = e => {
        onHandleOnChevronButtonClick(e, {
            tagsHappeningId,
            isPanelOpenForCurrentAsset,
            metaData,
            imgSrc: thumbnailImgSrc,
        });
        onChevronClick && onChevronClick(e);
    };

    const placeholder = 'NO_DATA';
    const title = metaData.title || placeholder;
    const eventType = metaData.eventType || placeholder;
    const eventInfo = metaData.eventInfo || placeholder;
    const date = metaData.date || placeholder;

    const isPlaceholder = text => (text === placeholder || text === '[No text]').toString(); // Second check is for mock/mirage

    return (
        <Box className={classes.root} px={1} py={0.5}>
            <TitleTypography
                ref={linesRef}
                className={titleClasses.root}
                component={'div'}
                data-cy="videoTileTitle"
                display="block"
                isplaceholder={isPlaceholder(title)}
                variant="body2"
            >
                {title}
                <Box>&nbsp;</Box>
            </TitleTypography>
            <Box>
                <SubTitleTypography
                    className={subTitleClasses.root}
                    display="block"
                    isplaceholder={isPlaceholder(eventType)}
                    noWrap={true}
                    variant="caption"
                >
                    {eventType}
                </SubTitleTypography>
                <SubTitleTypography
                    className={subTitleClasses.root}
                    display="block"
                    isplaceholder={isPlaceholder(eventInfo)}
                    noWrap={true}
                    variant="caption"
                >
                    {eventInfo}
                </SubTitleTypography>
                <DateTypography
                    className={dateClasses.root}
                    display="block"
                    isplaceholder={isPlaceholder(date)}
                    noWrap={true}
                    variant="caption"
                >
                    {date}
                </DateTypography>
            </Box>
            {hasChevronToggleButton && (
                <ChevronToggleButton
                    isOpened={isPanelOpenForCurrentAsset}
                    sx={{
                        position: 'absolute',
                        bottom: theme => theme.spacing(1),
                        right: theme => theme.spacing(1),
                    }}
                    onClick={handleOnChevronButtonClick}
                />
            )}
        </Box>
    );
};

MetaDataInfo.propTypes = {
    metaData: PropTypes.shape({
        title: PropTypes.string,
        eventType: PropTypes.string,
        eventInfo: PropTypes.string,
        date: PropTypes.string,
        category: PropTypes.string,
    }).isRequired,
    tagsHappeningId: PropTypes.string,
    onChevronClick: PropTypes.func,
    thumbnailImgSrc: PropTypes.string,
    isSelected: PropTypes.bool.isRequired,
};

MetaDataInfo.defaultProps = {
    tagsHappeningId: null,
    onChevronClick: null,
    thumbnailImgSrc: null,
};

const MetaDataInfoOverlay = ({ metaDataList, assetmeta, ...props }) => {
    const { classes } = useMetaDataInfoOverlayStyles();

    return (
        <OverlayBox className={classes.root} p={1} {...props}>
            {(metaDataList || []).map((item, index) => {
                const value = assetmeta[item.key];

                if (!isEmpty(value)) {
                    return (
                        <Typography key={index} color="textPrimary" display="block" noWrap={true} variant="caption">
                            {!isEmpty(item.label) && `${item.label}:`} {assetmeta[item.key]}
                        </Typography>
                    );
                }

                return null;
            })}
        </OverlayBox>
    );
};

MetaDataInfoOverlay.propTypes = {
    metaDataList: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    assetmeta: PropTypes.object,
};

MetaDataInfoOverlay.defaultProps = {
    assetmeta: {},
};

const MetaDataInfoSimple = ({ metaData }) => {
    const { classes } = useDefaultStyles();

    return (
        <Box className={classes.root} px={1} py={0.5}>
            <Typography className={classes.titleRow} variant="body2">
                {truncate(metaData.title, { length: 24 })}
            </Typography>
        </Box>
    );
};

MetaDataInfoSimple.propTypes = {
    metaData: PropTypes.shape({
        title: PropTypes.string,
    }).isRequired,
};

export default {
    Info: MetaDataInfo,
    Skeleton: MetaDataInfoSkeleton,
    ExtraInfoOverlay: MetaDataInfoOverlay,
    Simple: MetaDataInfoSimple,
    Tools: MetaDataTools,
};
