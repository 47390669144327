import React, { useState } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

export const arrowGenerator = (color, classes) => ({
    [`&[data-popper-placement*="bottom"] .${classes.arrow}`]: {
        top: 0,
        left: 0,
        marginTop: '-0.95em',
        '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent ${color} transparent`,
        },
    },
    [`&[data-popper-placement*="top"] .${classes.arrow}`]: {
        bottom: 0,
        left: 0,
        marginBottom: '-0.95em',
        '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `${color} transparent transparent transparent`,
        },
    },
    [`&[data-popper-placement*="right"] .${classes.arrow}`]: {
        left: 0,
        marginLeft: '-0.95em',
        '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent ${color} transparent transparent`,
        },
    },
    [`&[data-popper-placement*="left"] .${classes.arrow}`]: {
        right: 0,
        marginRight: '-0.95em',
        '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${color}`,
        },
    },
});

const useStylesArrow = makeStyles()((theme, _params, classes) => ({
    arrow: {
        position: 'absolute',
        fontSize: 6,
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    popper: arrowGenerator(theme.palette.primary.main, classes),
    tooltip: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    tooltipPlacementLeft: {
        margin: '0 8px',
    },
    tooltipPlacementRight: {
        margin: '0 8px',
    },
    tooltipPlacementTop: {
        margin: '8px 0',
    },
    tooltipPlacementBottom: {
        margin: '8px 0',
    },
}));

const MuiTooltipWithArraow = props => {
    const { arrow, ...classes } = useStylesArrow();
    const [arrowRef, setArrowRef] = useState(null);

    return (
        <MuiTooltip
            classes={classes}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef,
                        },
                    },
                },
            }}
            {...props}
            title={
                <>
                    {props.title}
                    <span ref={setArrowRef} className={arrow} />
                </>
            }
        />
    );
};

MuiTooltipWithArraow.propTypes = {
    title: PropTypes.node.isRequired,
};

export const placementList = ['bottom', 'left', 'right', 'top'];

const Tooltip = ({ title, children, placement }) => (
    <MuiTooltipWithArraow aria-label={title} placement={placement} title={title}>
        {children}
    </MuiTooltipWithArraow>
);

Tooltip.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf(placementList),
};

Tooltip.defaultProps = {
    title: '',
    placement: 'top',
};

export default Tooltip;
