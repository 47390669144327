export const defaultEmptyFilters = [{ id: 0, key: null, verb: 'equal', value: null }];

export const preparePayload = ({
    searchConfig: { id, name, permissionType, groupsAllowed, quickfilter, active, saved, quickfilterDisplayAfterId },
    query: { keyword, matchType, filters, createdPeriod, createdFilter },
}) => {
    const createdPeriodParams = createdPeriod &&
        createdPeriod.enabled && {
            smartsearchperiodenabled: createdPeriod.enabled,
            smartsearchperiodtype: createdPeriod.type,
            smartsearchperiod: createdPeriod.value,
        };
    const createdFilterParams = createdFilter &&
        createdFilter.enabled && {
            smartsearchassetcreatedfilterenabled: createdFilter.enabled,
            smartsearchassetcreatedfiltertype: createdFilter.type,
            smartsearchassetcreatedfiltervalue: createdFilter.value,
        };
    const attributes = {
        smartsearchname: name,
        smartsearchtext: keyword,
        smartsearchmatchtype: matchType,
        smartsearchfilters: filters
            ? filters.filter(
                  f => ((f.verb === 'isdefined' || f.verb === 'isnotdefined') && f.key) || (f.key && f.value)
              )
            : undefined,
        ...createdPeriodParams,
        ...createdFilterParams,
        smartsearchpermissiontype: permissionType,
        smartsearchpermissiongroups: permissionType === 'group' ? groupsAllowed : undefined,
        id,
        quickfilter,
        quickfilterDisplayAfterId,
        active,
        saved,
    };

    return {
        data: {
            type: 'smartsearch',
            attributes,
        },
    };
};

export const translateResponse = ({ id, attributes }) => {
    const {
        smartsearchname,
        smartsearchpermissiontype,
        smartsearchpermissiongroups,
        quickfilter,
        smartsearchtext,
        smartsearchmatchtype,
        smartsearchfilters,
        smartsearchperiod,
        smartsearchperiodtype,
        smartsearchperiodenabled,
        smartsearchassetcreatedfilterenabled,
        smartsearchassetcreatedfiltertype,
        smartsearchassetcreatedfiltervalue,
        editable,
        saved,
        active,
        userfullname,
        email_notification,
        sms_notification,
    } = attributes;

    return {
        id,
        name: smartsearchname,
        lowercaseName: smartsearchname && smartsearchname.toLowerCase(),
        permissionType: smartsearchpermissiontype,
        groupsAllowed: smartsearchpermissiongroups,
        quickfilter,
        keyword: smartsearchtext,
        matchType: smartsearchmatchtype,
        filters: (smartsearchfilters || []).map((f, index) => ({ ...f, id: index })),
        createdPeriod: {
            enabled: !!smartsearchperiodenabled,
            type: smartsearchperiodtype,
            value: smartsearchperiod,
        },
        createdFilter: {
            enabled: !!smartsearchassetcreatedfilterenabled,
            type: smartsearchassetcreatedfiltertype,
            value: smartsearchassetcreatedfiltervalue,
        },
        editable,
        saved,
        active,
        userfullname,
        emailNotification: email_notification,
        smsNotification: sms_notification,
    };
};
