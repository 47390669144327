import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
// import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { capitalize } from '@mediabank/utils';

import displayType from '../../store/displayType';
import uiSlice, { getEntryFromParamName } from '../../store/ui';

const {
    actions: { changeDisplayType },
} = displayType;

/**
 *
 * @returns Pollyfill for react-router-doms useSearchParams hook that was added in v6
 */

const useSearchParams = () => {
    const { pathname, search } = useLocation();

    return [useMemo(() => new URLSearchParams(search), [search]), pathname];
};

// Could be a util
const falsyValueMap = {
    undefined,
    null: null,
    false: false,
    NaN: NaN,
};

/**
 * Syncs url changes to Store
 */
const UIParamsToStateProvider = () => {
    const { actions } = uiSlice;
    const [searchParams, pathname] = useSearchParams();
    const dispatch = useDispatch();
    const entries = Array.from(searchParams.entries());

    const convertType = value => (value in falsyValueMap ? falsyValueMap[value] : value);

    useEffect(() => {
        entries.forEach(param => {
            const entry = getEntryFromParamName(param[0]);

            if (entry) {
                const getValue = entry.get || (val => val);
                const value = convertType(getValue(param[1]));
                dispatch(actions[`set${capitalize(param[0])}`](value));
            }
        });
    }, [dispatch, entries, actions]);

    useEffect(() => {
        const entry = getEntryFromParamName('dashboardSlug');
        //Get the dashboard slug from second resource
        const dashboardSlug = pathname.split('/')[2];

        if (entry) {
            const getValue = entry.get || (val => val);
            dispatch(actions['setDashboardSlug'](getValue(dashboardSlug)));
        }
    }, [dispatch, entries, pathname, actions]);

    useEffect(() => {
        // Sync activeSearchResultTab and displayType
        const activeTabEntry = entries.find(entry => entry[0] === 'activeSearchResultTab');
        const displayTypeEntry = entries.find(entry => entry[0] === 'displayType');

        if (activeTabEntry && displayTypeEntry) {
            const getValue = activeTabEntry.get || (val => val);
            const tab = getValue(activeTabEntry[1]);

            dispatch(changeDisplayType({ tab, newDisplayType: displayTypeEntry[1] }));
        }
    }, [dispatch, entries]);

    return null;
};

export default UIParamsToStateProvider;
