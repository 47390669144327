import { useStore } from 'react-redux';

const useInjectEpic = ({ key, epic }) => {
    const store = useStore();
    if (store.injectedEpics[key]) {
        return store;
    }
    store.epic$.next(epic);
    store.injectedEpics[key] = epic;

    return store;
};

export default useInjectEpic;
