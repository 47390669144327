import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContext } from '@mediabank/uikit-v5';
import { safeParseJSON } from '@mediabank/utils';

import configSlice from '../../store/config';

const {
    selectors: { getConfig },
} = configSlice;

const ToastNotificationProvider = () => {
    const { ReactNotificationMessage } = useSelector(getConfig) || {};
    const notificationMsg = ReactNotificationMessage && safeParseJSON(ReactNotificationMessage, undefined);
    const { addToastAppInfo } = useContext(ToastContext);

    useEffect(() => {
        if (!!notificationMsg && notificationMsg?.title !== '' && notificationMsg?.message !== '') {
            addToastAppInfo({
                title: notificationMsg.title,
                subtitle: notificationMsg.message,
                persist: false,
            });
        }
    }, [notificationMsg, addToastAppInfo]);

    return <></>;
};

export default ToastNotificationProvider;
