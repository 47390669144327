import React from 'react';
import { ReactComponent as SuccessIcon } from '@mediabank/assets/icons/checkmark-glyph.svg';
import { ReactComponent as ErrorIcon } from '@mediabank/assets/icons/error-glyph.svg';
import { ReactComponent as InfoIcon } from '@mediabank/assets/icons/info-glyph.svg';
import { ReactComponent as WarningIcon } from '@mediabank/assets/icons/warning-glyph.svg';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    root: {
        alignItems: 'center',
    },
});

const Icon = ({ severity }) => {
    switch (severity) {
        case 'success':
            return <SuccessIcon />;
        case 'info':
            return <InfoIcon />;
        case 'warning':
            return <WarningIcon />;
        default:
            return <ErrorIcon />;
    }
};

const Alert = ({ severity, ...rest }) => {
    const { classes } = useStyles();

    return (
        <MuiAlert
            className={classes.root}
            icon={<Icon severity={severity} />}
            severity={severity}
            variant="outlined"
            {...rest}
        />
    );
};

Alert.propTypes = {
    severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

Icon.propTypes = {
    severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

Alert.defaultProps = {
    severity: 'error',
};

Icon.defaultProps = {
    severity: 'error',
};

export default Alert;
