import { defaultFontFamily } from './fonts';
import { makeColors } from './themeFunctions';

export const basicColors = {
    blue: '#517aff',
    yellow: '#efc100',
    red: '#f5263c',
    green: '#32ff7b',
    greenReady: '#62FF9A',
    white: '#fff',
    darkGrey: '#424242',
};

export const commonColors = {
    ...basicColors,
    focused: makeColors('#ffcc42'),
    contrastText: 'rgb(22, 24, 25)', // should not be common
    mBoxInfo: makeColors('#e0245e'),
    const: {
        tooltipText: basicColors.white, // should not be common
    },
    loginPage: {
        color: '#3d3d3d',
        background: basicColors.white,
    },
    sys: {
        sysBlue: makeColors(basicColors.blue),
        sysYellow: makeColors(basicColors.yellow),
        sysRed: makeColors(basicColors.red),
        sysGreen: makeColors(basicColors.green),
    },

    download: {
        downloadProgress: basicColors.yellow,
        downloadReady: basicColors.greenReady,
        downloadWaiting: '#414445',
        downloadFailed: '#FC5466',
    },

    live: {
        liveRed: makeColors('#b80000'),
        liveGreen: makeColors('#00404a'),
    },
};

export const typography = {
    fontFamily: defaultFontFamily,
    fontSize: 10,
    htmlFontSize: 10,
    h1: {
        fontSize: '2.4rem',
        lineHeight: '2.4rem',
    },
    h2: {
        fontSize: '2.2rem',
        lineHeight: '2.2rem',
    },
    h3: {
        fontSize: '2.0rem',
        lineHeight: '2.0rem',
    },
    h4: {
        fontSize: '1.8rem',
        lineHeight: '1.8rem',
    },
    h5: {
        fontSize: '1.6rem',
        lineHeight: '1.6rem',
    },
    h6: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        fontWeight: 'normal',
    },
    subtitle1: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
    },
    subtitle2: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
    },
    body1: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
    },
    body2: {
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
    },
    button: {
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
        textTransform: 'none',
    },
    caption: {
        fontSize: '1.0rem',
    },
    overline: {
        fontSize: '1.0rem',
    },
};

export const themeProps = {
    MuiButton: {
        disableRipple: true,
    },
    MuiButtonBase: {
        disableRipple: true,
    },
};
