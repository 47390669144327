import React, { createContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import useAuth from '../../../apps/mediabank-next-transition/src/hooks/useAuth';
import { ToastContent } from './ToastContent';

const AUTO_HIDE_DURATION_MS = 40000;

const ToastContext = createContext({});

const useStyles = makeStyles()((theme, { showOverLay }) => ({
    overlay: {
        background: theme.palette.background.default,
        display: (showOverLay && 'flex') || 'none',
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        opacity: 0.5,
        zIndex: 100,
    },
}));

const ToastProvider = ({ children = null }) => {
    const authenticated = useAuth();

    const pathname = window.location.pathname;
    const perfEntries = performance.getEntriesByType('navigation');
    const [showOverLay, setShowOverlay] = useState(
        authenticated && !pathname?.includes('apps') && perfEntries[0]?.type !== 'back_forward'
    );
    const { enqueueSnackbar } = useSnackbar();
    const [hasMessage, setHasMessage] = useState(false);
    const { classes } = useStyles({ showOverLay });

    useEffect(() => {
        if (pathname.includes('apps')) {
            setShowOverlay(false);
        } else if (perfEntries[0]?.type === 'back_forward') {
            setShowOverlay(false);
        } else if (authenticated && perfEntries[0]?.type !== 'back_forward') {
            setShowOverlay(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    const addToast = ({ cy, persist, subtitle, title }) => {
        setHasMessage(true);

        enqueueSnackbar(title, {
            content: key => (
                <Box data-cy={cy} data-testid={cy}>
                    {showOverLay && (
                        <ToastContent
                            setShowOverlay={setShowOverlay}
                            subtitle={subtitle}
                            title={title}
                            toastKey={key}
                        />
                    )}
                </Box>
            ),
            persist,
            preventDuplicate: true,
            autoHideDuration: AUTO_HIDE_DURATION_MS,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            onExited: () => setShowOverlay(false),
        });
    };

    const appInfoValue = {
        addToastAppInfo: optionsObj => addToast({ ...optionsObj }),
        hasMessage,
    };

    return (
        <>
            {!!hasMessage && <div className={classes.overlay} />}
            <ToastContext.Provider value={appInfoValue}>{children}</ToastContext.Provider>
        </>
    );
};

ToastProvider.defaultProps = {};

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { ToastProvider, ToastContext };
