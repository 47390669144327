import { defaultFontFamily } from './fonts';

import { GlobalStyles as TSSGlobalStyles } from 'tss-react';
import { useStyles } from 'tss-react/mui';

const GlobalStyles = () => {
    const { theme } = useStyles();

    return (
        <TSSGlobalStyles
            styles={{
                '*': { userSelect: 'none' },
                input: { userSelect: 'text' },
                textArea: { userSelect: 'text' },
                html: {
                    fontSize: 10,
                },
                button: {
                    fontFamily: defaultFontFamily,
                },
                body: {
                    margin: 0,
                    overflow: 'hidden',
                },
                'body, html, #root': {
                    height: '100%',
                },
                '*::-webkit-scrollbar': {
                    width: theme.spacing(1),
                    height: theme.spacing(1),
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: theme.custom.accentDark[0.5],
                    borderRadius: theme.spacing(0.8),
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.custom.scrollbarThumb,
                    borderRadius: theme.spacing(0.8),
                },
                'body ::-webkit-scrollbar-track': {
                    backgroundColor: theme.custom.accentDark[0.5],
                    borderRadius: '6px',
                },
                'body ::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.custom.scrollbarThumb,
                    borderRadius: '6px',
                },
                '* svg': {
                    fill: 'currentColor',
                },
            }}
        />
    );
};

export default GlobalStyles;
