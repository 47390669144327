import { format as formatDate } from 'date-fns';
import PropTypes from 'prop-types';

export const filterType = PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    filterKey: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
});

export const classMaker = (makeStyleFN, customTheme) => customProps =>
    makeStyleFN({ ...customTheme, ...(customProps ? customProps : {}) });

export const formatFilterData = (data, dateRange) => {
    let formattedData = data
        .reduce((previousValue, currentValue) => {
            const selectedValues =
                currentValue?.options?.filter(optionItem => optionItem.checked).map(optionItem => optionItem.id) ||
                null;

            return [
                {
                    id: currentValue.id,
                    value: selectedValues,
                    options: currentValue?.options || [],
                    order: currentValue.order,
                },
                ...previousValue,
            ];
        }, [])
        ?.filter(reducedItem => reducedItem.value?.length);

    if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
        formattedData.push({ id: 'daterange', ...dateRange[0] });
    }

    return formattedData;
};

export const sendSignalAboutChanges = (newData, dateRange, onChange) => {
    const result = formatFilterData(newData, dateRange);

    onChange(result);
};

export const convertDynamicFilterData = (filters, dateRangeId = 'event_real_time') =>
    filters.map(filterItem =>
        filterItem.id === 'daterange'
            ? {
                  id: dateRangeId,
                  type: 'daterange',
                  value: [
                      `${formatDate(filterItem.startDate, 'yyyy-MM-dd')} 00:00`, // from
                      `${formatDate(filterItem.endDate, 'yyyy-MM-dd')} 23:59`, // to
                  ],
              }
            : filterItem
    );

export const sortByProperty = (items = [], property, desc = false) =>
    items.sort((a, b) => {
        let result = 0;
        if (a[property] < b[property]) {
            result = -1;
        }
        if (a[property] > b[property]) {
            result = 1;
        }

        if (desc) result = result * -1;

        return result;
    });
