import React from 'react';
import { ReactComponent as CloseIcon } from '@mediabank/assets/icons/close-modal.svg';
import {
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    Divider as MuiDivider,
    IconButton,
    styled,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        color: theme.custom.accentLight[0.9],
        fontWeight: 500,
        textAlign: 'center',
        fontSize: '14px',
    },
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
    borderColor: theme.custom.accentLight[0.08],
    backgroundColor: theme.custom.accentLight[0.08],
}));

const DialogTitle = ({ children, onClose, ...forwardProps }) => {
    const { classes, cx } = useStyles();

    return (
        <>
            <MuiDialogTitle className={cx(classes.root, forwardProps.classes?.root)} {...forwardProps}>
                <Typography className={cx(classes.title, forwardProps.styles?.title)} component="span" variant="h4">
                    {children}
                </Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={cx(classes.closeButton, forwardProps.styles?.closeButton)}
                        size="small"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <Divider />
        </>
    );
};

DialogTitle.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

DialogTitle.defaultProps = {
    onClose: undefined,
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
    padding: theme.spacing(2),
    justifyContent: 'space-between',
}));

const Dialog = ({ size, children, onClose, ...forwardProps }) => (
    <MuiDialog
        disableEscapeKeyDown={true}
        maxWidth={size}
        onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
                onClose(event, reason);
            }
        }}
        {...forwardProps}
    >
        {children}
    </MuiDialog>
);

Dialog.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'lg']),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    onClose: PropTypes.func,
};

Dialog.defaultProps = {
    size: 'sm',
    onClose: () => null,
};

export { Dialog, DialogTitle, DialogContent, DialogActions };
