import { createSlice } from '@reduxjs/toolkit';

import sequenceMockData from './sequenceMockData';

const initialState = sequenceMockData;

const sequenceSlice = createSlice({
    name: 'sequence',
    initialState,
    reducers: {
        setSequence: (state, action) => ({
            ...action.payload,
        }),
        setClips: (state, action) => {
            const { trackIndex, clips } = action.payload;
            state.tracks[trackIndex].clips = clips;
        },
        setClip: (state, action) => {
            const { trackIndex, clipIndex, clip } = action.payload;
            state.tracks[trackIndex].clips[clipIndex] = clip;
        },
        setDuration: (state, action) => {
            const { duration } = action.payload;
            state.duration = duration;
        },
        addClips: (state, action) => {
            const { trackId, clips } = action.payload;
            state.tracks = state.tracks.map(track => {
                if (track.id === trackId) {
                    // eslint-disable-next-line no-param-reassign
                    track.clips = [...track.clips, ...clips];
                }

                return track;
            });
        },
        removeClip: (state, action) => {
            const { trackIndex, clipIndex } = action.payload;
            state.tracks[trackIndex].clips.splice(clipIndex, 1);
        },
        setClipVolume: (state, action) => {
            const { trackIndex, clipIndex, volume } = action.payload;
            state.tracks[trackIndex].clips[clipIndex].volume = volume;
        },
        clear: () => initialState,
    },
});

const getSequence = state => state.sequence;

export default { ...sequenceSlice, selectors: { getSequence } };
