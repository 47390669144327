import { tagListsService } from '@mediabank/client';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    requesting: false,
    data: [],
};

const happeningsSlice = createSlice({
    name: 'happenings',
    initialState,
    reducers: {
        requestStart: state => {
            state.requesting = true;
        },
        requestFinished: state => {
            state.requesting = false;
        },
        updateDetails: (state, action) => {
            const { payload } = action;
            state.data = payload.happenings;
        },
    },
});

const { requestStart, requestFinished, updateDetails } = happeningsSlice.actions;

const fetchHappenings = id => async dispatch => {
    dispatch(requestStart());
    try {
        const response = await tagListsService.getHappenings(id);

        dispatch(requestFinished());
        if (response?.status === 200) {
            const happenings = response?.data?.data || [];
            dispatch(updateDetails({ happenings }));
        } else {
            dispatch(requestFinished());
            throw Error(response?.status);
        }
    } catch (error) {
        dispatch(requestFinished());
    }
};

const isRequesting = state => state.happenings.requesting;
const happenings = state => state.happenings?.data || [];

export default {
    ...happeningsSlice,
    fetchHappenings,
    selectors: { isRequesting, happenings },
};
