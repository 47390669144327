import React, { useState } from 'react';
import { Box, Skeleton } from '@development-nl/components-library';
import { AssetThumbnail } from '@mediabank/uikit-v5';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';

import { OverlayBox } from '../Thumbnail';
import { useOverlayBoxStyles } from './OverlayBox';

const ThumbnailContainer = ({ children, ...props }) => (
    <Box bgcolor="secondaryDark" position="relative" {...props}>
        {children}
    </Box>
);

ThumbnailContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

ThumbnailContainer.defaultProps = {};

const ThumbnailImg = ({ src, title }) => {
    const overlayClasses = useOverlayBoxStyles();
    const [isLoaded, setIsLoaded] = useState(true);

    const showImage = () => {
        setIsLoaded(true);
    };

    const hideImage = () => {
        setIsLoaded(false);
    };

    return (
        <>
            <div className={overlayClasses.root} style={isLoaded ? { display: 'none' } : {}} />
            <AssetThumbnail imgSrc={src} title={title} onError={hideImage} onLoad={showImage} />
        </>
    );
};

ThumbnailImg.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

ThumbnailImg.defaultProps = {};

const ThumbnailSkeleton = () => (
    <OverlayBox>
        <Skeleton height="100%" variant="rect" />
    </OverlayBox>
);

const BottomLabel = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(0.625),
    right: theme.spacing(0.625),
    fontSize: '0.7rem',
}));

const TopIcon = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: theme.spacing(1.25),
    top: theme.spacing(1.25),
}));

const TopSmallLabel = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(0.625),
    right: theme.spacing(0.625),
    fontSize: '0.5rem',
}));

export default {
    Img: ThumbnailImg,
    Container: ThumbnailContainer,
    Skeleton: ThumbnailSkeleton,
    TopIcon,
    BottomLabel,
    TopSmallLabel,
};
